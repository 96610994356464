import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { CircularProgress, Button as MuiButton } from '@mui/material';
import { useState, type FC, type ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { useAppDispatch } from 'features/app/hooks';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import type { Hotkey } from 'config/enums';
import { textEllipsis } from 'features/theme/commonCss';
import styled from 'features/theme/styled';

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  hotkey?: Hotkey;
  onClick?: () => void;
  isSendAnalytics?: boolean;
}

const onClickDefaultValue = () => null;

export const Button: FC<ButtonProps> = ({
  loading: externalLoading,
  disabled,
  children,
  size,
  hotkey = '',
  isSendAnalytics = true,
  onClick = onClickDefaultValue,
  ...args
}) => {
  const dispatch = useAppDispatch();
  const [internalLoading, setInternalLoading] = useState(false);
  const loading = externalLoading ?? internalLoading;

  const circularProgressSize = size === 'large' ? 31 : 22;

  useHotkeys(hotkey, onClick, {
    enabled: Boolean(hotkey) && !disabled && !loading,
  });

  const handleButtonClick = async () => {
    if (loading) {
      return;
    }

    setInternalLoading(true);

    try {
      if (isSendAnalytics) {
        dispatch(
          sendAnalyticsInfo({
            Event: 'ButtonClick',
            Method: 'handleButtonClick',
            Message: getButtonAnalyticsMessage(children),
          })
        );
      }

      await onClick();
    } finally {
      setInternalLoading(false);
    }
  };

  const getButtonAnalyticsMessage = (content: ReactNode) => {
    if (typeof content !== 'string') {
      return 'Click on button with unknown text';
    }

    return `Click on button with text - ${content}`;
  };

  return (
    <MuiButton
      disabled={disabled || loading}
      size={size}
      onClick={handleButtonClick}
      {...args}
    >
      {loading ? (
        <CircularProgress
          data-testid='loading-circular'
          size={circularProgressSize}
          thickness={4}
          color='inherit'
          variant='indeterminate'
        />
      ) : (
        <TruncatedText>{children}</TruncatedText>
      )}
    </MuiButton>
  );
};

const TruncatedText = styled('span')`
  ${textEllipsis}
`;
