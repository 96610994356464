import { useEffect, useRef, useState } from 'react';

interface useCountdownProps {
  onFinish: () => void;
  countFrom?: number;
}

export const useCountdown = ({ onFinish, countFrom }: useCountdownProps) => {
  const countdownRef = useRef<ReturnType<typeof setInterval>>();
  const [count, setCount] = useState(countFrom ?? 3);

  useEffect(() => {
    countdownRef.current = setInterval(() => {
      setCount((value) => value - 1);
    }, 1000);

    return () => clearInterval(countdownRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (count === 0) {
      onFinish();
      clearInterval(countdownRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return { count };
};
