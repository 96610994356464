import { Typography, Menu, MenuItem, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

import theme from 'features/theme/theme';

interface CallDetailsAccordianLanguageMenuProps {
  anchorEl?: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  selectedLanguage?: string;
  onLanguageSelect: (lang: string) => void;
}

export const CallDetailsAccordianLanguageMenu = ({
  anchorEl,
  open,
  onClose,
  selectedLanguage,
  onLanguageSelect,
}: CallDetailsAccordianLanguageMenuProps) => {
  const { t } = useTranslation('translation');

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <StyledMenuItem onClick={() => onLanguageSelect('English')}>
        {selectedLanguage === 'English' && (
          <StyledCheckIcon data-testid='check-icon' />
        )}
        <Text selected={selectedLanguage === 'English'}>{t('english')}</Text>
      </StyledMenuItem>
      <StyledMenuItem onClick={() => onLanguageSelect('Spanish')}>
        {selectedLanguage === 'Spanish' && (
          <StyledCheckIcon data-testid='check-icon' />
        )}
        <Text selected={selectedLanguage === 'Spanish'}>{t('spanish')}</Text>
      </StyledMenuItem>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    color: theme.palette.common.white,
    minWidth: '200px',
    boxShadow: `0px 5px 15px ${theme.palette.grey[900]}`,
    borderRadius: '8px',
  },
  '& .MuiMenu-list': {
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
  },
  '& .MuiMenuItem-root': {
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      borderRadius: theme.spacing(4),
    },
  },
}));

const StyledMenuItem = styled(MenuItem)`
  px: 16px;
  width: 230px;
`;

const Text = styled(Typography)<{ selected?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) =>
    props.selected ? theme.palette.primary[500] : theme.palette.common.white};
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: ${theme.spacing(4)};
  color: ${theme.palette.primary[500]};
  font-size: 20px;
`;
