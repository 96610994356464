import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { coldHandoffStatusSelector } from './coldHandoffSelectors';

import type { RootState } from 'features/app/store/store';
import { AudioPlayer } from 'features/audio-player/services';
import { LogoutType } from 'features/call/call-base/enums';
import { CallDaoService } from 'features/call/call-base/services';
import {
  handleResetCall,
  setCallTakenTime,
  setRelayCallState,
} from 'features/call/call-base/store';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { ColdHandoffStatus } from 'features/cold-handoff/enums';
import type {
  AcceptingUser,
  ColdHandoffState,
  RequestingUser,
} from 'features/cold-handoff/interfaces';
import { ColdHandoffDaoService } from 'features/cold-handoff/services';
import { handleError } from 'features/notification/store';
import {
  removeSession,
  resetSessionId,
  rnsConnectionIdSelector,
} from 'features/session/store';
import { userIdSelector } from 'features/user/store';
import { toIsoWithTimezone } from 'features/utils/helpers';
import { resetVriChat } from 'features/vri-chat/store';
import { incomingCallAccepted } from 'features/caller-session/store';
import { deafPhoneNumberSelector } from 'features/call/call-deaf/store';
import { acceptColdHandoffOffer } from 'features/call/call-ui-state/store';

export const initialColdHandoffState: ColdHandoffState = {
  status: ColdHandoffStatus.NONE,
  requestingUser: {
    name: '',
  },
  acceptingUser: {
    uri: '',
  },
};

export const coldHandoffSlice = createSlice({
  name: 'coldHandoff',
  initialState: initialColdHandoffState,
  reducers: {
    setColdHandoffStatus: (state, action: PayloadAction<ColdHandoffStatus>) => {
      state.status = action.payload;
    },
    setColdHandoffRequestingUser: (
      state,
      action: PayloadAction<RequestingUser>
    ) => {
      state.requestingUser = action.payload;
    },
    setColdHandoffAcceptingUser: (
      state,
      action: PayloadAction<AcceptingUser>
    ) => {
      state.acceptingUser = action.payload;
    },
    resetColdHandoff: () => initialColdHandoffState,
  },
});

export const {
  setColdHandoffStatus,
  setColdHandoffRequestingUser,
  setColdHandoffAcceptingUser,
  resetColdHandoff,
} = coldHandoffSlice.actions;

export const coldHandoffReducer = coldHandoffSlice.reducer;

export const takeColdHandoffRequest = createAsyncThunk(
  'cold-handoff/take',
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const userId = userIdSelector(state);
      const sessionId = rnsConnectionIdSelector(state);
      const deafPhoneNumber = deafPhoneNumberSelector(state);
      const call = await CallDaoService.takeCall({
        callId,
        userId,
        sessionId,
      });
      dispatch(incomingCallAccepted(callId.toString(), deafPhoneNumber));
      dispatch(setRelayCallState(call.CallState));
      dispatch(
        setCallTakenTime(toIsoWithTimezone(call.TakenOrAbandonDateTime))
      );
      dispatch(acceptColdHandoffOffer());
      dispatch(resetColdHandoff());
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'takeColdHandoffRequest',
        })
      );
    } finally {
      AudioPlayer.stop();
    }
  }
);

export const declineColdHandoffRequest = createAsyncThunk(
  'cold-handoff/decline',
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      await CallDaoService.denyCall({
        callId,
      });
      await dispatch(
        removeSession({
          logoutType: LogoutType.Normal,
        })
      ).unwrap();
      dispatch(resetSessionId());
      dispatch(handleResetCall()).unwrap();
      dispatch(resetVriChat());
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'declineColdHandoffRequest',
        })
      );
    } finally {
      AudioPlayer.stop();
    }
  }
);

export const completeColdHandoff = createAsyncThunk(
  'cold-handoff/complete',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    try {
      await ColdHandoffDaoService.completeHandoff({
        callId,
      });
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'completeColdHandoff',
        })
      );
    }
  }
);

export const forceCancelColdHandoff = createAsyncThunk(
  'cold-handoff/force-cancel',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const coldHandoffStatus = coldHandoffStatusSelector(state);
    if (
      coldHandoffStatus === ColdHandoffStatus.REQUESTED ||
      coldHandoffStatus === ColdHandoffStatus.ACCEPTED
    ) {
      try {
        await ColdHandoffDaoService.cancelHandoff({ callId });
        dispatch(resetColdHandoff());
      } catch (error) {
        dispatch(
          handleError({
            error,
            methodName: 'forceCancelColdHandoff',
          })
        );
      }
    }
  }
);
