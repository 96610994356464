import type { UserState } from 'features/user/interfaces';
import { initialUserState } from 'features/user/store';

export const userStateMock: UserState = {
  ...initialUserState,
  user: {
    ...initialUserState.user,
    id: 2153101,
    agentNumber: 'JB1483',
    userName: 'vbobrov',
    firstName: 'Vitaliy',
    lastName: 'Bobrov',
    isActive: true,
    languages: ['English', 'Spanish', 'TCSI', 'Test', 'VPTest'],
    languagesPriority: [
      {
        languageCode: 'English',
        languagePriority: 1,
        isSelected: true,
      },
      {
        languageCode: 'Spanish',
        languagePriority: 1,
        isSelected: false,
      },
      {
        languageCode: 'TCSI',
        languagePriority: 1,
        isSelected: false,
      },
      {
        languageCode: 'Test',
        languagePriority: 1,
        isSelected: false,
      },
      {
        languageCode: 'VPTest',
        languagePriority: 1,
        isSelected: false,
      },
    ],
  },
};
