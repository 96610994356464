import type { FC } from 'react';

import { Hotkey } from 'config/enums';
import { CallDetailsButtonConnect } from 'features/call/call-details/components';

interface CallDetailsButtonCalleeConnectProps {
  isLoading: boolean;
  onClickHandler: () => void;
}
export const CallDetailsButtonCalleeConnect: FC<
  CallDetailsButtonCalleeConnectProps
> = ({ isLoading, onClickHandler }) => {
  return (
    <CallDetailsButtonConnect
      loading={isLoading}
      onClick={onClickHandler}
      hotkey={Hotkey.CalleeConnectionStateChange}
    />
  );
};
