import type { MouseEvent, ReactNode } from 'react';
import { MenuItem, styled, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { useAppSelector } from 'features/app/hooks';
import { selectIsAutoCompleteEnabled } from 'features/call/call-base/store/selectors';
import theme from 'features/theme/theme';

interface CallDetailsAccordianAutoCompleteMenuProps {
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const CallDetailsAccordianAutoCompleteMenu = ({
  children,
  onClick,
}: CallDetailsAccordianAutoCompleteMenuProps) => {
  const isAutoCompleteEnabled = useAppSelector(selectIsAutoCompleteEnabled);

  return (
    <StyledMenuItem onClick={onClick}>
      <IconWrapper>
        {!isAutoCompleteEnabled && <StyledCheckIcon data-testid='check-icon' />}
      </IconWrapper>
      {children}
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(4)} ${theme.spacing(8)};
  width: 230px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: ${theme.palette.primary[500]};
  font-size: 20px;
  margin-right: ${theme.spacing(8)};
`;

const IconWrapper = styled(Box)`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
