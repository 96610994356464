import { styled } from '@mui/material';
import { typography } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon, MessageDotsSquareIcon } from 'assets/svg';
import { Hotkey } from 'config/enums';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { getCurrentDate } from 'features/call/call-base/helpers';
import { deafNameSelector } from 'features/call/call-deaf/store';
import { SipSessionEventBus } from 'features/sip/services';
import { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';
import { agentNumberSelector } from 'features/user/store';
import { useWhileComponentMounted } from 'features/utils/hook';
import {
  messageToClearVPText,
  VrsChatMessageLocal,
  VrsChatMessageRemote,
} from 'features/vrs-chat/components';
import { VrsChatEventBus } from 'features/vrs-chat/services';
import {
  isShowPopupSelector,
  openVrsChatPopup,
  toggleVrsChatPopup,
} from 'features/vrs-chat/store';

export const VrsChat = () => {
  const isShowPopup = useAppSelector(isShowPopupSelector);
  const localAgentNumber = useAppSelector(agentNumberSelector);
  const remoteUserNumber = useAppSelector(deafNameSelector);
  const dispatch = useAppDispatch();
  const whileComponentMounted = useWhileComponentMounted();
  const [isWaitingClearConfirm, setIsWaitingClearConfirm] = useState(false);

  const { t } = useTranslation();

  const closeChat = () => {
    dispatch(toggleVrsChatPopup());
  };

  const closeConfirmHandler = () => {
    closeChat();
    setIsWaitingClearConfirm(false);
  };

  const clearChat = () => {
    setIsWaitingClearConfirm(true);
    SipSessionEventBus.outgoingInfo$.next(messageToClearVPText);
    VrsChatEventBus.$clearMessage.next();
  };
  const handleReceiveMessage = (message: string) => {
    if (message.trim() && !isShowPopup) {
      dispatch(openVrsChatPopup());
    }
  };

  useEffect(() => {
    const receiveMessageSubscription = VrsChatEventBus.$receiveMessage
      .pipe(whileComponentMounted())
      .subscribe(handleReceiveMessage);

    return () => {
      receiveMessageSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleReceiveMessage]);

  return (
    <Container $isShowPopup={isShowPopup}>
      <Header>
        <TitleWithIcon>
          <MessageDotsSquareIcon />
          <HeaderTitle>{t('callerText')}</HeaderTitle>
        </TitleWithIcon>
        <CloseIcon onClick={closeChat} />
      </Header>
      <ChatInitiatedDt>{getCurrentDate()}</ChatInitiatedDt>
      <ChatHistory>
        <LocalMessage>
          <VrsChatMessageLocal agentNumber={localAgentNumber} />
        </LocalMessage>
        <RemoteMessage>
          <VrsChatMessageRemote
            isWaitingClearConfirm={isWaitingClearConfirm}
            userName={remoteUserNumber}
            onCloseConfirm={closeConfirmHandler}
          />
        </RemoteMessage>
      </ChatHistory>
      <Button
        loading={isWaitingClearConfirm}
        variant='outlined'
        color='secondary'
        onClick={clearChat}
        hotkey={Hotkey.ClearText}
        fullWidth
        title='clearAndClose'
      >
        {t('clearAndClose')}
      </Button>
    </Container>
  );
};

const Container = styledProps('div')<{
  $isShowPopup: boolean;
}>`
  max-width: 340px;
  border-radius: 8px;
  background-color: ${theme.palette.grey[900]};
  padding: ${theme.spacing(14, 12, 12, 12)};
  ${({ $isShowPopup }) =>
    !$isShowPopup &&
    `
  display: none;
  `};
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled('div')`
  ${typography(theme.typography.body1)};
  color: ${theme.palette.common.white};
  margin-left: ${theme.spacing(5)};
`;

const TitleWithIcon = styled('div')`
  align-items: center;
  display: flex;
`;

const ChatInitiatedDt = styled('div')`
  width: 100%;
  text-align: center;
  margin-top: ${theme.spacing(18)};
  margin-bottom: ${theme.spacing(16)};
  ${typography(theme.typography.caption)}
  color: ${theme.palette.grey[300]};
`;

const ChatHistory = styled('div')`
  margin-bottom: 4px;
`;

const RemoteMessage = styled('div')`
  margin: ${theme.spacing(14, 2, 16, 12)};
`;

const LocalMessage = styled('div')`
  margin-left: ${theme.spacing(14)};
`;
