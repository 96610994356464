import { init, setConfig } from '@testrtc/watchrtc-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import packageJson from '../../../../package.json';

import { sendAnalyticsError } from 'features/analytics/helpers';
import { LogLevel } from 'features/analytics/enums';
import {
  callIdSelector,
  callLanguageCodeSelector,
  peerUserAgentSelector,
  sipCallIdSelector,
  sipHeaderFromAddressSelector,
} from 'features/call/call-base/store/selectors';
import { userNameSelector } from 'features/user/store';
import { env } from 'features/environment/constants';
import { isVriModeSelector, modeSelector } from 'features/multi-mode/store';
import { rnsConnectionIdSelector } from 'features/session/store';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  remoteIpCheckCompleteSelector,
  remoteVideoIpSelector,
} from 'features/stats/store';

export const useWatchRtc = () => {
  try {
    init();
  } catch (e: any) {
    console.log('Error initializing watchRTC', e?.message);
  }

  const dispatch = useAppDispatch();
  const callId = useSelector(callIdSelector);
  const agentUserName = useSelector(userNameSelector);
  const { watchRtcApiKey } = useFlags();
  const canInit = callId !== 0 && agentUserName !== '' && watchRtcApiKey;
  const mode = useAppSelector(modeSelector);
  const isVri = useAppSelector(isVriModeSelector);
  const sipCallId = useAppSelector(sipCallIdSelector);
  const sipHeaderFromAddress = useAppSelector(sipHeaderFromAddressSelector);
  const language = useAppSelector(callLanguageCodeSelector);
  const rnsSession = useAppSelector(rnsConnectionIdSelector);
  const peerUserAgent = useAppSelector(peerUserAgentSelector);
  const remoteVideoIp = useAppSelector(remoteVideoIpSelector);
  const isRemoteIpCheckComplete = useAppSelector(remoteIpCheckCompleteSelector);
  useEffect(() => {
    if (!canInit || !sipCallId || (!isRemoteIpCheckComplete && isVri)) {
      return;
    }
    try {
      setConfig({
        rtcRoomId: callId.toString(),
        rtcApiKey: watchRtcApiKey,
        rtcPeerId: agentUserName,
        keys: {
          productName: 'Mercury',
          version: packageJson.version,
          environment: env.environment,
          callType: mode,
          language: language,
          sipCallId: sipCallId,
          sessionId: rnsSession,
          peerName: agentUserName,
          remoteProductName: peerUserAgent,
          from: sipHeaderFromAddress,
          remoteServerIP: remoteVideoIp,
        },
      });
    } catch (e: any) {
      dispatch(
        sendAnalyticsError({
          Level: LogLevel.ERROR,
          Method: 'watchRTC.setConfig',
          Message: e?.message,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    callId,
    agentUserName,
    watchRtcApiKey,
    sipCallId,
    remoteVideoIp,
    isRemoteIpCheckComplete,
  ]);
};
