import { createStyled } from '@mui/system';
import type { FunctionComponent } from 'react';

import defaultTheme from './theme';

const styled = createStyled({
  defaultTheme,
});

export const styledProps = (
  component: FunctionComponent | 'div' | 'video' | 'span' | 'button'
) =>
  styled(component as any, {
    shouldForwardProp: (prop: string) => !prop.startsWith('$'),
  });

export default styled;
