import type { RootState } from 'features/app/store/store';

export const isRnsConnectionSuccessSelector = ({ app }: RootState) =>
  app.isRnsConnectionSuccess;

export const isSipConnectionSuccessSelector = ({ app }: RootState) =>
  app.isSipConnectionSuccess;

export const isInnerLoadingSelector = ({ app }: RootState) =>
  app.isInnerLoading;

export const selectIsAppUnloading = ({ app }: RootState) => app.isUnloading;
