import type { HubConnection } from '@microsoft/signalr';
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';

import { modeHeaderKey } from 'features/multi-mode/constants';
import type { Mode } from 'features/multi-mode/enums';
import type { ClientNotificationEvent } from 'features/rns/interfaces';
import { RnsEventBus } from 'features/rns/services';
import { generateSignalRReconnectDelays } from 'features/rns/helpers';

interface RnsConnectionParams {
  host: string;
  token: string;
  mode: Mode;
}

interface ClientNotificationParams extends ClientNotificationEvent {
  receiverId: string;
}

export class RnsConnectionService {
  private static instance: RnsConnectionService;
  private readonly connection!: HubConnection;

  private constructor({ host, mode, token }: RnsConnectionParams) {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${host}/?${modeHeaderKey}=${mode}`, {
        transport: HttpTransportType.WebSockets,
        headers: {
          Authorization: token,
          [modeHeaderKey]: mode,
        },
      })
      .withAutomaticReconnect(generateSignalRReconnectDelays())
      .build();

    this.connection.onclose((error?: Error) => {
      const state = this.connection.state;
      const connectionId = this.connection.connectionId;
      const baseUrl = this.connection.baseUrl;

      if (error) {
        console.error('RNS connection closed with error:', {
          error: error.message,
          connectionId,
          state,
          baseUrl,
        });
      } else {
        console.info('RNS connection closed normally', {
          connectionId,
          state,
          baseUrl,
        });
      }

      RnsEventBus.$close.next({
        error,
        connectionId,
        state,
        baseUrl,
      });
    });

    this.connection.onreconnecting((error) => {
      const state = this.connection.state;
      const connectionId = this.connection.connectionId;

      console.warn('SignalR connection reconnecting:', {
        error: error?.message,
        connectionId,
        state,
      });

      RnsEventBus.$reconnecting.next({
        error: error || new Error('Reconnecting without error details'),
        connectionId,
        state,
      });
    });

    this.connection.onreconnected((connectionId) => {
      const state = this.connection.state;
      const previousConnectionId = this.connection.connectionId;

      console.info('SignalR connection reestablished:', {
        newConnectionId: connectionId,
        oldConnectionId: previousConnectionId,
        state,
      });

      RnsEventBus.$reconnected.next({
        connectionId,
        previousConnectionId,
        state,
      });
    });
  }

  public static getInstance(
    params?: RnsConnectionParams
  ): RnsConnectionService | null {
    if (RnsConnectionService.instance) {
      return RnsConnectionService.instance;
    }

    if (!RnsConnectionService.instance && params) {
      RnsConnectionService.instance = new RnsConnectionService(params);
      return RnsConnectionService.instance;
    }

    console.warn('There is no RNS instance and parameters were not provided');
    return null;
  }

  public getConnection(): HubConnection {
    return this.connection;
  }

  public async start() {
    if (this.connection.state === HubConnectionState.Disconnected) {
      console.info('Starting SignalR connection...', {
        connectionId: this.connection.connectionId,
        state: this.connection.state,
        baseUrl: this.connection.baseUrl,
      });

      try {
        await this.connection.start();
        console.info('SignalR connection started successfully', {
          connectionId: this.connection.connectionId,
        });

        await this.connection.send('GroupJoin', 'AgentStations');
        console.info('Joined AgentStations group');

        RnsEventBus.$connected.next({
          connection: this.connection,
          connectionId: this.connection.connectionId,
          state: this.connection.state,
          baseUrl: this.connection.baseUrl,
        });
      } catch (error) {
        console.error('Failed to start SignalR connection:', {
          error,
          connectionState: this.connection.state,
        });
        throw error;
      }
    }
  }

  public async sendClientNotification({
    eventName,
    receiverId,
    data,
  }: ClientNotificationParams) {
    await this.connection.send(
      'NotifyClient',
      receiverId,
      eventName,
      JSON.stringify(data ?? {})
    );
  }

  public static async cleanup(): Promise<void> {
    if (RnsConnectionService.instance) {
      const connection = RnsConnectionService.instance.connection;
      if (connection.state !== HubConnectionState.Disconnected) {
        await connection.stop();
      }
      console.info('RNS connection cleanup');
      RnsConnectionService.instance = undefined as any;
    }
  }
}
