import type { FC } from 'react';
import { useCallback } from 'react';

import { CallInPhone, CallMonitor, CallOutPhone } from 'assets/svg';
import { useAppSelector } from 'features/app/hooks';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';

export interface CallDetailsParticipantIconProps {
  isDeafParticipant: boolean;
}

export const CallDetailsParticipantIcon: FC<
  CallDetailsParticipantIconProps
> = ({ isDeafParticipant }) => {
  const isDeafToHearing = useAppSelector(isDeafToHearingSelector);

  const getHearingIcon = useCallback(() => {
    if (isDeafToHearing) {
      return <CallInPhone data-testid='call-in-phone' />;
    }
    if (!isDeafToHearing) {
      return <CallOutPhone data-testid='call-out-phone' />;
    }
  }, [isDeafToHearing]);

  return (
    <>
      {isDeafParticipant && <CallMonitor data-testid='call-monitor' />}
      {!isDeafParticipant && getHearingIcon()}
    </>
  );
};
