import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';

export const VrsCallReceivedTakeCallButton: FC<
  Pick<ButtonProps, 'loading' | 'disabled' | 'onClick'>
> = ({ loading, disabled, onClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      fullWidth
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      color='primary'
    >
      {t('takeCall')}
    </Button>
  );
};
