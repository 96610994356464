import { styled, Typography } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';

export interface EmergencyLocationConfirmPopupProps extends PropsWithChildren {
  className?: string;
  onConfirm: () => void;
  onDisprove: () => void;
}

export const EmergencyLocationConfirmPopup: FC<
  EmergencyLocationConfirmPopupProps
> = ({ className, onConfirm, onDisprove }) => {
  const { t: ct } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'emergencyLocationConfirmPopup',
  });

  return (
    <Container className={className}>
      <Typography variant='body2' color={theme.palette.common.white}>
        {t('message')}
      </Typography>
      <ButtonsContainer>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onConfirm}
        >
          {ct('yes')}
        </Button>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onDisprove}
        >
          {ct('no')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled('div')`
  border-radius: ${theme.spacing(4)};
  padding: ${theme.spacing(8)};
  background-color: #760c08;
`;

const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing(5)};
`;
