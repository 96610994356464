import { useEffect, useRef } from 'react';

import { useAppDispatch } from 'features/app/hooks';
import { SignMailRecordingStatusWidget } from 'features/signmail/components';
import { maxVideoRecordingDuration } from 'features/signmail/constants';
import { useSignMailRecording } from 'features/signmail/hooks';
import { SignMailEventBus } from 'features/signmail/services';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { resetSignMailConnectRecord } from 'features/reporting/store';

export const SignMailRecording = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { start } = useSignMailRecording();
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const dispatch = useAppDispatch();

  const startRecording = async () => {
    await start(videoRef);

    timerRef.current = setTimeout(() => {
      SignMailEventBus.recordingFinished$.next();
    }, maxVideoRecordingDuration);
  };

  useEffect(() => {
    startRecording();

    return () => {
      dispatch(resetSignMailConnectRecord());

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container data-testid='signmail-recording'>
      <SignMailRecordingStatusWidget />
      <StyledVideo ref={videoRef} autoPlay></StyledVideo>
    </Container>
  );
};

const Container = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.grey[900]};
`;

const StyledVideo = styled('video')`
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
`;
