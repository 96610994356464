import { Typography } from '@mui/material';
import type { FC } from 'react';

import { CallPhoneHangUp } from 'assets/svg';
import type { HearingParticipant } from 'features/call/call-hearing/interfaces';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

interface ConnectedPhoneNumbersProps {
  list: HearingParticipant[];
  onHangUp: (id: string, phoneNumber: string) => void;
  isHangUpAvailable: boolean;
}

export const ConnectedPhoneNumbers: FC<ConnectedPhoneNumbersProps> = ({
  list,
  onHangUp,
  isHangUpAvailable,
}) => {
  return (
    <ConnectedPhoneNumbersContainer>
      {list.map(({ id, phoneNumber }) => (
        <ConnectedPhoneNumberItem variant='textMediumRegular' key={id}>
          {maskPhoneNumber(phoneNumber)}
          {isHangUpAvailable && (
            <CallPhoneHangUpStyleIcon
              data-testid='connected-phone-number-hang-up-button'
              onClick={() => onHangUp(id, phoneNumber)}
            />
          )}
        </ConnectedPhoneNumberItem>
      ))}
    </ConnectedPhoneNumbersContainer>
  );
};

const ConnectedPhoneNumbersContainer = styled('div')`
  display: flex;
  max-height: 248px;
  overflow-y: auto;
  flex-direction: column;
  margin-top: ${theme.spacing(8)};
`;

const ConnectedPhoneNumberItem = styled(Typography)`
  display: flex;
  justify-content: space-between;
  color: ${theme.palette.common.white};
  padding: ${theme.spacing(4)} 0 ${theme.spacing(4)} ${theme.spacing(6)};
  outline: none;
  border-top: 1px solid ${theme.palette.grey[600]};
  &:hover {
    background-color: ${theme.palette.emergency.main};
  }
`;

const CallPhoneHangUpStyleIcon = styled(CallPhoneHangUp)`
  margin-right: ${theme.spacing(6)};
  vertical-align: middle;
  cursor: pointer;
`;
