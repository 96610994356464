import type { FC } from 'react';

import { CallDetailsParticipantVco2Line } from './CallDetailsParticipantVco2Line';

import { useAppSelector } from 'features/app/hooks';
import { twoLineVcoPhoneNumberSelector } from 'features/call/call-hearing/store';

export const CallDetailsParticipantVco2LineContainer: FC = () => {
  const twoLineVcoPhoneNumber = useAppSelector(twoLineVcoPhoneNumberSelector);

  if (!twoLineVcoPhoneNumber) {
    return null;
  }

  return <CallDetailsParticipantVco2Line />;
};
