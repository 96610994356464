import { alpha, Divider } from '@mui/material';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const Container = styled('div')`
  max-width: 340px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: ${theme.spacing(7, 9, 9)};
  background-color: ${alpha(theme.palette.grey[900], 1)};
`;

export const Separator = styled(Divider)`
  border-color: ${theme.palette.grey[600]};
  margin: ${theme.spacing(8, 0)};
`;

export const ContainerWrapper = styled('div')`
  margin-top: ${theme.spacing(6)};
`;
