export const DEFAULT_BASE_DELAY = 500;
export const DEFAULT_MAX_DELAY = 10000;

export interface RetryOptions {
  baseDelay?: number;
  maxDelay?: number;
}

export const getExponentialBackoffDelay = (
  retryCount: number,
  options?: RetryOptions
): number => {
  const baseDelay = options?.baseDelay ?? DEFAULT_BASE_DELAY;
  const maxDelay = options?.maxDelay ?? DEFAULT_MAX_DELAY;

  const exponentialDelay = Math.min(
    baseDelay * Math.pow(2, retryCount),
    maxDelay
  );
  const jitter = Math.random() * (exponentialDelay * 0.25);
  const totalDelay = exponentialDelay + jitter;
  return Math.floor(Math.max(0, totalDelay));
};
