import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { ConnectionChangeType } from 'features/call/call-base/enums';
import {
  isDeafUnavailableSelector,
  setDeafIsLoading,
  setDeafStatus,
} from 'features/call/call-deaf/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import {
  SignMailRecordingStatus,
  SignMailStatus,
} from 'features/signmail/enums';
import { SignMailEventBus } from 'features/signmail/services';
import {
  changeSignMailStatus,
  resetSignMailState,
  selectSignMailRecordingStatus,
  selectSignMailStatus,
} from 'features/signmail/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';

export const useSignMailHandlers = () => {
  const dispatch = useAppDispatch();
  const signMailRecordingStatus = useAppSelector(selectSignMailRecordingStatus);

  const signMailStatus = useAppSelector(selectSignMailStatus);
  const isDeafStatusUnavailable = useAppSelector(isDeafUnavailableSelector);
  const { handleDeafCancel } = useCallDeafParticipantHandlers();
  const handleDeafUnavailable = useCallback(() => {
    dispatch(setDeafIsLoading(true));
    if (signMailRecordingStatus === SignMailRecordingStatus.RECORDING) {
      SignMailEventBus.recordingFinished$.next();
    } else {
      ParticipantsStatusEventBus.deaf.$disconnected.next({
        connectionChangeType: ConnectionChangeType.AGENT_DISCONNECT_DEAF,
      });
      dispatch(resetSignMailState());
    }
    ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signMailRecordingStatus]);

  const handleGreetingEnded = () => {
    if (!isDeafStatusUnavailable) {
      dispatch(
        sendAnalyticsInfo({
          Method: 'handleGreetingEnded',
          Message:
            'SignMail countdown was attempted to be called in the wrong context.',
          SignMailStatus: signMailStatus,
          SignMailRecordingStatus: signMailRecordingStatus,
        })
      );
      return;
    }
    dispatch(changeSignMailStatus(SignMailStatus.COUNTDOWN));
    dispatch(
      sendAnalyticsInfo({
        method: 'handleGreetingEnded',
        message:
          'SignMail greeting ended and countdown started. SignMailStatus changed to COUNTDOWN.',
      })
    );
  };

  const handleCountdownEnded = () => {
    if (!isDeafStatusUnavailable) {
      return;
    }
    dispatch(changeSignMailStatus(SignMailStatus.RECORDING));
  };

  const handleDeafSkipToSignMail = useCallback(() => {
    handleDeafCancel();
    dispatch(setDeafStatus('unavailable'));
    dispatch(changeSignMailStatus(SignMailStatus.COUNTDOWN));
    dispatch(
      sendAnalyticsInfo({
        method: 'handleDeafSkipToSignMail',
        message:
          'Agent Pushed the call to SignMail. SignMailStatus changed to COUNTDOWN.',
      })
    );
  }, [dispatch, handleDeafCancel]);

  return {
    handleDeafUnavailable,
    handleGreetingEnded,
    handleCountdownEnded,
    handleDeafSkipToSignMail,
  };
};
