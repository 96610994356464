import { FormControlLabel } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useCallHearingParticipantHandlers } from 'features/call/call-accepted/hooks';
import { useKeyBoardEventToConnectCall } from 'features/call/call-details/hooks';
import {
  primaryHearingPhoneNumberSelector,
  selectIsAnyHearingConnecting,
  selectIsLoadingPrimaryHearing,
} from 'features/call/call-hearing/store';
import {
  deafPhoneNumberIsHiddenSelector,
  toggleDeafPhoneNumberIsHiddenState,
} from 'features/deaf-phone-number-hidden/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button, Switch } from 'features/ui/elements';
import { Hotkey } from 'config/enums';
import { selectIsCallCanBeClosed } from 'features/call/call-finish/store';

export const DeafPhoneNumberHidden: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleRegularHearingCall } = useCallHearingParticipantHandlers();
  const isLoadingPrimaryHearing = useAppSelector(selectIsLoadingPrimaryHearing);
  const deafPhoneNumberIsHidden = useAppSelector(
    deafPhoneNumberIsHiddenSelector
  );
  const primaryHearingPhoneNumber = useAppSelector(
    primaryHearingPhoneNumberSelector
  );
  const isCallCanBeClosed = useAppSelector(selectIsCallCanBeClosed);
  const isAnyHearingConnecting = useAppSelector(selectIsAnyHearingConnecting);

  const isConnectLoading = isLoadingPrimaryHearing || isAnyHearingConnecting;

  useKeyBoardEventToConnectCall(
    handleRegularHearingCall,
    [primaryHearingPhoneNumber],
    isCallCanBeClosed || isConnectLoading
  );

  return (
    <>
      <StyledFormControlLabel
        control={
          <Switch
            onChange={() => dispatch(toggleDeafPhoneNumberIsHiddenState())}
            checked={!deafPhoneNumberIsHidden}
          />
        }
        label={t('showCallerId')}
      />

      <Button
        fullWidth
        size='small'
        color='primary'
        loading={isConnectLoading}
        onClick={() => handleRegularHearingCall(primaryHearingPhoneNumber)}
        hotkey={Hotkey.CalleeConnectionStateChange}
        disabled={isCallCanBeClosed}
      >
        {t('connect')}
      </Button>
      {children}
    </>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: ${theme.spacing(8)};
`;
