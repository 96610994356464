import type { TerminationType } from 'features/call/call-base/enums';

export class RecentCallsTracker {
  private static instance: RecentCallsTracker | null = null;

  private readonly callLog: {
    callId: number;
    terminationCode: TerminationType;
  }[] = [];

  private constructor() {
    // private constructor to prevent direct instantiation
  }

  static getInstance(): RecentCallsTracker {
    if (this.instance === null) {
      this.instance = new RecentCallsTracker();
    }
    return this.instance;
  }

  addCallLog(callId: number, terminationCode: TerminationType) {
    if (this.callLog.length >= 5) {
      this.callLog.shift();
    }
    this.callLog.push({ callId, terminationCode });
  }

  getCallLog() {
    return this.callLog;
  }
}
