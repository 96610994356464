import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppState } from 'features/app/store/app/interfaces/appState';

export const initialAppState: AppState = {
  isInnerLoading: false,
  isRnsConnectionSuccess: false,
  isSipConnectionSuccess: false,
  isUnloading: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setInnerLoading: (state, action: PayloadAction<boolean>) => {
      state.isInnerLoading = action.payload;
    },
    setRnsConnectionSuccess: (state, action: PayloadAction<boolean>) => {
      state.isRnsConnectionSuccess = action.payload;
    },
    setSipConnectionSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSipConnectionSuccess = action.payload;
    },
    appUnloading: (state) => {
      state.isUnloading = true;
    },
  },
});

export const {
  setInnerLoading,
  setRnsConnectionSuccess,
  setSipConnectionSuccess,
  appUnloading,
} = appSlice.actions;

export default appSlice.reducer;
