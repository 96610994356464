import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import packageJson from '../../../../package.json';

import { LogLevel } from 'features/analytics/enums';
import { sendAnalytics } from 'features/analytics/store';
import type { RootState } from 'features/app/store/store';
import { LogoutType } from 'features/call/call-base/enums';
import { CallPageStatus } from 'features/call/call-ui-state/enums';
import {
  selectCallPageStatus,
  selectIsTeamingOfferCanceled,
} from 'features/call/call-ui-state/store';
import { leaveQueue } from 'features/leave-queue/store';
import { getMachinePreferencesSelector } from 'features/machine-preferences/store';
import { resetMultiMode } from 'features/multi-mode/store';
import { handleError, showNotification } from 'features/notification/store';
import { PageRoute } from 'features/router/routes';
import {
  autoUpdateSessionActivityTimeMs,
  finishTakingCallsAnalytics,
} from 'features/session/helpers';
import type {
  ReconnectSessionPayload,
  RemoveSessionPayload,
  ReplaceSessionPayload,
  SessionFinishPayload,
} from 'features/session/interfaces';
import { SessionDaoService, SessionService } from 'features/session/services';
import {
  resetSessionId,
  rnsConnectionIdSelector,
  sessionIdSelector,
  setSessionId,
} from 'features/session/store';
import { actualSessionTeamingStatedSelector } from 'features/teaming/teaming-base/store';

export const updateSessionActivity = createAsyncThunk(
  'session/updateActivity',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callPageStatus = selectCallPageStatus(state);
    try {
      const rnsConnectionId = rnsConnectionIdSelector(state);
      const teamingState = actualSessionTeamingStatedSelector(state);

      await SessionDaoService.updateSessionActivity({
        rnsConnectionId,
        teamingState,
      });
    } catch (error) {
      const sessionId = sessionIdSelector(state);
      const session = await SessionDaoService.getSessionBySessionId(sessionId);
      if (!session) {
        dispatch(
          showNotification({ severity: 'info', title: t('sessionExpired') })
        );
        if (callPageStatus === CallPageStatus.IDLE) {
          dispatch(
            sendAnalytics({
              Level: LogLevel.INFO,
              Method: 'updateSessionActivity',
              Message: `User logged out due to invalid session`,
              sessionId: sessionId,
            })
          );
          dispatch(leaveQueue({ shouldFinishSession: false }));
        }
        return;
      }
      dispatch(handleError({ error, title: t('sessionUpdateFailed') }));
    }
  }
);

export const autoUpdateSessionActivity = createAsyncThunk(
  'session/autoUpdateActivity',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const intervalId = setInterval(async () => {
      try {
        const { pathname } = window.location;
        const isCallPage = [
          PageRoute.VRS_CALL,
          PageRoute.VRI_CALL,
          PageRoute.VRI_CONFERENCE,
          PageRoute.VRS_CONFERENCE,
        ].includes(pathname as PageRoute);
        const state = getState() as RootState;
        const isTeamingCanceled = selectIsTeamingOfferCanceled(state);

        if (!isCallPage || isTeamingCanceled) {
          clearInterval(intervalId);
          return;
        }

        dispatch(updateSessionActivity());
      } catch (error) {
        dispatch(handleError({ error }));
        return rejectWithValue(error);
      }
    }, autoUpdateSessionActivityTimeMs());
  }
);

export const createSession = createAsyncThunk(
  'session/create',
  async (rnsConnectionId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const machinePreferences = getMachinePreferencesSelector(state);

      const sessionService = SessionService.getInstance();
      const response = await SessionDaoService.createSession({
        rnsConnectionId,
        clientVersion: packageJson.version,
        machinePreferences,
      });
      sessionService.setSession(response);
      dispatch(setSessionId(response.SessionLoginHistoryID));
      dispatch(autoUpdateSessionActivity());
      return response;
    } catch (error) {
      dispatch(handleError({ error }));
      return rejectWithValue(error);
    }
  }
);

export const replaceSession = createAsyncThunk(
  'session/replace',
  async (
    { logoutType, connectionId }: ReplaceSessionPayload,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const sessionId = sessionIdSelector(state);

      if (!sessionId) {
        return;
      }

      await dispatch(
        removeSession({
          logoutType,
        })
      ).unwrap();
      await dispatch(createSession(connectionId)).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'replaceSession',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const removeSession = createAsyncThunk(
  'session/remove',
  async (
    { logoutType }: RemoveSessionPayload,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const rnsConnectionId = rnsConnectionIdSelector(state);

      await SessionDaoService.removeSession({
        rnsConnectionId,
        logoutType,
      });
      dispatch(resetSessionId());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const reconnectSession = createAsyncThunk(
  'session/reconnect',
  async (request: ReconnectSessionPayload, { dispatch, rejectWithValue }) => {
    try {
      const sessionService = SessionService.getInstance();
      const session = sessionService.getSession();

      if (!session) {
        return;
      }

      const newSession = await SessionDaoService.reconnectSession({
        ...request,
        oldSession: session,
      });
      sessionService.setSession(newSession);
    } catch (error) {
      dispatch(handleError({ error }));
      return rejectWithValue(error);
    }
  }
);

export const updateSessionByCallPageStatus = createAsyncThunk(
  'session/updateSession',
  async (connectionId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callPageStatus = selectCallPageStatus(state);
    // In these cases user is not in the call from a Relay perspective
    const replaceStatuses: CallPageStatus[] = [
      CallPageStatus.IDLE,
      CallPageStatus.CALL_RECEIVED,
      CallPageStatus.TEAMING_OFFER_CANCELED,
    ];
    // In these cases user in the call from a Relay perspective
    const reconnectStatuses: CallPageStatus[] = [
      CallPageStatus.CONNECTED,
      CallPageStatus.TEAMING_OFFER_ACCEPTED,
      CallPageStatus.COLD_HANDOFF_OFFER_ACCEPTED,
      CallPageStatus.SURVEY,
    ];

    if (replaceStatuses.includes(callPageStatus)) {
      dispatch(
        replaceSession({
          logoutType: LogoutType.ConnectionLoss,
          connectionId,
        })
      );
    }

    if (reconnectStatuses.includes(callPageStatus)) {
      dispatch(
        reconnectSession({
          newSessionId: connectionId,
        })
      );
    }
  }
);

export const finishSession = createAsyncThunk(
  'session/finish',
  async ({ logoutType }: SessionFinishPayload, { dispatch }) => {
    try {
      const sessionService = SessionService.getInstance();

      await dispatch(
        removeSession({
          logoutType,
        })
      ).unwrap();

      sessionService.resetSession();

      dispatch(resetMultiMode());

      dispatch(
        finishTakingCallsAnalytics(finishSession.name, LogoutType[logoutType])
      );
    } catch (error) {
      dispatch(handleError({ error }));
    }
  }
);
