import type { FC, PropsWithChildren } from 'react';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

const Participants = styled('div')`
  width: 340px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(16)};
`;

export const VrsCallReceivedParticipantsStyledContainer: FC<
  PropsWithChildren
> = ({ children }) => {
  return <Participants>{children}</Participants>;
};
