import { useEffect } from 'react';

import { useAppDispatch } from 'features/app/hooks';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { EmergencyCallDetailsStatus } from 'features/emergency/enums';
import { setEmergencyCallDetailsStatus } from 'features/emergency/store';
import { useWhileComponentMounted } from 'features/utils/hook';

export function useEmergencyCallDetailsStatusHandler() {
  const whileComponentMounted = useWhileComponentMounted();
  const dispatch = useAppDispatch();

  const handleStatusCalleeConnected = () => {
    dispatch(
      setEmergencyCallDetailsStatus(EmergencyCallDetailsStatus.CALLEE_CONNECTED)
    );
  };

  const handleStatusCalleeReconnect = () => {
    dispatch(
      setEmergencyCallDetailsStatus(EmergencyCallDetailsStatus.CALLEE_RECONNECT)
    );
  };

  useEffect(() => {
    ParticipantsStatusEventBus.hearing.$connecting
      .pipe(whileComponentMounted())
      .subscribe(handleStatusCalleeConnected);

    ParticipantsStatusEventBus.hearing.$connected
      .pipe(whileComponentMounted())
      .subscribe(handleStatusCalleeConnected);

    ParticipantsStatusEventBus.hearing.$disconnected
      .pipe(whileComponentMounted())
      .subscribe(handleStatusCalleeReconnect);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
