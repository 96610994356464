import { Box, styled } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'features/app/hooks';
import {
  useCallDeafParticipantHandlers,
  useCallHearingParticipantHandlers,
} from 'features/call/call-accepted/hooks';
import { callSelector } from 'features/call/call-base/store/selectors';
import { deafSelector } from 'features/call/call-deaf/store';
import {
  CallDetailsButtonCalleeConnect,
  CallDetailsButtonCancel,
  CallDetailsButtonDeafDisconnect,
  CallDetailsButtonDeafReconnect,
  CallDetailsButtonDeafThreeDotMenu,
  CallDetailsButtonDisconnect,
  CallDetailsButtonHearingDisconnect,
  CallDetailsButtonReconnect,
  CallDetailsParticipantIcon,
} from 'features/call/call-details/components';
import type { CallDetailsParticipantInfo } from 'features/call/call-details/interfaces';
import { isHearingToDeafCallerLoadingSelector } from 'features/call/call-hearing-to-deaf/store';
import {
  isPrimaryHearingReconnectSelector,
  primaryHearingSelector,
} from 'features/call/call-hearing/store';
import { isEmergencyCallSelector } from 'features/call/vrs-call/store/vrsCallSelectors';
import { EmergencyCallDetailsCalleeConnecting } from 'features/emergency/components';
import { useSignMailHandlers } from 'features/signmail/hooks';
import {
  isAcceptingUserSelector,
  isTeamingInProgressSelector,
} from 'features/teaming/teaming-base/store';
import { CallDetailsParticipantVco2LineContainer } from 'features/vco-2-line/components';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import { voiceSessionStatusSelector } from 'features/voice-session/store';

export const useCallDetailsCaller = () => {
  const { isDeafToHearing } = useAppSelector(callSelector);
  const deaf = useAppSelector(deafSelector);
  const primaryHearing = useAppSelector(primaryHearingSelector);
  const isHearingToDeafCallerLoading = useAppSelector(
    isHearingToDeafCallerLoadingSelector
  );
  const isTeamingInProgress = useAppSelector(isTeamingInProgressSelector);
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);
  const voiceSessionStatus = useAppSelector(voiceSessionStatusSelector);
  const isEmergencyCall = useAppSelector(isEmergencyCallSelector);
  const isPrimaryHearingReconnect = useAppSelector(
    isPrimaryHearingReconnectSelector
  );

  const { handleEndSignMailSession } = useSignMailHandlers();
  const { handleDeafCancel } = useCallDeafParticipantHandlers();
  const { handleRegularHearingCall, handleHearingDisconnect } =
    useCallHearingParticipantHandlers();
  const { t } = useTranslation('translation', { keyPrefix: 'callDetails' });

  const deafToHearingCallerButton = useMemo(() => {
    if (deaf.status === 'connecting' && !isTeamingInProgress) {
      if (isEmergencyCall) {
        return <EmergencyCallDetailsCalleeConnecting />;
      }

      return (
        <CallDetailsButtonCancel
          loading={deaf.isLoading}
          onClick={handleDeafCancel}
        />
      );
    }
    if (deaf.status === 'connected') {
      return (
        <RootContainer>
          <CallDetailsButtonDeafDisconnect isLoading={deaf.isLoading} />
          {!isAcceptingUser && <CallDetailsButtonDeafThreeDotMenu />}
        </RootContainer>
      );
    }

    if (deaf.status === 'disconnected') {
      return <CallDetailsButtonDeafReconnect phoneNumber={deaf.phoneNumber} />;
    }

    if (deaf.status === 'unavailable') {
      return (
        <CallDetailsButtonDisconnect
          loading={deaf.isLoading}
          onClick={handleEndSignMailSession}
        />
      );
    }
  }, [
    deaf.status,
    deaf.isLoading,
    deaf.phoneNumber,
    isTeamingInProgress,
    isEmergencyCall,
    handleDeafCancel,
    isAcceptingUser,
    handleEndSignMailSession,
  ]);

  const hearingToDeafCallerButton = useMemo(() => {
    if (isAcceptingUser) {
      return null;
    }
    if (
      primaryHearing.id &&
      primaryHearing.status === 'connecting' &&
      voiceSessionStatus === VoiceSessionStatus.ACTIVE
    ) {
      return (
        <CallDetailsButtonCancel
          loading={false}
          onClick={() => handleHearingDisconnect(primaryHearing.id)}
        />
      );
    }

    if (isHearingToDeafCallerLoading) {
      return <CallDetailsButtonCancel loading />;
    }

    if (primaryHearing.status === 'connected') {
      return <CallDetailsButtonHearingDisconnect />;
    }

    if (isPrimaryHearingReconnect) {
      return (
        <CallDetailsButtonReconnect
          loading={false}
          onClick={() => handleRegularHearingCall(primaryHearing.phoneNumber)}
        />
      );
    }

    return (
      <CallDetailsButtonCalleeConnect
        isLoading={false}
        onClickHandler={() =>
          handleRegularHearingCall(primaryHearing.phoneNumber)
        }
      />
    );
  }, [
    primaryHearing.status,
    primaryHearing.id,
    primaryHearing.phoneNumber,
    isHearingToDeafCallerLoading,
    isAcceptingUser,
    voiceSessionStatus,
    handleHearingDisconnect,
    handleRegularHearingCall,
    isPrimaryHearingReconnect,
  ]);

  const callerButton = useMemo(() => {
    if (isDeafToHearing) {
      return (
        <>
          {deafToHearingCallerButton}
          <CallDetailsParticipantVco2LineContainer />
        </>
      );
    }

    return hearingToDeafCallerButton;
  }, [hearingToDeafCallerButton, deafToHearingCallerButton, isDeafToHearing]);

  const callerMetadata = useMemo(() => {
    if (isDeafToHearing) {
      return deaf;
    }
    return primaryHearing;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deaf.status,
    deaf.phoneNumber,
    primaryHearing.id,
    primaryHearing.status,
    primaryHearing.phoneNumber,
    primaryHearing.isWavelloRegistered,
    isDeafToHearing,
  ]);

  const caller = useMemo<CallDetailsParticipantInfo>(() => {
    return {
      ...callerMetadata,
      icon: <CallDetailsParticipantIcon isDeafParticipant={isDeafToHearing} />,
      children: callerButton,

      testId: isDeafToHearing ? 'deaf-details-item' : 'hearing-details-item',
      title: t('callFrom'),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callerMetadata, callerButton, isDeafToHearing]);

  return { caller };
};

const RootContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
