import { alpha, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CallFromIncomingIcon, MedicalCrossIcon } from 'assets/svg';
import { useAppSelector } from 'features/app/hooks';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import { deafSelector } from 'features/call/call-deaf/store';
import { primaryHearingSelector } from 'features/call/call-hearing/store';
import {
  VrsCallReceivedParticipant,
  VrsCallReceivedParticipantsStyledContainer,
  VrsCallReceivedTakeCallButton,
} from 'features/call/call-received/components';
import { useCallReceivedTakeCall } from 'features/call/call-received/hooks';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const EmergencyCallbackCallReceived = () => {
  const { t: ct } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'callDetails' });
  const { handleTakeCall, isLoading: isLoadingTakeCall } =
    useCallReceivedTakeCall();
  const deaf = useAppSelector(deafSelector);
  const hearing = useAppSelector(primaryHearingSelector);
  const isDeafToHearing = useAppSelector(isDeafToHearingSelector);

  const [callerParticipant, calleeParticipant] = useMemo(() => {
    const deafParticipant = {
      name: deaf.name,
      phoneNumber: maskPhoneNumber(deaf.phoneNumber),
      location: deaf.location,
      icon: <CallFromIncomingIcon />,
    };

    const hearingParticipant = {
      name: hearing.name,
      phoneNumber: maskPhoneNumber(hearing.phoneNumber),
      location: hearing.location,
      icon: <MedicalCrossIcon />,
    };

    if (isDeafToHearing) {
      return [deafParticipant, hearingParticipant];
    }

    return [hearingParticipant, deafParticipant];
  }, [deaf, hearing, isDeafToHearing]);

  return (
    <Container>
      <StyledMedicalCrossIcon />
      <StyledTitle variant='h1' align='center'>
        {ct('emergencyCallback')}
      </StyledTitle>
      <VrsCallReceivedParticipantsStyledContainer>
        <VrsCallReceivedParticipant
          title={t('callFrom')}
          {...callerParticipant}
        />
        <VrsCallReceivedParticipant
          title={t('callTo')}
          {...calleeParticipant}
        />
      </VrsCallReceivedParticipantsStyledContainer>
      <VrsCallReceivedTakeCallButton
        onClick={handleTakeCall}
        loading={isLoadingTakeCall}
      />
    </Container>
  );
};

const Container = styled('div')`
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${alpha(theme.palette.grey[900], 0.9)};
  border-radius: 8px;
  padding: ${theme.spacing(23)};
`;

const StyledMedicalCrossIcon = styled(MedicalCrossIcon)`
  & path {
    stroke: ${theme.palette.grey[300]};
  }
`;

const StyledTitle = styled(Typography)`
  margin-top: ${theme.spacing(8)};
  margin-bottom: ${theme.spacing(16)};
  color: ${theme.palette.common.white};
`;
