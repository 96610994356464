import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultEventController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';

import { useAppSelector } from 'features/app/hooks';
import { isEmergencyCallSelector } from 'features/call/vrs-call/store/vrsCallSelectors';
import { deafPhoneNumberIsHiddenSelector } from 'features/deaf-phone-number-hidden/store';
import { useEmergencyFlag } from 'features/emergency/hooks';
import { emergencyConnectionPointSelector } from 'features/emergency/store';
import { mediaDevicesSelector } from 'features/media-devices/store';
import {
  createAgentParticipant,
  createHearingParticipant,
} from 'features/voice-meeting/helpers';
import type { CreateVoiceMeetingParams } from 'features/voice-meeting/interfaces';
import type { VoiceSessionResponse } from 'features/voice-session/interfaces';
import { VoiceSessionDaoService } from 'features/voice-session/services';

export const useVoiceMeetingConnection = () => {
  const mediaDevices = useAppSelector(mediaDevicesSelector);
  const logger = new ConsoleLogger('Chime', LogLevel.INFO);
  const deviceController = new DefaultDeviceController(logger, {
    enableWebAudio: true,
  });
  const emergencyConnectionPoint = useAppSelector(
    emergencyConnectionPointSelector
  )!;
  const isEmergencyCall = useAppSelector(isEmergencyCallSelector);
  const hideDeafPhoneNumber = useAppSelector(deafPhoneNumberIsHiddenSelector);
  const { isEmergencyEnabled } = useEmergencyFlag();

  const getAvailableInterpreterById = (
    voiceSessionState: VoiceSessionResponse,
    id: string
  ) =>
    voiceSessionState?.participants.find(
      (participant) =>
        participant.type === 'agent' &&
        participant.agentId === id &&
        participant.state !== 'disconnected'
    );

  const createMeetingSessionFromState = (
    voiceSessionState: VoiceSessionResponse,
    agentId: string
  ) => {
    const interpreter = getAvailableInterpreterById(voiceSessionState, agentId);

    if (!interpreter) {
      console.warn('DEBUG: Interpreter not found');
    }

    const configuration = new MeetingSessionConfiguration(
      voiceSessionState.chimeMeeting,
      interpreter?.chimeAttendee
    );

    return new DefaultMeetingSession(
      configuration,
      logger,
      deviceController,
      new DefaultEventController(configuration, logger)
    );
  };

  const bindAudio = async (
    session: DefaultMeetingSession,
    audio: HTMLAudioElement
  ) => {
    await Promise.all([
      session.audioVideo.chooseAudioOutput(mediaDevices.speaker),
    ]);

    await session.audioVideo.bindAudioElement(audio);
  };

  const createVoiceSession = async ({
    callId,
    agentUserName,
    deafPhoneNumber,
    hearingPhoneNumber,
  }: CreateVoiceMeetingParams) => {
    const participants = [];
    const agent = createAgentParticipant(agentUserName);
    participants.push(agent);
    if (hearingPhoneNumber) {
      const hearing = createHearingParticipant(
        hearingPhoneNumber,
        deafPhoneNumber
      );
      participants.push(hearing);
    }
    return await VoiceSessionDaoService.createVoiceSession({
      isEmergencyCall: isEmergencyEnabled && isEmergencyCall,
      emergencyCallType: emergencyConnectionPoint,
      isCallerIdBlocked: hideDeafPhoneNumber,
      sessionId: String(callId),
      participants: participants,
    });
  };

  return {
    createMeetingSessionFromState,
    getAvailableInterpreterById,
    bindAudio,
    addParticipant: VoiceSessionDaoService.addParticipant,
    createVoiceSession,
  };
};
