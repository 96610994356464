import { Hotkey } from 'config/enums';
import { useAppSelector } from 'features/app/hooks';
import { useCallHearingParticipantHandlers } from 'features/call/call-accepted/hooks';
import { CallDetailsButtonDisconnect } from 'features/call/call-details/components';
import {
  primaryHearingSelector,
  selectIsLoadingPrimaryHearing,
} from 'features/call/call-hearing/store';

export const CallDetailsButtonHearingDisconnect = () => {
  const { handleHearingDisconnect } = useCallHearingParticipantHandlers();
  const primaryHearing = useAppSelector(primaryHearingSelector);
  const isLoadingPrimaryHearing = useAppSelector(selectIsLoadingPrimaryHearing);

  return (
    <CallDetailsButtonDisconnect
      loading={isLoadingPrimaryHearing}
      onClick={() => handleHearingDisconnect(primaryHearing.id)}
      hotkey={Hotkey.CalleeConnectionStateChange}
    />
  );
};
