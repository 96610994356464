import type { PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { audioEnd } from './audioSlice';
import { callIdSelector, isConferenceCall } from './selectors';

import type { Gender } from 'config/enums';
import { AppSupportedLanguage } from 'config/enums';
import { setInnerLoading } from 'features/app/store/app/appSlice';
import type { RootState } from 'features/app/store/store';
import { AudioPlayer } from 'features/audio-player/services';
import type { CallType } from 'features/call/call-base/enums';
import {
  ConnectionChangeType,
  LogoutType,
  RelayCallState,
  RelayConnectionTypes,
  TerminationType,
} from 'features/call/call-base/enums';
import {
  adaptIncomingCall,
  closeCallAnalytics,
  denyCallAnalytics,
  removePlusFromPhoneNumber,
  takeCallAnalytics,
} from 'features/call/call-base/helpers';
import type {
  Call,
  CallStarvationTimeoutPayload,
  CloseCallPayload,
  ConnectionStateHearingConnectPayload,
  ConnectionStateHearingDisconnectPayload,
  ConnectionStatePayload,
  DenyCallWhileInProgressPayload,
} from 'features/call/call-base/interfaces';
import { CallDaoService } from 'features/call/call-base/services';
import { resetConference } from 'features/call/call-conference/store';
import {
  isDeafConnectedSelector,
  resetCallDeaf,
  resetDeafAudio,
} from 'features/call/call-deaf/store';
import { resetCallDetails } from 'features/call/call-details/store';
import {
  HearingParticipantPhoneNumberType,
  HearingParticipantType,
} from 'features/call/call-hearing/interfaces';
import {
  getHearingById,
  getNotDisconnectedHearingByPhoneNumberSelector,
  isPrimaryHearingConnectedSelector,
  primaryHearingPhoneNumberSelector,
  resetCallHearing,
} from 'features/call/call-hearing/store';
import { resetVrsCall } from 'features/call/vrs-call/store';
import { resetVrsConferenceCall } from 'features/call/vrs-conference-call/store';
import { callerSessionAnalyticsInfo } from 'features/caller-session/helpers';
import {
  incomingCallAccepted,
  resetCallerSession,
  selectIsFullyDisconnected,
  startedTakingCalls,
  stoppedTakingCalls,
} from 'features/caller-session/store';
import { resetColdHandoff } from 'features/cold-handoff/store';
import { resetCustomGreeting } from 'features/custom-greeting/store';
import { resetDeafPhoneNumberHiddenStatus } from 'features/deaf-phone-number-hidden/store';
import { resetDialPad } from 'features/dial-pad/store';
import { resetDialedNumbersHistory } from 'features/dialed-numbers-history/store';
import { resetEmergency } from 'features/emergency/store';
import { resetMultiMode } from 'features/multi-mode/store';
import { handleError } from 'features/notification/store';
import {
  hearingConnectRecordedSelector,
  recordHearingConnect,
  recordTwoLineVCOConnect,
  resetHearingConnectRecord,
  resetReportingState,
  resetTwoLineVCORecord,
  twoLineVCORecordedSelector,
} from 'features/reporting/store';
import {
  finishSession,
  removeSession,
  resetSessionId,
  rnsConnectionIdSelector,
  sessionIdSelector,
} from 'features/session/store';
import { _resetSignMail } from 'features/signmail/store';
import { disableSpawnCall } from 'features/spawn-call/store';
import { resetRemoteVideoIp } from 'features/stats/store';
import { resetTeaming } from 'features/teaming/teaming-base/store';
import { UserStatus } from 'features/user/enums';
import { userIdSelector } from 'features/user/store';
import { toIsoWithTimezone } from 'features/utils/helpers';
import { isPrimaryVcoSenderSelector, resetVco } from 'features/vco/store';
import { resetVideoPrivacy } from 'features/video-privacy/store';
import { getConnectionChangeTypeByDisconnectReason } from 'features/voice-meeting/helpers';
import { resetVoiceSession } from 'features/voice-session/store';
import { resetVrsChatState } from 'features/vrs-chat/store';
import { RecentCallsTracker } from 'features/hidden-debug-menu/components/';
import { resetToIdle } from 'features/call/call-ui-state/store';

export interface CallState {
  id: number;
  languageCode: string;
  genderRequest: Gender | null;
  callType: CallType | null;
  callTakenTime: string;
  isDeafToHearing: boolean;
  relayCallState: RelayCallState;
  isCanBeTransferred: boolean;
  isHearingCallerIdBlocked: boolean;
  isAutoCompleteEnabled: boolean;
  callerConnectionType: RelayConnectionTypes;
  terminationType: TerminationType;
  totalInterpretTime: number;
  sipCallId: string;
  sipHeaderFrom: string;
  lastCallId?: number;
  endpointMacAddress: string;
  peerUserAgent?: string;
  calleeConnectionString?: string;
  connectionStateHistoryCount: number;
}

export const initialCallState: CallState = {
  id: 0,
  languageCode: '',
  genderRequest: null,
  callType: null,
  callTakenTime: '',
  calleeConnectionString: '',
  isDeafToHearing: true,
  relayCallState: RelayCallState.Invalid,
  isCanBeTransferred: true,
  isHearingCallerIdBlocked: false,
  isAutoCompleteEnabled: true,
  callerConnectionType: RelayConnectionTypes.PhoneNum,
  terminationType: TerminationType.Normal,
  totalInterpretTime: 0,
  sipCallId: '',
  sipHeaderFrom: '',
  endpointMacAddress: '',
  peerUserAgent: '',
  connectionStateHistoryCount: 0,
};

export const callSlice = createSlice({
  name: 'call',
  initialState: initialCallState,
  reducers: {
    setReceivedCall: (state, action: PayloadAction<Call>) => {
      return {
        ...state,
        ...adaptIncomingCall(action.payload),
      };
    },
    setUpdatedCall: (state, action: PayloadAction<Call>) => {
      return {
        ...state,
        ...adaptIncomingCall(action.payload),
      };
    },
    setCallType: (state, action: PayloadAction<CallType | null>) => {
      state.callType = action.payload;
    },
    setCallTakenTime: (state, action: PayloadAction<string>) => {
      state.callTakenTime = action.payload;
    },
    resetCall: (state) => {
      return {
        ...initialCallState,
        lastCallId: state.lastCallId,
      };
    },
    setRelayCallState: (state, action: PayloadAction<RelayCallState>) => {
      state.relayCallState = action.payload;
    },
    toggleAutoComplete: (state) => {
      state.isAutoCompleteEnabled = !state.isAutoCompleteEnabled;
    },
    setCallId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setTerminationType: (state, action: PayloadAction<TerminationType>) => {
      state.terminationType = action.payload;
    },
    setSipCallId: (state, action: PayloadAction<string>) => {
      state.sipCallId = action.payload;
    },
    setSipHeaderFrom: (state, action: PayloadAction<string>) => {
      state.sipHeaderFrom = action.payload;
    },
    setLastCallId: (state, action: PayloadAction<number>) => {
      state.lastCallId = action.payload;
    },
    setPeerUserAgent: (state, action: PayloadAction<string>) => {
      state.peerUserAgent = action.payload;
    },
    startConnectionStateHistoryChange: (state) => {
      state.connectionStateHistoryCount++;
    },
    finishConnectionStateHistoryChange: (state) => {
      state.connectionStateHistoryCount--;
    },
  },
});

export const {
  setReceivedCall,
  setUpdatedCall,
  setCallType,
  setCallTakenTime,
  resetCall,
  setRelayCallState,
  setCallId,
  setTerminationType,
  setSipCallId,
  setSipHeaderFrom,
  setLastCallId,
  setPeerUserAgent,
  toggleAutoComplete,
  startConnectionStateHistoryChange,
  finishConnectionStateHistoryChange,
} = callSlice.actions;

export const callReducer = callSlice.reducer;
export const handleResetCall = createAsyncThunk(
  'call/reset',
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    if (!selectIsFullyDisconnected(state)) {
      // Temporary solution until we can handle this in the caller session UI enhancement
      dispatch(
        handleError({
          error: new Error('Caller session not endable'),
          title: t('callErrors.callPossiblyStillConnectedTitle'),
          message: t('callErrors.callPossiblyStillConnectedMessage'),
        })
      );
      throw new Error('Caller session not endable');
    }
    dispatch(performResets());
  }
);

export const answerTimeoutResetCall = createAsyncThunk(
  'call/answerTimeoutReset',
  (_, { dispatch }) => {
    dispatch(performResets());
  }
);

const performResets = createAsyncThunk(
  'call/performResets',
  (_, { dispatch }) => {
    dispatch(resetCall());
    dispatch(resetTeaming());
    dispatch(resetDeafPhoneNumberHiddenStatus());
    dispatch(resetCallHearing());
    dispatch(resetVideoPrivacy());
    dispatch(audioEnd());
    dispatch(resetDialPad());
    dispatch(resetDialedNumbersHistory());
    dispatch(resetVco());
    dispatch(resetConference());
    dispatch(resetCallDetails());
    dispatch(resetVrsCall());
    dispatch(resetColdHandoff());
    dispatch(resetEmergency());
    dispatch(resetCustomGreeting());
    dispatch(resetVrsConferenceCall());
    dispatch(resetDeafAudio());
    dispatch(resetRemoteVideoIp());
    dispatch(resetCallDeaf());
    dispatch(resetVoiceSession());
    dispatch(resetVrsChatState());
    dispatch(resetReportingState());
    dispatch(_resetSignMail());
    dispatch(resetCallerSession());
    dispatch(resetToIdle());
  }
);
export const closeCall = createAsyncThunk(
  'call/close',
  async (
    payload: CloseCallPayload,
    { dispatch, getState, rejectWithValue }
  ) => {
    const callManager = RecentCallsTracker.getInstance();
    const isAnswerTimeout =
      payload.terminationType === TerminationType.AnswerTO;
    try {
      dispatch(setInnerLoading(true));

      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const userId = userIdSelector(state);
      const abandonAfterTaken = Boolean(payload.abandonAfterTaken);
      const terminationType = TerminationType[payload.terminationType];
      const isDeafToHearing = state.call.isDeafToHearing;

      await CallDaoService.closeCall({
        callId,
        userId,
        terminationType: payload.terminationType,
        abandonAfterTaken,
      });
      dispatch(setLastCallId(callId));
      callManager.addCallLog(callId, payload.terminationType);

      dispatch(
        closeCallAnalytics(
          'closeCall',
          abandonAfterTaken,
          terminationType,
          isDeafToHearing
        )
      );
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'closeCall',
        })
      );
      return rejectWithValue(error);
    } finally {
      if (isAnswerTimeout) {
        dispatch(answerTimeoutResetCall());
      } else {
        dispatch(handleResetCall()).unwrap();
      }
      dispatch(disableSpawnCall());
      dispatch(setInnerLoading(false));
    }
  }
);

export const denyCall = createAsyncThunk(
  'call/deny',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      dispatch(stoppedTakingCalls());
      const logoutType = LogoutType[LogoutType.Normal];
      await CallDaoService.denyCall({ callId });
      await dispatch(removeSession({ logoutType: LogoutType.Normal })).unwrap();
      dispatch(resetSessionId());
      dispatch(denyCallAnalytics('denyCall', logoutType));
      dispatch(resetMultiMode());
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'denyCall',
        })
      );
      return rejectWithValue(error);
    } finally {
      await dispatch(handleResetCall()).unwrap();
      dispatch(disableSpawnCall());
      AudioPlayer.stop();
    }
  }
);

export const denyCallWhileInProgress = createAsyncThunk(
  'call/denyCallWhileInProgress',
  async (
    { callId }: DenyCallWhileInProgressPayload,
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const isDeafConnected = isDeafConnectedSelector(state);
      const isHearingConnected = isPrimaryHearingConnectedSelector(state);

      const userStatus =
        isDeafConnected && isHearingConnected
          ? UserStatus.Intrptng
          : UserStatus.InPrgres;

      return await CallDaoService.denyCall({
        callId,
        userStatus,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const shouldCallBeAnswerTimeout =
  () =>
  (
    dispatch: ThunkDispatch<RootState, unknown, any>,
    getState: () => RootState
  ) => {
    const state = getState();
    const isDeafConnected = isDeafConnectedSelector(state);
    const isHearingConnected = isPrimaryHearingConnectedSelector(state);
    if (isDeafConnected || isHearingConnected) {
      return false;
    }
    return true;
  };

export const handleCallNotReceived = createAsyncThunk(
  'call/handleNotReceived',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    dispatch(
      handleError({
        error: t('callNotReceived', { lng: AppSupportedLanguage.EN }),
        title: t('callNotReceived'),
        methodName: 'handleCallNotReceived',
        state: { ...state.call, ...state.callDeaf },
      })
    );

    await dispatch(
      closeCall({
        terminationType: TerminationType.AnswerTO,
        abandonAfterTaken: false,
      })
    );
    // if answer TO, log them out for now so we can make sure they are in a good state.
    dispatch(finishSession({ logoutType: LogoutType.Normal })).unwrap();
  }
);

export const takeCall = createAsyncThunk(
  'call/take',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const userId = userIdSelector(state);
      const rnsSessionId = rnsConnectionIdSelector(state);

      dispatch(takeCallAnalytics('takeCall'));
      const call = await CallDaoService.takeCall({
        callId,
        userId,
        sessionId: rnsSessionId,
      });

      if (!call) {
        throw new Error('Call returned no object');
      }
      dispatch(
        incomingCallAccepted(callId.toString(), call.CallerConnectionString)
      );
      dispatch(callerSessionAnalyticsInfo('Accepted incoming call'));
      dispatch(setRelayCallState(call.CallState));
      dispatch(
        setCallTakenTime(toIsoWithTimezone(call.TakenOrAbandonDateTime))
      );
      return call;
    } catch (error) {
      dispatch(
        handleError({
          title: 'Call no longer available',
          message: 'Returning to the queue for next call',
          error,
          methodName: 'takeCall',
        })
      );
      await dispatch(handleResetCall()).unwrap();
      dispatch(startedTakingCalls());
      dispatch(setInnerLoading(false));
      return rejectWithValue(error);
    } finally {
      AudioPlayer.stop();
    }
  }
);

export const sendConnectionCallState = createAsyncThunk(
  'call/sendConnectionCallState',
  async (payload: ConnectionStatePayload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const isDeafConnected = isDeafConnectedSelector(state);
    const isHearingConnected = isPrimaryHearingConnectedSelector(state);
    const sessionLoginHistoryId = sessionIdSelector(state);
    const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);
    try {
      if (!isPrimaryVcoSender) {
        return;
      }

      dispatch(startConnectionStateHistoryChange());

      const call = await CallDaoService.connectionStateHistory({
        ...payload,
        callId,
        isDeafConnected: payload.isDeafConnected ?? isDeafConnected,
        isHearingConnected,
        sessionLoginHistoryId,
      });

      dispatch(setRelayCallState(call.CallState));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'sendConnectionCallState',
          title: t('callErrors.updateCallState', { callId }),
        })
      );
    } finally {
      dispatch(finishConnectionStateHistoryChange());
    }
  }
);

export const sendConnectionCallStateHearingConnect = createAsyncThunk(
  'call/sendConnectionCallHearingConnect',
  async (
    {
      phoneNumber,
      interpretingSessionType,
    }: ConnectionStateHearingConnectPayload,
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const isConferenceCallType = isConferenceCall(state);
    const isHearingCallerIdBlocked =
      phoneNumber === HearingParticipantPhoneNumberType.ANONYMOUS;

    try {
      const hearingParticipant = getNotDisconnectedHearingByPhoneNumberSelector(
        state,
        removePlusFromPhoneNumber(phoneNumber)
      );

      const isTwoLineVCO =
        hearingParticipant?.type === HearingParticipantType.VCO2LINE;
      const isPrimary =
        hearingParticipant?.type === HearingParticipantType.PRIMARY;
      const isSecondary =
        hearingParticipant?.type === HearingParticipantType.SECONDARY;
      const isInbound = hearingParticipant?.direction === 'inbound';
      const isOutbound = !isInbound;

      if (isSecondary) {
        return;
      }

      if (isTwoLineVCO) {
        await dispatch(
          sendConnectionCallState({
            interpretingSessionType,
            connectionChangeType: ConnectionChangeType.AGENT_CONNECT_VCO,
          })
        ).unwrap();
        dispatch(recordTwoLineVCOConnect());
      }

      // if primary record hearing connect for billing
      if (isPrimary || isHearingCallerIdBlocked) {
        dispatch(recordHearingConnect());
      }

      if (isPrimary && isOutbound) {
        await dispatch(
          sendConnectionCallState({
            interpretingSessionType,
            connectionChangeType: ConnectionChangeType.AGENT_CONNECT_HEARING,
            ...(isConferenceCallType ? { isDeafConnected: true } : {}),
          })
        ).unwrap();
      }

      if (isInbound && (isPrimary || isHearingCallerIdBlocked)) {
        await dispatch(
          sendConnectionCallState({
            interpretingSessionType,
            connectionChangeType: ConnectionChangeType.HEARING_CONNECT,
            ...(isConferenceCallType ? { isDeafConnected: true } : {}),
          })
        ).unwrap();
      }
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'sendConnectionCallStateHearingConnect',
        })
      );
    }
  }
);

export const sendConnectionCallStateHearingDisconnect = createAsyncThunk(
  'call/sendConnectionCallHearingDisconnect',
  async (
    {
      disconnectReason,
      interpretingSessionType,
      id,
    }: ConnectionStateHearingDisconnectPayload,
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const isConferenceCallType = isConferenceCall(state);
    const vco2LineConnectRecorded = twoLineVCORecordedSelector(state);
    const hearingConnectRecorded = hearingConnectRecordedSelector(state);
    try {
      if (!id) {
        return;
      }

      const hearingParticipant = getHearingById(state, id);
      const isTwoLineVco =
        hearingParticipant?.type === HearingParticipantType.VCO2LINE;
      const connectionChangeType = getConnectionChangeTypeByDisconnectReason(
        isTwoLineVco,
        disconnectReason
      );
      const isPrimaryHearing =
        hearingParticipant?.type === HearingParticipantType.PRIMARY;

      // for each type if a connect was never recorded then we should not record a disconnect
      if (isTwoLineVco) {
        if (!vco2LineConnectRecorded) {
          return;
        }
        dispatch(resetTwoLineVCORecord());
      }

      if (isPrimaryHearing) {
        if (!hearingConnectRecorded) {
          return;
        }
        dispatch(resetHearingConnectRecord());
      }

      const isSecondaryHearing =
        hearingParticipant?.type === HearingParticipantType.SECONDARY;

      if (isSecondaryHearing) {
        return;
      }
      await dispatch(
        sendConnectionCallState({
          interpretingSessionType,
          connectionChangeType,
          ...(isConferenceCallType ? { isDeafConnected: true } : {}),
        })
      ).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'sendConnectionCallStateHearingDisconnect',
        })
      );
    }
  }
);

export const updateCalleePhoneNumber = createAsyncThunk(
  'call/updateCalleePhoneNumber',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const primaryHearingPhoneNumber = primaryHearingPhoneNumberSelector(state);

    try {
      await CallDaoService.updateCalleePhoneNumber({
        callId,
        phoneNumber: primaryHearingPhoneNumber,
      });
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'updateCalleePhoneNumber',
        })
      );
    }
  }
);

export const callStarvationTimeout = createAsyncThunk(
  'call/starvationTimeout',
  async (payload: CallStarvationTimeoutPayload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);

    if (callId !== payload.receivedCallId) {
      return;
    }

    try {
      await CallDaoService.starvationReset(callId);
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'callStarvationTimeout',
        })
      );
    }
  }
);

export const updateCallRecord = createAsyncThunk(
  'call/updateCallRecord',
  async (payload: Partial<Call>, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);

      const call = await CallDaoService.updateCall(callId, payload);
      dispatch(setUpdatedCall(call));

      return call;
    } catch (error) {
      dispatch(handleError({ error, methodName: 'updateCallRecord' }));
    }
  }
);

export const getCallState = createAsyncThunk(
  'call/getCallState',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);

      const call = await CallDaoService.getCall(callId);
      dispatch(setRelayCallState(call.CallState));
    } catch (error) {
      dispatch(handleError({ error, methodName: 'getCallState' }));
    }
  }
);
