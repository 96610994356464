import type { RootState } from 'features/app/store/store';
import { emergencyPhoneNumberSelector } from 'features/emergency/store';
import { getNotDisconnectedHearingByPhoneNumberSelector } from 'features/call/call-hearing/store';

export const selectIsEmergencyHearingNotDisconnected = (state: RootState) => {
  const emergencyPhoneNumber = emergencyPhoneNumberSelector(state);
  if (!emergencyPhoneNumber) {
    return false;
  }

  const hearing = getNotDisconnectedHearingByPhoneNumberSelector(
    state,
    emergencyPhoneNumber
  );
  return Boolean(hearing);
};
