import styled from '@emotion/styled';

import theme from 'features/theme/theme';

export const CallerStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: ${theme.spacing(5, 4)};
  background-color: #b1b1b1;
  border-bottom-color: ${theme.palette.primary[500]};
  border-bottom-style: solid;
  border-bottom-width: ${theme.spacing(4)};
  height: ${theme.spacing(50)};
  z-index: 1;
`;

export const RedSpan = styled.span`
  color: crimson;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  width: 50%;
`;

export const BlueParagraph = styled.p`
  color: navy;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  width: 50%;
`;

export const GreenParagraph = styled.p`
  color: #01796f;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  width: 50%;
`;

export const CallLogSection = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 1.1;
`;

export const SmallColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`;

export const LargeColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
