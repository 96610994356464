/* eslint-disable import/no-internal-modules */
import { Divider, styled, Typography } from '@mui/material';
import { typography } from '@mui/system';
import { type FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckIcon, ClockRefreshIcon, MedicalCrossIcon } from 'assets/svg';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { isDeafConnectedSelector } from 'features/call/call-deaf/store';
import { setPrimaryHearingPhoneNumber } from 'features/call/call-hearing/store';
import { EmergencyGpsDetails } from 'features/emergency/components/EmergencyGpsDetails';
import { EmergencyLocationConfirmPopup } from 'features/emergency/components/EmergencyLocationConfirmPopup';
import { EmergencyLocationRecord } from 'features/emergency/components/EmergencyLocationRecord';
import { EmergencyConnectionPoint } from 'features/emergency/enums';
import {
  emergencyConnectionPointSelector,
  emergencyIsNotAtRegisteredAddressSelector,
  emergencySelector,
  isDefinedConnectionPointSelector,
  isRegisteredAddressPendingSelector,
  markAddressVerified,
  phoneNumberECRCSelector,
  phoneNumberPSAPSelector,
  setEmergencyConnectionPoint,
  setIsEmergencyOccurredInRegisteredLocation,
} from 'features/emergency/store';
import { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';
import type { BadgeProps } from 'features/ui/elements';
import { Badge } from 'features/ui/elements';

interface EmergencyInfoProps {
  className?: string;
}

export const EmergencyInfo: FC<EmergencyInfoProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { t: ct } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'emergencyInfo',
  });
  const emergency = useAppSelector(emergencySelector);
  const isRegisteredAddressPending = useAppSelector(
    isRegisteredAddressPendingSelector
  );
  const connectionPoint = useAppSelector(emergencyConnectionPointSelector);
  const isDefinedConnectionPoint = useAppSelector(
    isDefinedConnectionPointSelector
  );
  const emergencyIsNotAtRegisteredAddress = useAppSelector(
    emergencyIsNotAtRegisteredAddressSelector
  );
  const phoneNumberECRC = useAppSelector(phoneNumberECRCSelector);
  const phoneNumberPSAP = useAppSelector(phoneNumberPSAPSelector);
  const isDeafConnected = useAppSelector(isDeafConnectedSelector);

  const [location, setLocation] = useState('');

  const connectionPointText = useMemo(() => {
    if (connectionPoint === EmergencyConnectionPoint.ECRC) {
      return 'ECRC';
    }

    return '911 Center/PSAP';
  }, [connectionPoint]);

  const registrationStatusBadgeProps = useMemo<BadgeProps>(() => {
    if (isRegisteredAddressPending) {
      return {
        icon: ClockRefreshIcon,
        severity: 'error',
        children: 'Pending',
      };
    }

    return {
      icon: CheckIcon,
      severity: 'success',
      children: 'OK',
    };
  }, [isRegisteredAddressPending]);

  const confirmBeingAtRegisteredAddress = useCallback(() => {
    dispatch(setPrimaryHearingPhoneNumber(phoneNumberPSAP));
    dispatch(setEmergencyConnectionPoint(EmergencyConnectionPoint.PSAP));
    dispatch(setIsEmergencyOccurredInRegisteredLocation(true));
    dispatch(markAddressVerified());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disproveBeingAtRegisteredAddress = useCallback(() => {
    dispatch(setPrimaryHearingPhoneNumber(phoneNumberECRC));
    dispatch(setEmergencyConnectionPoint(EmergencyConnectionPoint.ECRC));
    dispatch(setIsEmergencyOccurredInRegisteredLocation(false));
    dispatch(markAddressVerified());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={className} data-testid='emergency-info'>
      <TitleContainer>
        <StyledMedicalCrossIcon title='Medical cross small' />
        <Typography
          variant='textLargeMedium'
          color={theme.palette.common.white}
          ml={4}
        >
          {ct('emergency')}
        </Typography>
      </TitleContainer>
      {emergency.isAddressVerificationNeeded && isDeafConnected && (
        <StyledEmergencyLocationConfirmPopup
          onConfirm={confirmBeingAtRegisteredAddress}
          onDisprove={disproveBeingAtRegisteredAddress}
        />
      )}
      {emergencyIsNotAtRegisteredAddress && (
        <>
          <Separator />
          <StyledEmergencyLocationRecord
            location={location}
            onLocationChange={setLocation}
          />
        </>
      )}
      {isDefinedConnectionPoint && (
        <>
          <Separator />
          <Section>
            <StyledCaption>{t('connectionPoint')}</StyledCaption>
            <StyledText>{connectionPointText}</StyledText>
          </Section>
        </>
      )}
      {emergency.registeredAddress.name && (
        <>
          <Separator />
          <PriorityContainer
            $isGpsHasHigherPriority={isRegisteredAddressPending}
          >
            <Section>
              <SectionHeader>
                <StyledCaption>{ct('registeredAddress')}</StyledCaption>
                <Badge {...registrationStatusBadgeProps} />
              </SectionHeader>
              <StyledText>{emergency.registeredAddress.name}</StyledText>
            </Section>
            {emergency.coordinates && (
              <>
                <Separator />
                <Section>
                  <StyledCaption>{t('gpsDetails')}</StyledCaption>
                  <StyledText>
                    <EmergencyGpsDetails details={emergency.coordinates} />
                  </StyledText>
                </Section>
              </>
            )}
          </PriorityContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled('div')`
  border-radius: 8px;
  padding: ${theme.spacing(8)};
  background-color: ${theme.palette.emergency.main};
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(8)};
`;

const StyledMedicalCrossIcon = styled(MedicalCrossIcon)`
  width: 18px;
  height: 18px;
  & path {
    stroke: ${theme.palette.common.white};
  }
`;

const StyledEmergencyLocationConfirmPopup = styled(
  EmergencyLocationConfirmPopup
)`
  margin-top: ${theme.spacing(8)};
`;

const StyledEmergencyLocationRecord = styled(EmergencyLocationRecord)`
  margin-top: ${theme.spacing(8)};
`;

const Section = styled('div')`
  ${typography(theme.typography.label)};
  color: #e8a8a6;
`;

const SectionHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(4)};
`;

const Separator = styled(Divider)`
  margin: ${theme.spacing(8, 0)};
  border-color: ${theme.palette.grey[400]};
`;

const StyledCaption = styled('div')`
  ${typography(theme.typography.label)};
  color: #e8a8a6;
`;

const StyledText = styled('div')`
  ${typography(theme.typography.textLargeMedium)};
  color: ${theme.palette.common.white};
`;

const PriorityContainer = styledProps('div')<{
  $isGpsHasHigherPriority: boolean;
}>`
  display: flex;
  flex-direction: ${({ $isGpsHasHigherPriority }) =>
    $isGpsHasHigherPriority ? 'column-reverse' : 'column'};
`;
