import { createContext } from 'react';

import type { RtcConnection } from 'features/sip/interfaces';

/**
 * When this is set to an RtcConnection,
 * the RemoteVideocomponent will use that connection's
 * video stream instead of the default one.
 * Useful for Wavello, and possibly other features.
 */
export const RemoteVideoOverrideContext = createContext<
  RtcConnection | undefined
>(undefined);
