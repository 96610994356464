import { styled } from '@mui/material';
import { typography } from '@mui/system';
import type { FC } from 'react';

import theme from 'features/theme/theme';

interface EmergencyGpsAttributeProps {
  label: string;
  value: string;
}

export const EmergencyGpsAttribute: FC<EmergencyGpsAttributeProps> = ({
  label,
  value,
}) => {
  return (
    <Attribute>
      {label}: {value}
    </Attribute>
  );
};

const Attribute = styled('div')`
  display: flex;
  justify-content: space-between;
  ${typography(theme.typography.body1)};
  color: ${theme.palette.common.white};
`;
