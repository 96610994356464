import { createContext, useContext } from 'react';

import type { Popover } from 'features/menu/interfaces';

export const PopoverContext = createContext<Popover>({
  popoverId: '',
  anchorEl: null,
  isOpenPopover: false,
  handleClosePopover: () => {},
  handleShowPopover: () => {},
});

export const usePopoverContext = () => useContext(PopoverContext);
