import { useTranslation } from 'react-i18next';

import { Button } from 'features/ui/elements';

export const EmergencyCallDetailsCalleeConnecting = () => {
  const { t } = useTranslation();

  return (
    <Button fullWidth size='small' color='secondary' loading={false} disabled>
      {t('connecting')}
    </Button>
  );
};
