import type { FC } from 'react';

import { Notification } from './Notification';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  closeNotification,
  notificationSelector,
} from 'features/notification/store';

export const NotificationContainer: FC = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(notificationSelector);

  return (
    <Notification
      {...notification}
      isOpen={notification.isOpen}
      onClose={() => dispatch(closeNotification({ ...notification }))}
    />
  );
};
