import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import type { PeerConnectionEvent } from 'jssip/lib/RTCSession';

import { Language } from 'config/enums';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import type { RootState } from 'features/app/store/store';
import { AudioPlayer } from 'features/audio-player/services';
import { CallStatus, LogoutType } from 'features/call/call-base/enums';
import { CallDaoService } from 'features/call/call-base/services';
import {
  getCallState,
  handleResetCall,
  setCallStatus,
  setCallTakenTime,
} from 'features/call/call-base/store';
import {
  callIdSelector,
  callLanguageCodeSelector,
} from 'features/call/call-base/store/selectors';
import { isDeafConnectedSelector } from 'features/call/call-deaf/store';
import { CallNotificationType } from 'features/call/call-events/enums';
import { setPreviousCallId } from 'features/call/vrs-call-comments/store';
import { resetVrsCall } from 'features/call/vrs-call/store';
import { wasCallPlacedSelector } from 'features/call/vrs-call/store/vrsCallSelectors';
import { incomingCallAccepted } from 'features/caller-session/store';
import { handleError, showNotification } from 'features/notification/store';
import type { Agent } from 'features/rns/interfaces';
import { sendRnsNotification } from 'features/rns/store';
import { SessionDaoService } from 'features/session/services';
import {
  createSession,
  resetSessionId,
  rnsConnectionIdSelector,
} from 'features/session/store';
import { sipSettings } from 'features/sip/config';
import { userAgentHeader } from 'features/sip/constants';
import { RtcSessionTerminateCauses } from 'features/sip/enums';
import { SipSessionEventBus } from 'features/sip/services';
import type {
  AdhocTeamRequestDaoOptions,
  AdhocTeamRequestFormOptions,
} from 'features/teaming/teaming-adhoc/interfaces';
import {
  TeamingReason,
  TeamingStatus,
  TeamingType,
} from 'features/teaming/teaming-base/enums';
import type {
  CompleteTeamingPayload,
  RestoreTeamingPayload,
  TeamRequestDaoOptions,
  TeamRequestFormOptions,
  TeamingBeginPayload,
  TeamingRemovePayload,
} from 'features/teaming/teaming-base/interfaces';
import { TeamingDaoService } from 'features/teaming/teaming-base/services';
import {
  isAcceptingUserSelector,
  isRemoteTeamingSelector,
  isRequestingUserSelector,
  resetTeaming,
  resetTeamingFreeSwitchCallStillInProgress,
  setIsCurrentCallOnFreeSwitch,
  setTeamingAcceptingUser,
  setTeamingAnotherUserSessionId,
  setTeamingOptions,
  setTeamingRequestingUser,
  setTeamingStatus,
  teamingAcceptingUserIdSelector,
  teamingAcceptingUserSelector,
  teamingReasonSelector,
  teamingRoomUriSelector,
  teamingStatusSelector,
} from 'features/teaming/teaming-base/store';
import { UserDaoService } from 'features/user/services';
import { userIdSelector, userSelector } from 'features/user/store';
import { toIsoWithTimezone } from 'features/utils/helpers';
import { VoiceMeetingEventBus } from 'features/voice-meeting/services';
import { voiceSessionIdSelector } from 'features/voice-session/store';
import { callerSessionAnalyticsInfo } from 'features/caller-session/helpers';

export const takeRemoteTeamingRequest = createAsyncThunk(
  'teaming/takeRemote',
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const userId = userIdSelector(state);
      const sessionId = rnsConnectionIdSelector(state);
      const call = await CallDaoService.takeCall({
        callId,
        userId,
        sessionId,
      });
      dispatch(
        setCallTakenTime(toIsoWithTimezone(call.TakenOrAbandonDateTime))
      );
      dispatch(setCallStatus(CallStatus.TEAMING_TAKEN));
      dispatch(
        incomingCallAccepted(callId.toString(), call.CallerConnectionString)
      );
      dispatch(callerSessionAnalyticsInfo('Accepted Teaming Call'));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'takeRemoteTeamingRequest',
        })
      );
    } finally {
      AudioPlayer.stop();
    }
  }
);

export const takeInPersonTeamingRequest = createAsyncThunk(
  'teaming/takeInPerson',
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const userId = userIdSelector(state);
      const sessionId = rnsConnectionIdSelector(state);
      await CallDaoService.takeCall({
        callId,
        userId,
        sessionId,
      });
      dispatch(resetSessionId());
      dispatch(handleResetCall()).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'takeInPersonTeamingRequest',
        })
      );
    } finally {
      AudioPlayer.stop();
    }
  }
);

export const declineTeamingRequest = createAsyncThunk(
  'teaming/decline',
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      await CallDaoService.denyCall({
        callId,
      });
      dispatch(handleResetCall()).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'declineTeamingRequest',
        })
      );
    } finally {
      AudioPlayer.stop();
    }
  }
);

export const restoreTeamingRequest = createAsyncThunk(
  'teaming/restoreRequest',
  async (payload: RestoreTeamingPayload, { dispatch, getState }) => {
    const { teaming } = getState() as RootState;
    // we are waiting to check if the deaf is actually connected because this is a race condition and sometimes this gets called first.
    await new Promise((resolve) => setTimeout(resolve, 1800));
    const isDeafConnected = isDeafConnectedSelector(getState() as RootState);
    if (!isDeafConnected) {
      dispatch(resetTeaming());
      sendAnalyticsInfo({
        Method: 'restoreTeamingRequest',
        message:
          'Deaf user disconnected so we are not allowing another request to be restored',
      });
      return;
    }

    try {
      await TeamingDaoService.requestTeaming({
        callId: payload.callId,
        options: {
          reason: teaming.reason,
          gender: teaming.gender,
          language: teaming.language,
          teamingType: teaming.teamingType,
          isRemote: teaming.isRemote,
        },
      });

      dispatch(setTeamingStatus(TeamingStatus.DECLINED));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'restoreTeamingRequest',
        })
      );
    }
  }
);

export const cancelTeaming = createAsyncThunk(
  'teaming/cancel',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    try {
      await TeamingDaoService.cancelTeaming(callId);
      dispatch(resetTeamingFreeSwitchCallStillInProgress());
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'cancelTeaming',
        })
      );
    }
  }
);

export const cancelTeamingCall = createAsyncThunk(
  'teaming/cancelCall',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const userId = userIdSelector(state);
    try {
      await TeamingDaoService.completeTeaming({
        callId,
        userId,
      });
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'cancelTeamingCall',
        })
      );
    } finally {
      dispatch(handleResetCall()).unwrap();
    }
  }
);

export const completeTeamingCall = createAsyncThunk(
  'teaming/complete',
  async ({ userId }: CompleteTeamingPayload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);

    try {
      await TeamingDaoService.completeTeaming({
        callId,
        userId,
      });
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'completeTeamingCall',
        })
      );
    }
  }
);

export const callTeamingRoom = createAsyncThunk(
  'teaming/begin',
  async ({ uaRef }: TeamingBeginPayload, { dispatch, getState }) => {
    const { teaming } = getState() as RootState;
    const sipConfig = sipSettings.get();
    const options = {
      extraHeaders: [userAgentHeader],
      pcConfig: sipConfig.pcConfig,
      eventHandlers: {
        peerconnection: (event: PeerConnectionEvent) => {
          SipSessionEventBus.peerConnection$.next(event);
        },
        failed: () => {
          dispatch(
            sendRnsNotification({
              eventName: CallNotificationType.TEAMING_USER_CONNECTION_FAILED,
            })
          );
        },
      },
    };

    try {
      dispatch(getCallState());
      uaRef?.current?.call(`${teaming.roomUri};transport=tcp`, options);
      sendAnalyticsInfo({
        Method: 'callTeamingRoom',
        roomUri: teaming.roomUri,
        message: 'Teaming Remote Accepted',
      });
    } catch (error) {
      dispatch(handleError({ error }));
    }
    dispatch(setIsCurrentCallOnFreeSwitch());
  }
);

export const handleTeamingNotification = createAsyncThunk(
  'teaming/handleNotification',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const acceptingUser = teamingAcceptingUserSelector(state);

    dispatch(
      showNotification({
        severity: 'info',
        title: t('teamNotification.remoteUserLogout', {
          userNumber: acceptingUser.number,
          userName: acceptingUser.name,
        }),
      })
    );
  }
);

export const handleTeamingFinish = createAsyncThunk(
  'teaming/handleFinish',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isRequestingUser = isRequestingUserSelector(state);
    const isAcceptingUser = isAcceptingUserSelector(state);
    if (isRequestingUser) {
      dispatch(resetTeamingFreeSwitchCallStillInProgress());
    }

    if (isAcceptingUser) {
      VoiceMeetingEventBus.finishVoiceSession$.next();
      SipSessionEventBus.terminate$.next({
        cause: RtcSessionTerminateCauses.LEAVE_TEAMING,
      });
      dispatch(handleResetCall()).unwrap();
    }
  }
);

export const leaveRemoteTeaming = createAsyncThunk(
  'teaming/leave',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const userId = userIdSelector(state);
    try {
      await TeamingDaoService.completeTeaming({
        callId,
        userId,
      });
      SipSessionEventBus.terminate$.next({
        cause: RtcSessionTerminateCauses.LEAVE_TEAMING,
      });
      VoiceMeetingEventBus.finishVoiceSession$.next();
      dispatch(setPreviousCallId(callId));
      dispatch(handleResetCall()).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'leaveRemoteTeaming',
        })
      );
    }
  }
);

export const leaveInPersonTeaming = createAsyncThunk(
  'teaming/leave',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const userId = userIdSelector(state);

    try {
      await TeamingDaoService.completeTeaming({
        callId,
        userId,
      });

      dispatch(resetTeaming());
      dispatch(resetVrsCall());
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'leaveInPersonTeaming',
        })
      );
    }
  }
);

export const removeTeam = createAsyncThunk(
  'teaming/remove',
  async (
    payload: TeamingRemovePayload,
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);

    try {
      await TeamingDaoService.completeTeaming({
        callId,
        userId: payload.userId,
        suppressTeamEndNotify: payload?.suppressTeamEndNotify,
      });
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'removeTeam',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const handleTeamingCancel = createAsyncThunk(
  'teaming/handleCancel',
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const rnsConnectionId = rnsConnectionIdSelector(state);
      await SessionDaoService.removeSession({
        logoutType: LogoutType.Normal,
        rnsConnectionId,
      });
      dispatch(resetTeamingFreeSwitchCallStillInProgress());
      dispatch(setCallStatus(CallStatus.TEAMING_CANCELED));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'handleTeamingCancel',
        })
      );
    }
  }
);

export const returnToQueue = createAsyncThunk(
  'teaming/returnToQueue',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const sessionId = rnsConnectionIdSelector(state);
    try {
      dispatch(createSession(sessionId)).unwrap();
      dispatch(handleResetCall()).unwrap();
    } catch (error) {
      dispatch(handleError({ error }));
    }
  }
);

export const completeTeaming = createAsyncThunk(
  'teaming/complete',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const teamingStatus = teamingStatusSelector(state);
    const callId = callIdSelector(state);
    const isAcceptingUser = isAcceptingUserSelector(state);
    const acceptingUser = teamingAcceptingUserSelector(state);

    if (isAcceptingUser) {
      return;
    }

    try {
      if (
        teamingStatus === TeamingStatus.ACCEPTED ||
        teamingStatus === TeamingStatus.STARTED
      ) {
        await TeamingDaoService.completeTeaming({
          callId,
          userId: acceptingUser.id,
        });
      }

      if (
        teamingStatus === TeamingStatus.REQUESTED ||
        teamingStatus === TeamingStatus.DECLINED
      ) {
        await TeamingDaoService.cancelTeaming(callId);
      }
    } catch (error) {
      dispatch(handleError({ error }));
      return rejectWithValue(error);
    }
  }
);

export const requestRemoteTeaming = createAsyncThunk(
  'teaming/requestRemote',
  async (options: TeamRequestFormOptions, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const voiceSessionId = voiceSessionIdSelector(state);

    const requestOptions: TeamRequestDaoOptions = {
      reason: TeamingReason[options.reason],
      gender: Number(options.gender),
      language: options.language,
      teamingType: options.location,
      isRemote: true,
    };

    const roomUri = await TeamingDaoService.requestTeaming({
      callId,
      voiceSessionId,
      options: requestOptions,
    });
    dispatch(
      setTeamingOptions({
        ...requestOptions,
        roomUri,
      })
    );
  }
);

export const requestAdhocTeaming = createAsyncThunk(
  'teaming/requestAdhoc',
  async (options: AdhocTeamRequestFormOptions, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const callLanguageCode = callLanguageCodeSelector(state);
    const user = userSelector(state);

    try {
      const requestOptions: AdhocTeamRequestDaoOptions = {
        adhocUsername: options.adhocUsername,
      };

      dispatch(
        setTeamingRequestingUser({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        })
      );

      const adhocUser = await TeamingDaoService.requestAdhocTeaming({
        callId,
        options: requestOptions,
      });

      dispatch(
        setTeamingOptions({
          gender: adhocUser.genderCode,
          teamingType: TeamingType.AdhocReq,
          reason: TeamingReason.Other,
          language: Language[callLanguageCode as Language],
          roomUri: '',
          isRemote: false,
        })
      );

      dispatch(
        setTeamingAcceptingUser({
          id: adhocUser.id,
          number: adhocUser.agentNumber,
          name: `${adhocUser.firstName} ${adhocUser.lastName}`,
        })
      );

      await TeamingDaoService.beginTeaming(callId);
      dispatch(setTeamingStatus(TeamingStatus.STARTED));
    } catch (error) {
      dispatch(resetTeamingFreeSwitchCallStillInProgress());
      dispatch(
        handleError({
          error,
          methodName: 'requestAdhocTeaming',
        })
      );
    }
  }
);

export const requestInPersonTeaming = createAsyncThunk(
  'teaming/requestInPerson',
  async (options: TeamRequestFormOptions, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const voiceSessionId = voiceSessionIdSelector(state);

    const requestOptions: TeamRequestDaoOptions = {
      reason: TeamingReason[options.reason],
      gender: Number(options.gender),
      language: options.language,
      teamingType: options.location,
      isRemote: false,
    };

    await TeamingDaoService.requestTeaming({
      callId,
      voiceSessionId,
      options: requestOptions,
    });
    dispatch(
      setTeamingOptions({
        ...requestOptions,
      })
    );
    dispatch(setTeamingStatus(TeamingStatus.REQUESTED));
  }
);

export const setAnotherTeamingUserSessionId = createAsyncThunk(
  'teaming/setAnotherTeamingUserSessionId',
  async (user: Agent, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const isRemoteTeaming = isRemoteTeamingSelector(state);

    if (!isRemoteTeaming) {
      return;
    }

    try {
      const session = await UserDaoService.getUserSession(user.Id);
      dispatch(setTeamingAnotherUserSessionId(session.SessionId));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setTeamingAcceptingUserInfo = createAsyncThunk(
  'teaming/setTeamingAcceptingUserInfo',
  async (user: Agent, { dispatch, rejectWithValue }) => {
    dispatch(
      setTeamingAcceptingUser({
        id: user.Id,
        number: user.AgentNumber,
        name: `${user.FirstName} ${user.LastName}`,
      })
    );

    try {
      await dispatch(setAnotherTeamingUserSessionId(user)).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          message: t('teaming.requestErrorMessages.setAcceptingUserSessionId'),
          error,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const setTeamingRequestingUserInfo = createAsyncThunk(
  'teaming/setTeamingRequestingUserInfo',
  async (user: Agent, { dispatch, rejectWithValue }) => {
    dispatch(
      setTeamingRequestingUser({
        id: user.Id,
        name: user.FullName,
      })
    );

    try {
      await dispatch(setAnotherTeamingUserSessionId(user)).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          message: t('teaming.requestErrorMessages.setRequestingUserSessionId'),
          error,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const forceAcceptTeaming = createAsyncThunk(
  'teaming/forceAcceptTeaming',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const reason = teamingReasonSelector(state);
      const roomUri = teamingRoomUriSelector(state);
      const teamingAcceptingUserId = teamingAcceptingUserIdSelector(state);

      await TeamingDaoService.forceAcceptTeaming({
        callId,
        userId: teamingAcceptingUserId,
        reason,
        roomUri,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendInfoToViThunk = createAsyncThunk(
  'teaming/sendInfoToVi',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isRequestingUser = isRequestingUserSelector(state);
    const wasCallPlaced = wasCallPlacedSelector(state);

    if (!isRequestingUser) {
      return;
    }

    try {
      dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.INFO_FOR_VI,
          data: {
            callWasPlaced: wasCallPlaced,
          },
        })
      );
      dispatch(
        sendAnalyticsInfo({
          message: `Info for was call placed VI sent to VI2 with the value of ${wasCallPlaced}`,
          Method: 'sendInfoToViThunk',
        })
      );
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'sendInfoToViThunk',
        })
      );
    }
  }
);
