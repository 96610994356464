import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import {
  isAcceptingUserSelector,
  isRemoteTeamingStartedSelector,
} from 'features/teaming/teaming-base/store';

interface VcoState {
  isRequested: boolean;
  isVco2Line: boolean;
}

const initialState: VcoState = {
  isRequested: false,
  isVco2Line: false,
};

export const vcoSlice = createSlice({
  name: 'vco',
  initialState,
  reducers: {
    setIsVcoRequested: (state, action: PayloadAction<boolean>) => {
      state.isRequested = action.payload;
    },
    vco2LineRequested: (state) => {
      state.isVco2Line = true;
    },
    resetVco: () => initialState,
  },
});

export const { setIsVcoRequested, vco2LineRequested, resetVco } =
  vcoSlice.actions;

export const isVcoRequestedSelector = ({ vco }: RootState) => vco.isRequested;

export const isTwoLineVcoSelector = ({ vco }: RootState) => vco.isVco2Line;

export const isPrimaryVcoSenderSelector = createSelector(
  isRemoteTeamingStartedSelector,
  isAcceptingUserSelector,
  (isRemoteTeamingStarted, isAcceptingUser) => {
    if (isRemoteTeamingStarted) {
      return !isAcceptingUser;
    }

    return true;
  }
);

export const shouldDeafMuteBtnBeVisibleSelector = createSelector(
  isVcoRequestedSelector,
  isTwoLineVcoSelector,
  (isVcoRequested, isVco2Line) => {
    return isVcoRequested && !isVco2Line;
  }
);

export const vcoReducer = vcoSlice.reducer;
