import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Hotkey } from 'config/enums';
import { useAppSelector } from 'features/app/hooks';
import { vco2LineSelector } from 'features/call/call-hearing/store';
import { selectIsCallCanBeClosed } from 'features/call/call-finish/store';
import {
  useHearingConnect,
  useHearingDisconnect,
} from 'features/voice-meeting/hooks';

export const useVoiceMeetingVco2LineHandlers = () => {
  const { connectHearing } = useHearingConnect();
  const { disconnectHearing } = useHearingDisconnect();
  const vco2Line = useAppSelector(vco2LineSelector);
  const isCallCanBeClosed = useAppSelector(selectIsCallCanBeClosed);

  const handleVco2LineCall = useCallback(async () => {
    await connectHearing(vco2Line.phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectHearing, vco2Line.phoneNumber]);

  const handleVco2LineCancel = useCallback(async () => {
    await disconnectHearing(vco2Line.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vco2Line.id, vco2Line.phoneNumber]);

  const handleVco2LineDisconnect = useCallback(async () => {
    await disconnectHearing(vco2Line.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vco2Line.id, vco2Line.phoneNumber]);

  useHotkeys(
    Hotkey.Vco2Line,
    () => {
      handleVco2LineCall();
    },
    [handleVco2LineCall],
    {
      enabled: !isCallCanBeClosed,
    }
  );

  return {
    handleVco2LineCall,
    handleVco2LineCancel,
    handleVco2LineDisconnect,
  };
};
