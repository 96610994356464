import type { FC } from 'react';
import { useEffect } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { useSignMailHandlers } from 'features/signmail/hooks';
import { signMailGreetingTextSelector } from 'features/signmail/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const SignMailGreetingTextOnly: FC = () => {
  const text = useAppSelector(signMailGreetingTextSelector);
  const { handleGreetingEnded } = useSignMailHandlers();

  useEffect(() => {
    setTimeout(() => {
      handleGreetingEnded();
    }, 20000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GreetingTextContainer>
      <GreetingText>{text}</GreetingText>
    </GreetingTextContainer>
  );
};

const GreetingTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const GreetingText = styled('div')`
  font-size: 24px;
  color: ${theme.palette.common.white};
  text-align: center;
`;
