import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectIsDeafUserFromSorenson } from 'features/call/call-deaf/store/';
import type { RootState } from 'features/app/store/store';
import {
  nextWavelloIsCapable,
  sendWavelloCheck,
} from 'features/wavello/helpers';
import {
  primaryHearingPhoneNumberSelector,
  setPrimaryHearingIsWavelloRegistered,
} from 'features/call/call-hearing/store';
import { removePlusFromPhoneNumber } from 'features/call/call-base/helpers';
import { updateCallRecord } from 'features/call/call-base/store';
import type { Call } from 'features/call/call-base/interfaces';

/** Finds out if the hearing phone number is registered with Wavello.
 *
 * @param hearingPhoneNumber Phone number without a plus. Like "18015551234"
 */
export const checkWavelloRegistration = createAsyncThunk(
  'wavello/check',
  async (hearingPhoneNumber: string, thunkAPI) => {
    // Send a SIP INFO message instructing the endpoint to check for Wavello and stripping plus sign for Relay
    const isSorensonCall = selectIsDeafUserFromSorenson(
      thunkAPI.getState() as RootState
    );
    if (!isSorensonCall) {
      console.log(
        `"Wavello: skipping registration check for ${hearingPhoneNumber} because deaf side is not a Sorenson device`
      );
      return;
    }
    console.log('Wavello: Checking registration for ' + hearingPhoneNumber);
    const hearingNumberWithoutPlus =
      removePlusFromPhoneNumber(hearingPhoneNumber);
    sendWavelloCheck(hearingNumberWithoutPlus);

    // Handle the response.
    const isWavelloRegistered = await nextWavelloIsCapable();

    const currentNumberWithoutPlus = removePlusFromPhoneNumber(
      primaryHearingPhoneNumberSelector(thunkAPI.getState() as RootState)
    );
    if (currentNumberWithoutPlus !== hearingNumberWithoutPlus) {
      console.log(
        "Wavello: ignoring response because it doesn't match the current number.",
        {
          currentNumberWithoutPlus,
          checkedNumber: hearingNumberWithoutPlus,
          isWavelloRegistered,
        }
      );
      return;
    }

    thunkAPI.dispatch(
      setPrimaryHearingIsWavelloRegistered(isWavelloRegistered)
    );

    if (isWavelloRegistered) {
      const partialCall: Partial<Call> = {
        IsHearingVideoCapable: true,
      };
      thunkAPI.dispatch(updateCallRecord(partialCall));
    }
  }
);
