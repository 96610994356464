import { CallPageStatus } from 'features/call/call-ui-state/enums';
import type { RootState } from 'features/app/store/store';

export const selectCallPageStatus = (state: RootState): CallPageStatus =>
  state.callUiManager.state;

export const selectIsIdle = (state: RootState): boolean =>
  state.callUiManager.state === CallPageStatus.IDLE;

export const selectIsConnected = (state: RootState): boolean =>
  state.callUiManager.state === CallPageStatus.CONNECTED;

export const selectIsTeamingOfferCanceled = (state: RootState): boolean =>
  state.callUiManager.state === CallPageStatus.TEAMING_OFFER_CANCELED;
