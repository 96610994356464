import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PushNotificationService } from 'common/services';
import { useAppDispatch } from 'features/app/hooks';
import { showNotification } from 'features/notification/store';

export const PushNotificationHandler: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const showWarningBeforeUnmountingPage = () => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      window.addEventListener('beforeunload', (e) => {
        e.preventDefault();
        // Not removing this line will for supporting legacy browsers
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        e.returnValue = '';
      });
    }
  };

  useEffect(() => {
    if (!PushNotificationService.getInstance().getNotificationPermission()) {
      dispatch(
        showNotification({
          severity: 'warning',
          title: t('pushNotifications.permissionDeniedWarn'),
        })
      );
    }
    showWarningBeforeUnmountingPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
