import { useAppSelector } from 'features/app/hooks';
import { UserSipHeaderTag } from 'features/user/enums';
import { agentNumberSelector } from 'features/user/store';

export const useUserSipHeader = () => {
  const agentNumber = useAppSelector(agentNumberSelector);

  const getUserSipHeader = (): Record<UserSipHeaderTag, string> => {
    return {
      [UserSipHeaderTag.ALTERNATE_NAME]: `Interpreter ${agentNumber}`,
    };
  };

  return { getUserSipHeader };
};
