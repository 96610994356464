import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { isDeafConnectedSelector } from 'features/call/call-deaf/store';
import { getTextStartSeconds } from 'features/signmail/helpers';
import type { SignMailTextForm } from 'features/signmail/interfaces';
import { SignMailEventBus } from 'features/signmail/services';
import {
  finishRecording,
  isShowSignMailTextFormSelector,
  setTextStartSeconds,
} from 'features/signmail/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button, Textarea } from 'features/ui/elements';
import { useWhileComponentMounted } from 'features/utils/hook';
import { VrsChatEventBus } from 'features/vrs-chat/services';
import { toggleVrsChatPopup } from 'features/vrs-chat/store';

export const SignMailText = () => {
  const dispatch = useAppDispatch();
  const whileComponentMounted = useWhileComponentMounted();
  const { t } = useTranslation('translation', {
    keyPrefix: 'signMail',
  });
  const isDeafConnected = useAppSelector(isDeafConnectedSelector);
  const isShowSignMailTextForm = useAppSelector(isShowSignMailTextFormSelector);

  const { control, resetField, getValues, handleSubmit } =
    useForm<SignMailTextForm>({
      mode: 'onChange',
      defaultValues: {
        signMailText: '',
      },
    });
  const maxLength = 100;
  const { isValid, isDirty } = useFormState({ control });
  const isTextareaHasText = Boolean(getValues().signMailText);

  const resetFormControl = () => {
    resetField('signMailText');
  };

  const handleRecordingFinished = () => {
    const { signMailText } = getValues();
    dispatch(finishRecording({ text: signMailText }));

    resetFormControl();
  };

  const sendMessage = (data: SignMailTextForm) => {
    const { signMailText } = data;
    dispatch(toggleVrsChatPopup());
    VrsChatEventBus.$sendSignMailMessage.next(signMailText);
    resetFormControl();
  };

  useEffect(() => {
    SignMailEventBus.recordingFinished$
      .pipe(whileComponentMounted())
      .subscribe(handleRecordingFinished);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty) {
      const textStartSeconds = getTextStartSeconds();

      dispatch(setTextStartSeconds(textStartSeconds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <>
      {(isShowSignMailTextForm || isTextareaHasText) && (
        <StyledForm onSubmit={handleSubmit(sendMessage)}>
          <Typography variant='h2' color={theme.palette.common.white}>
            {t('title')}
          </Typography>
          <Typography
            variant='subtitle2'
            color={theme.palette.grey[300]}
            mt={12}
          >
            {t('description')}
          </Typography>
          <Controller
            control={control}
            name='signMailText'
            rules={{ maxLength, required: true }}
            render={({ field: { ref, ...args } }) => (
              <Description {...args} inputProps={{ maxLength }} />
            )}
          />
          <StyledButton
            fullWidth
            size='small'
            color='secondary'
            title={t('clearAll')}
            onClick={resetFormControl}
          >
            {t('clearAll')}
          </StyledButton>
          <StyledButton
            disabled={!isValid || !isDeafConnected}
            fullWidth
            type='submit'
            size='small'
            color='secondary'
            title={t('sendMessage')}
          >
            {t('sendMessage')}
          </StyledButton>
        </StyledForm>
      )}
    </>
  );
};

const Description = styled(Textarea)`
  margin-top: ${theme.spacing(8)};
`;

const StyledButton = styled(Button)`
  margin-top: ${theme.spacing(8)};
`;

const StyledForm = styled('form')`
  margin-top: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
  border-radius: ${theme.spacing(4)};
  padding: ${theme.spacing(8)};
`;
