import { useCallback, useEffect, useState } from 'react';

import { consoleLogDev } from 'common/helpers/consoleLogDev';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { updateCalleePhoneNumber } from 'features/call/call-base/store';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { setPrimaryHearingPhoneNumber } from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { EmergencyConnectionPoint } from 'features/emergency/enums';
import {
  addCalledConnectionPoints,
  emergencyConnectionPointSelector,
  emergencyPhoneNumberSelector,
  isAddressVerificationNeededSelector,
  markAddressVerified,
  phoneNumberPSAPSelector,
  selectIsAbandonedEmergencyCall,
  selectIsLoggedOutEmergencyCall,
  setEmergencyConnectionPoint,
  setIsEmergencyDeafReconnectionNeeded,
  turnOffSpawningEmergencyCall,
} from 'features/emergency/store';
import { selectIsEmergencyHearingNotDisconnected } from 'features/emergency/store/emergencyHearingSelectors';
import { isAcceptingUserSelector } from 'features/teaming/teaming-base/store';
import { useWhileComponentMounted } from 'features/utils/hook';
import { useHearingConnect } from 'features/voice-meeting/hooks';
import { psapFallbackOnDeafDisconnectAnalytics } from 'features/emergency/helpers';

export const useEmergencyAutoConnect = () => {
  const whileComponentMounted = useWhileComponentMounted();
  const dispatch = useAppDispatch();
  const { connectHearing } = useHearingConnect();
  const emergencyPhoneNumber = useAppSelector(emergencyPhoneNumberSelector);
  const isAddressVerificationNeeded = useAppSelector(
    isAddressVerificationNeededSelector
  );
  const isAbandonedEmergencyCall = useAppSelector(
    selectIsAbandonedEmergencyCall
  );
  const emergencyConnectionPoint = useAppSelector(
    emergencyConnectionPointSelector
  );
  const isLoggedOutEmergencyCall = useAppSelector(
    selectIsLoggedOutEmergencyCall
  );
  const callId = useAppSelector(callIdSelector);
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);
  const isEmergencyHearingNotDisconnected = useAppSelector(
    selectIsEmergencyHearingNotDisconnected
  );
  const phoneNumberPSAP = useAppSelector(phoneNumberPSAPSelector);
  const [isAutoConnectAttempted, setIsAutoConnectAttempted] = useState(false);

  const attemptAutoConnect = useCallback(async () => {
    try {
      await dispatch(updateCalleePhoneNumber()).unwrap();
      dispatch(setPrimaryHearingPhoneNumber(emergencyPhoneNumber));
      await connectHearing(emergencyPhoneNumber);
      if (emergencyConnectionPoint) {
        dispatch(addCalledConnectionPoints(emergencyConnectionPoint));
      }
      dispatch(setIsEmergencyDeafReconnectionNeeded(false));
    } finally {
      dispatch(turnOffSpawningEmergencyCall());
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * INFO: Adding `connectHearing`, which is not wrapped with `useCallback`, may lead to infinite calls of this function.
     */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [emergencyConnectionPoint, emergencyPhoneNumber, dispatch]);

  const handleDeafUnavailableStatusChange = useCallback(() => {
    dispatch(setPrimaryHearingPhoneNumber(phoneNumberPSAP));
    dispatch(setEmergencyConnectionPoint(EmergencyConnectionPoint.PSAP));
    dispatch(markAddressVerified());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberPSAP]);

  const triggerPSAPFallbackOnDeafDisconnect = useCallback(() => {
    if (
      isLoggedOutEmergencyCall &&
      isAddressVerificationNeeded &&
      !isAutoConnectAttempted
    ) {
      handleDeafUnavailableStatusChange();
      dispatch(
        psapFallbackOnDeafDisconnectAnalytics({
          isLoggedOutEmergencyCall,
          isAddressVerificationNeeded,
          isAutoConnectAttempted,
        })
      );
    }
  }, [
    dispatch,
    handleDeafUnavailableStatusChange,
    isAddressVerificationNeeded,
    isAutoConnectAttempted,
    isLoggedOutEmergencyCall,
  ]);

  useEffect(() => {
    setIsAutoConnectAttempted(false);
  }, [emergencyConnectionPoint]);

  useEffect(() => {
    if (
      isLoggedOutEmergencyCall &&
      isAbandonedEmergencyCall &&
      isAddressVerificationNeeded
    ) {
      handleDeafUnavailableStatusChange();
    }
  }, [
    handleDeafUnavailableStatusChange,
    isAbandonedEmergencyCall,
    isAddressVerificationNeeded,
    isLoggedOutEmergencyCall,
  ]);

  useEffect(() => {
    // If it's *not* a logged-out call, check for other blocking conditions
    if (!isLoggedOutEmergencyCall) {
      if (!callId) {
        logDev('Skipping, no call ID and not a logged-out call');
        dispatch(turnOffSpawningEmergencyCall());
        return;
      }
      if (isAddressVerificationNeeded) {
        logDev(
          'Skipping, address verification needed and not a logged-out call'
        );
        dispatch(turnOffSpawningEmergencyCall());
        return;
      }
      if (isAcceptingUser) {
        logDev('Skipping, user is accepting and not a logged-out call');
        dispatch(turnOffSpawningEmergencyCall());
        return;
      }
      if (emergencyConnectionPoint === 'OTHER') {
        logDev(
          'Skipping, emergency connection point is OTHER and not a logged-out call'
        );
        dispatch(turnOffSpawningEmergencyCall());
        return;
      }
    }

    // Remaining checks (apply regardless of logged-out status)
    if (isAutoConnectAttempted) {
      logDev('Skipping, already attempted');
      return;
    }

    if (!emergencyPhoneNumber) {
      logDev('Skipping, no emergency phone number');
      return;
    }

    if (isEmergencyHearingNotDisconnected) {
      logDev('Skipping, already connected or not disconnected');
      // We still mark as attempted if already connected, to prevent future attempts in this session
      setIsAutoConnectAttempted(true);
      return;
    }

    logDev('Attempting to connect');
    setIsAutoConnectAttempted(true);
    attemptAutoConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAutoConnectAttempted,
    isEmergencyHearingNotDisconnected,
    callId,
    isAddressVerificationNeeded,
    isAcceptingUser,
    attemptAutoConnect,
    emergencyConnectionPoint,
    emergencyPhoneNumber,
    isLoggedOutEmergencyCall,
    dispatch,
  ]);

  useEffect(() => {
    const deafUnavailableSubscription =
      ParticipantsStatusEventBus.deaf.$unavailable
        .pipe(whileComponentMounted())
        .subscribe(handleDeafUnavailableStatusChange);

    const deafDisconnectedSubscription =
      ParticipantsStatusEventBus.deaf.$disconnected
        .pipe(whileComponentMounted())
        .subscribe(triggerPSAPFallbackOnDeafDisconnect);

    return () => {
      deafUnavailableSubscription.unsubscribe();
      deafDisconnectedSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDeafUnavailableStatusChange, triggerPSAPFallbackOnDeafDisconnect]);

  const logDev = (message: string) => {
    consoleLogDev(`DEBUG: Emergency auto connect. ${message}.`);
  };
};
