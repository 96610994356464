import { useEffect } from 'react';

import { useAppDispatch } from 'features/app/hooks';
import { EmergencyCallDetailsStatus } from 'features/emergency/enums';
import { setEmergencyCallDetailsStatus } from 'features/emergency/store';
import { useWhileComponentMounted } from 'features/utils/hook';
import { VoiceMeetingEventBus } from 'features/voice-meeting/services';

export const useEmergencyCallDetailsParticipantHandler = () => {
  const whileComponentMounted = useWhileComponentMounted();
  const dispatch = useAppDispatch();

  const attendeePresenceDeclinedHandler = () => {
    dispatch(
      setEmergencyCallDetailsStatus(EmergencyCallDetailsStatus.CALLEE_RECONNECT)
    );
  };

  useEffect(() => {
    VoiceMeetingEventBus.hearingCallDeclined$
      .pipe(whileComponentMounted())
      .subscribe(attendeePresenceDeclinedHandler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
