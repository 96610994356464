import {
  createAction,
  createSlice,
  nanoid,
  type PayloadAction,
} from '@reduxjs/toolkit';
import type { RTCSession } from 'jssip/lib/RTCSession';
import _ from 'lodash';

import { CallerSessionStatus } from 'features/caller-session/enums/';
import { parsePhoneNumberWithRemovePlus } from 'features/call/call-base/helpers';
import type {
  ColdHandoffRequest,
  RegularCall,
  TeamingRequest,
} from 'features/rns/interfaces';
import type { HearingParticipantSyncUpdate } from 'features/call/call-hearing/interfaces';

//
// Types and values
//

export const NoSessionId = null;

interface CallPushPayload {
  caller: string;
  relayCallId: string;
  isDeafToHearing: boolean;
  endpointMacAddress: string;
}

//
// Slice definition
//

export interface CallerSessionState {
  id: string | null;
  status: CallerSessionStatus;
  caller: string;
  relayCallId: string;
  webRtcRemoteIdentities: string[];
  switchboardConnections: string[];
  errorMessages: string[];
  endpointMacAddress: string;
}

export const initialCallerSessionState: CallerSessionState = {
  id: NoSessionId as string | null,
  status: CallerSessionStatus.NotTakingCalls as CallerSessionStatus,
  caller: '',
  relayCallId: '',
  webRtcRemoteIdentities: [] as string[],
  switchboardConnections: [] as string[],
  errorMessages: [] as string[],
  endpointMacAddress: '',
};

const callerSessionSlice = createSlice({
  name: 'callerSession',
  initialState: initialCallerSessionState,

  reducers: {
    startedTakingCalls: (state) => {
      if (state.status !== CallerSessionStatus.NotTakingCalls) {
        return;
      }
      state.status = CallerSessionStatus.TakingCalls;
    },

    stoppedTakingCalls: (state) => {
      // taking calls and considering can both stop taking calls potentially in case of an error.
      if (
        state.status === CallerSessionStatus.TakingCalls ||
        state.status === CallerSessionStatus.ConsideringPushedCall
      ) {
        state.status = CallerSessionStatus.NotTakingCalls;
      } else {
        state.errorMessages.push(
          'Stopped taking calls while in status ' + state.status
        );
      }
    },

    /** Declining a call also takes us out of the call queue. */
    declinedPushedCall: (state) => {
      if (state.status === CallerSessionStatus.ConsideringPushedCall) {
        return initialCallerSessionState;
      }
    },

    missedPushedCall: (state) => {
      if (state.status === CallerSessionStatus.ConsideringPushedCall) {
        return initialCallerSessionState;
      }
    },

    newRtcConnection: (state, { payload }: PayloadAction<RTCSession>) => {
      if (state.status !== CallerSessionStatus.InCallerSession) {
        state.errorMessages.push('new connection while not in caller session');
        return;
      }
      const identity = payload.remote_identity.uri.toString();
      state.webRtcRemoteIdentities.push(identity);
    },
    newHearingConnection: (state, { payload }: PayloadAction<string>) => {
      if (state.status !== CallerSessionStatus.InCallerSession) {
        state.errorMessages.push('new connection while not in caller session');
        return;
      }

      const parsedNumber = parsePhoneNumberWithRemovePlus(payload);
      if (!state.switchboardConnections.includes(parsedNumber)) {
        state.switchboardConnections.push(parsedNumber);
      }
    },

    rtcDisconnection: (state, { payload }: PayloadAction<RTCSession>) => {
      // TODO: Probably don't let non-serializable RTCSession values get to the reducer
      const identity = payload.remote_identity.uri.toString();
      if (state.status === CallerSessionStatus.InCallerSession) {
        state.webRtcRemoteIdentities = state.webRtcRemoteIdentities.filter(
          (c) => c !== identity
        );
        // TODO: Put in an error of some kind if the session identity is not found in the array
        // But don't be too aggressive about it, because there are multiple ways for things to disconnect
        // and disconnecting from someone who's not connected is always "successful" anyway.
      }
    },
    rtcRefer: (
      state,
      { payload }: PayloadAction<{ oldConnection: string }>
    ) => {
      state.webRtcRemoteIdentities = state.webRtcRemoteIdentities.filter(
        (c) => c !== payload.oldConnection
      );
    },
    hearingDisconnection: (state, { payload }: PayloadAction<string>) => {
      const normalizedNumber = parsePhoneNumberWithRemovePlus(payload);
      state.switchboardConnections = state.switchboardConnections?.filter(
        (c) => c !== normalizedNumber
      );
    },
    callerSessionEnded: (state) => {
      if (state.status !== CallerSessionStatus.InCallerSession) {
        return;
      }
      if (
        _.isEmpty(state.webRtcRemoteIdentities) &&
        _.isEmpty(state.switchboardConnections)
      ) {
        // Already cleaned up!
        state.status = CallerSessionStatus.NotTakingCalls;

        state.caller = '';
        state.errorMessages = [];
        state.id = '';
        state.relayCallId = '';
      } else {
        state.errorMessages.push(
          'Caller session tried to end with active connections: ' +
            state.webRtcRemoteIdentities.join(', ') +
            ' and ' +
            state.switchboardConnections.join(', ')
        );
      }
    },
    resetCallerSession: () => {
      return initialCallerSessionState;
    },
    /** The VI disconnected from the voice session so no switchboard connections are left. */
    voiceSessionEnded: (state) => {
      state.switchboardConnections = [];
    },
    updateSwitchboardConnectionsOnSync: (
      state,
      { payload }: PayloadAction<HearingParticipantSyncUpdate[]>
    ) => {
      state.switchboardConnections = payload
        .filter(({ status }) => ['connected', 'connecting'].includes(status))
        .map(({ phoneNumber }) => parsePhoneNumberWithRemovePlus(phoneNumber));
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      incomingCallPushed,
      (state, action: PayloadAction<CallPushPayload>) => {
        if (state.status === CallerSessionStatus.TakingCalls) {
          state.status = CallerSessionStatus.ConsideringPushedCall;
          state.relayCallId = action.payload.relayCallId;
          state.endpointMacAddress = action.payload.endpointMacAddress;
        } else {
          state.errorMessages.push(
            'Call pushed while in status ' + state.status
          );
        }
      }
    );

    // This is what will begin a caller session.
    builder.addCase(incomingCallAccepted, (state, action) => {
      if (state.status !== CallerSessionStatus.ConsideringPushedCall) {
        state.errorMessages.push(
          'Cannot accept a call while in status ' + state.status
        );
        return;
      }

      if (state.relayCallId !== action.payload.relayCallId) {
        const expected = state.relayCallId;
        const received = action.payload.relayCallId;
        state.errorMessages.push(
          `Cannot accept a call with unexpected Relay call ID. Expected: ${expected}, Received: ${received}`
        );
        return;
      }

      state.status = CallerSessionStatus.InCallerSession;
      state.id = action.payload.newCallerSessionId;
      state.relayCallId = action.payload.relayCallId;
      state.caller = action.payload.callerPhoneNumber;
    });

    builder.addMatcher(
      () => true,
      () => {
        // TODO: This reducer will run after every action,
        // and will make sure that the caller session ends
        // when everyone becomes disconnected.
        // Edge case: Zoom/Teams calls might not appear to have anyone connected
        // but they do still represent a caller session.
      }
    );
  },
});

export const callerSessionReducer = callerSessionSlice.reducer;

//
// Actions
//

export const {
  startedTakingCalls,
  stoppedTakingCalls,
  declinedPushedCall,
  missedPushedCall,
  newRtcConnection,
  rtcDisconnection,
  newHearingConnection,
  hearingDisconnection,
  callerSessionEnded,
  resetCallerSession,
  rtcRefer,
  voiceSessionEnded,
  updateSwitchboardConnectionsOnSync,
} = callerSessionSlice.actions;

export const incomingCallPushed = createAction(
  'callerSession/incomingCallPushed',
  (data: RegularCall | ColdHandoffRequest) => {
    const isRegularCall = (obj: any): obj is RegularCall => {
      return obj.Call !== undefined;
    };
    const callProperty = isRegularCall(data) ? data.Call : data.HandoffCall;

    return {
      payload: {
        caller: callProperty.CallerConnectionString,
        relayCallId: callProperty.Id.toString(),
        isDeafToHearing: callProperty.IsDeafToHearing,
        endpointMacAddress: callProperty.EndpointMacAddress,
      } as CallPushPayload,
    };
  }
);

export const teamingCallPushed = createAction(
  'callerSession/incomingCallPushed',
  (data: TeamingRequest) => {
    return {
      payload: {
        caller: data.TeamingCall.CallerConnectionString,
        relayCallId: data.TeamingCall.Id.toString(),
        isDeafToHearing: data.TeamingCall.IsDeafToHearing,
        endpointMacAddress: data.TeamingCall.EndpointMacAddress,
      } as CallPushPayload,
    };
  }
);

// This means an incoming call has been accepted by the interpeter.
// This will begin a caller session.
export const incomingCallAccepted = createAction(
  'callerSession/incomingCallAccepted',
  (relayCallId: string, callerPhoneNumber: string) => {
    return {
      payload: {
        relayCallId,
        callerPhoneNumber,
        newCallerSessionId: nanoid(),
      },
    };
  }
);
