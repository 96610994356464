import { typography } from '@mui/system';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallDetailsButtonAddTwoLineVco } from 'features/call/call-details/components';
import {
  callErrorSelector,
  setVco2LinePhoneNumber,
  vco2LineStatusSelector,
} from 'features/call/call-hearing/store';
import { PhoneNumberInput } from 'features/phone-number/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Label } from 'features/ui/elements';
import { useVoiceMeetingVco2LineHandlers } from 'features/vco-2-line/hooks';
import { vco2LineRequested } from 'features/vco/store';
import { useValidatePhoneNumberInput } from 'features/phone-number/hooks';

export const CallDetailsAddVco2Line = () => {
  const dispatch = useAppDispatch();
  const { handleVco2LineCall } = useVoiceMeetingVco2LineHandlers();
  const callError = useAppSelector(callErrorSelector);
  const vco2LineStatus = useAppSelector(vco2LineStatusSelector);
  const { ref, isPhoneNumberValid, unmaskedValue } =
    useValidatePhoneNumberInput();

  const handleAddVco2LineRequest = async () => {
    dispatch(vco2LineRequested());
    await handleVco2LineCall();
  };

  useEffect(() => {
    if (isPhoneNumberValid) {
      dispatch(setVco2LinePhoneNumber(unmaskedValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhoneNumberValid, unmaskedValue]);

  const { t } = useTranslation('translation', {
    keyPrefix: 'vco2Line',
  });
  return (
    <>
      {vco2LineStatus === 'disconnected' && (
        <Container data-testid='call-details-add-vco2-line-container'>
          <Head>
            <Title variant='textLargeMedium'>{t('addNewLine')}</Title>
          </Head>
          <Label text={t('label')} />
          <PhoneNumberInputStyle title='phoneNumber' refInput={ref} />
          <CallDetailsButtonAddTwoLineVco
            disabled={!isPhoneNumberValid}
            onClick={handleAddVco2LineRequest}
          />
          {callError && <CallError>{callError}</CallError>}
        </Container>
      )}
    </>
  );
};
const CallError = styled('p')`
  margin-top: ${theme.spacing(4)};
  color: ${theme.palette.error.light};
  ${typography(theme.typography.label)};
`;

const Container = styled('div')`
  margin-top: ${theme.spacing(8)};
  border-radius: ${theme.spacing(4)};
  padding: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
`;

const PhoneNumberInputStyle = styled(PhoneNumberInput)`
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(6)};
  padding: ${theme.spacing(4, 6)};
`;

const Head = styled('div')`
  color: ${theme.palette.common.white};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(4)};
`;

const Title = styled(Typography)`
  color: ${theme.palette.common.white};
`;
