import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import { isDeafConnectedSelector } from 'features/call/call-deaf/store';
import { isPrimaryHearingConnectedSelector } from 'features/call/call-hearing/store';
import { VrsCallType } from 'features/call/vrs-call/enums';
import {
  isTeamingReasonEmergencySelector,
  isTeamingStartedSelector,
} from 'features/teaming/teaming-base/store';

export const vrsCallTypeSelector = ({ vrsCall }: RootState) => vrsCall.callType;

export const isEmergencyCallSelector = ({ vrsCall }: RootState) =>
  vrsCall.callType === VrsCallType.EMERGENCY_CALL;

export const isEmergencyTeamingCallSelector = createSelector(
  isEmergencyCallSelector,
  isTeamingReasonEmergencySelector,
  (isVrsCallTypeEmergency, isTeamingReasonEmergency) =>
    isVrsCallTypeEmergency || isTeamingReasonEmergency
);

export const shouldCreateDataChannelSelector = ({ vrsCall }: RootState) =>
  vrsCall.shouldCreateDataChannel;

export const isDeafAutoConnectEnabledSelector = createSelector(
  isDeafToHearingSelector,
  vrsCallTypeSelector,
  isTeamingStartedSelector,
  (isDeafToHearing, vrsCallType, isTeamingStarted) =>
    !isDeafToHearing &&
    !isTeamingStarted &&
    vrsCallType !== VrsCallType.FAST_CALL
);

export const isParticipantsConnectedSelector = createSelector(
  isDeafConnectedSelector,
  isPrimaryHearingConnectedSelector,
  (deafConnected, hearingConnected) => deafConnected && hearingConnected
);

export const wasCallPlacedSelector = ({ vrsCall }: RootState) =>
  vrsCall.callWasPlaced;
