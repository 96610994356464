export class FeatureFlagService {
  private static context = {
    kind: 'multi',
  };
  public flags: Record<string, boolean | string | number> = {};

  private static instance: FeatureFlagService;

  public static getInstance(): FeatureFlagService {
    if (!FeatureFlagService.instance) {
      FeatureFlagService.instance = new FeatureFlagService();
    }
    return FeatureFlagService.instance;
  }
  public setFlags(newFlags: Record<string, boolean | string | number>): void {
    this.flags = { ...this.flags, ...newFlags };
  }

  public static getIdentifyContext(key: string, value: Record<string, string>) {
    FeatureFlagService.context = {
      ...FeatureFlagService.context,
      [key]: value,
    };
    return FeatureFlagService.context;
  }
}
