import type { FC } from 'react';
import { useEffect } from 'react';
import { useIMask } from 'react-imask';

import { useAppDispatch } from 'features/app/hooks';
import { ChangePhoneNumber } from 'features/call/call-details/components';
import { setVco2LinePhoneNumber } from 'features/call/call-hearing/store';
import { maskValidate } from 'features/phone-number/constants';
import styled from 'features/theme/styled';
import { useVoiceMeetingVco2LineHandlers } from 'features/vco-2-line/hooks';

export const CallDetailsParticipantChangePhoneNumberVco2Line: FC = () => {
  const dispatch = useAppDispatch();

  const { handleVco2LineCall } = useVoiceMeetingVco2LineHandlers();
  const { ref, unmaskedValue, maskRef } = useIMask<HTMLInputElement>({
    mask: maskValidate,
  });

  const isPhoneNumberValid = maskRef.current?.masked.isComplete;

  useEffect(() => {
    if (isPhoneNumberValid) {
      dispatch(setVco2LinePhoneNumber(unmaskedValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhoneNumberValid, unmaskedValue]);

  return (
    <Container>
      <ChangePhoneNumber
        refPhoneNumber={ref}
        isPhoneNumberValid={isPhoneNumberValid}
        onClick={handleVco2LineCall}
      />
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
