import VideoPrivacyIcon from 'assets/images/video-privacy.png';
import { useAppSelector } from 'features/app/hooks';
import { mediaDevicesSelector } from 'features/media-devices/store';
import { videoSettings } from 'features/media-streams/constants';

export const useMediaStream = () => {
  const { camera, microphone } = useAppSelector(mediaDevicesSelector);

  const getAudioStream = () =>
    navigator.mediaDevices.getUserMedia({
      audio: { deviceId: microphone ? { exact: microphone } : undefined },
      video: false,
    });

  const getVideoStream = (): Promise<MediaStream> =>
    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: camera ? { exact: camera } : undefined,
          ...videoSettings,
        },
        audio: false,
      })
      .then((stream) => {
        const track = stream.getVideoTracks()[0];
        // crop to 960x720
        return track
          .applyConstraints({
            width: { exact: 960 },
            height: { exact: 720 },
          })
          .then(() => {
            return stream;
          })
          .catch((err) => {
            console.error('Apply constraints failed:', err);
            console.log('Fallback settings:', track.getSettings());
            return stream;
          });
      })
      .catch((err) => {
        console.error('Failed to get video stream:', err);
        throw err;
      });

  const getPrivacyMediaStreamTrack = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const image = new Image();
    image.src = VideoPrivacyIcon;
    image.onload = () => {
      context?.drawImage(image, 0, 0, 300, 150);
    };
    return canvas.captureStream().getVideoTracks()[0];
  };

  return {
    getAudioStream,
    getVideoStream,
    getPrivacyMediaStreamTrack,
  };
};
