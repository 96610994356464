import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useSignMailHandlers } from 'features/signmail/hooks';
import { signMailGreetingTextSelector } from 'features/signmail/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { useWhileComponentMounted } from 'features/utils/hook';
import { sendAnalyticsInfo } from 'features/analytics/helpers';

export const SignMailGreetingTextOnly: FC = () => {
  const text = useAppSelector(signMailGreetingTextSelector);
  const { handleGreetingEnded } = useSignMailHandlers();
  const whileComponentMounted = useWhileComponentMounted();
  const dispatch = useAppDispatch();

  const timeoutId = useRef<NodeJS.Timeout>();

  const handleClearTimeout = useCallback(() => {
    if (timeoutId.current) {
      console.log('DEBUG: clearing timeout from SignMailGreetingTextOnly');
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
  }, []);

  const handleTimeoutExpired = useCallback(() => {
    dispatch(
      sendAnalyticsInfo({
        method: 'SignMailGreetingTextOnly Timeout Expired',
        message:
          'SignMailGreetingTextOnly timeout expired attempting to move to next state',
      })
    );
    handleClearTimeout();
    handleGreetingEnded();
  }, [dispatch, handleClearTimeout, handleGreetingEnded]);

  useEffect(() => {
    const subscription = ParticipantsStatusEventBus.deaf.$disconnectSignMail
      .pipe(whileComponentMounted())
      .subscribe(handleClearTimeout);

    return () => {
      subscription.unsubscribe();
    };
  }, [handleClearTimeout, whileComponentMounted]);

  useEffect(() => {
    timeoutId.current = setTimeout(handleTimeoutExpired, 20000);

    return () => {
      console.log('DEBUG: Clearing timeout on component unmount');
      clearTimeout(timeoutId.current);
    };
  }, [dispatch, handleTimeoutExpired]);

  return (
    <GreetingTextContainer>
      <GreetingText>{text}</GreetingText>
    </GreetingTextContainer>
  );
};

const GreetingTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const GreetingText = styled('div')`
  font-size: 24px;
  color: ${theme.palette.common.white};
  text-align: center;
`;
