import { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Hotkey } from 'config/enums';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallStatus } from 'features/call/call-base/enums';
import {
  handleCallNotReceived,
  setCallStatus,
  shouldCallBeAnswerTimeout,
  takeCall,
} from 'features/call/call-base/store';
import {
  selectCanRelayTransferCallToMercury,
  isDeafToHearingSelector,
} from 'features/call/call-base/store/selectors';
import { callAnswerTimeoutMs } from 'features/call/call-received/helpers';
import { isDeafConnectedSelector } from 'features/call/call-deaf/store';
import { isPrimaryHearingConnectedSelector } from 'features/call/call-hearing/store';
import { SipSessionEventBus } from 'features/sip/services';

export const useCallReceivedTakeCall = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isDeafToHearing = useAppSelector(isDeafToHearingSelector);
  const isDeafConnected = useAppSelector(isDeafConnectedSelector);
  const isHearingConnected = useAppSelector(isPrimaryHearingConnectedSelector);
  const canRelayTransferCallToMercury = useAppSelector(
    selectCanRelayTransferCallToMercury
  );
  const timeoutId = useRef<number | null>(null);

  const handleCallTimeout = useCallback(async () => {
    const shouldAnswerTimeoutOccur = dispatch(shouldCallBeAnswerTimeout());
    if (shouldAnswerTimeoutOccur) {
      SipSessionEventBus.endCall$.next();
      await dispatch(handleCallNotReceived());
    }
  }, [dispatch]);

  const handleTakeCall = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(takeCall());

      if (!isDeafToHearing) {
        dispatch(setCallStatus(CallStatus.CALL_IN_PROGRESS));
      }
      // If this is false, RNS will send an initiate dial event to Mercury so we won't want to time out the call
      if (canRelayTransferCallToMercury) {
        timeoutId.current = window.setTimeout(
          handleCallTimeout,
          callAnswerTimeoutMs()
        );
      }
    } catch (error) {
      console.error('Error taking call', error);
    }
  }, [
    dispatch,
    isDeafToHearing,
    canRelayTransferCallToMercury,
    handleCallTimeout,
  ]);

  useHotkeys(
    Hotkey.TakeCall,
    () => {
      if (!isLoading) {
        handleTakeCall();
      }
    },
    [handleTakeCall, isLoading]
  );

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        console.log('DEBUG: Clearing timeout on unmount');
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // clear timer if call is connected or hearing party is connected.
    if (!timeoutId.current) {
      return;
    }
    if (isDeafConnected || isHearingConnected) {
      console.log('DEBUG: Clearing timeout as call is connected');
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  }, [isDeafConnected, isHearingConnected]);

  return { handleTakeCall, isLoading };
};
