import _ from 'lodash';

import { CallerSessionStatus } from 'features/caller-session/enums';
import type { RootState } from 'features/app/store/store';

export function selectLastError(state: RootState) {
  const slice = state.callerSession;
  return slice.errorMessages.at(-1);
}

export function selectCallerSessionId(state: RootState) {
  const slice = state.callerSession;
  return slice.id;
}

/** True if Mercury should allow calls to be pushed from Relay. */
export function selectAllowPushingCalls(state: RootState) {
  const slice = state.callerSession;
  return slice.status === CallerSessionStatus.TakingCalls;
}

export function selectCallerSessionStatus(state: RootState) {
  return state.callerSession.status;
}

export const selectIsInCallerSession = (state: RootState) => {
  return state.callerSession.status === CallerSessionStatus.InCallerSession;
};

export const selectIsFullyDisconnected = (state: RootState) => {
  const slice = state.callerSession;
  const noActiveConnections =
    _.isEmpty(slice.webRtcRemoteIdentities) &&
    _.isEmpty(slice.switchboardConnections);
  return noActiveConnections;
};

export const selectWebRtcRemoteIdentities = (state: RootState) => {
  return state.callerSession.webRtcRemoteIdentities;
};

export const selectSwitchboardConnections = (state: RootState) => {
  return state.callerSession.switchboardConnections;
};
