import { SignMailRecorderService } from 'features/signmail/services';

export const getTextStartSeconds = () => {
  const recordingStartDate =
    SignMailRecorderService.getInstance().getRecordingStartDate();

  if (recordingStartDate) {
    const startTypingDate = new Date();
    const diff = startTypingDate.valueOf() - recordingStartDate.valueOf();

    return Math.round(diff / 1000);
  }

  return 0;
};
