import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { closeCall, handleResetCall } from 'features/call/call-base/store';
import {
  callIdSelector,
  terminationTypeSelector,
} from 'features/call/call-base/store/selectors';
import { CallTimerDaoService } from 'features/call/call-timer/services';
import { terminationDeciderVrs } from 'features/call/vrs-call/helpers';
import { resetCallWasPlaced } from 'features/call/vrs-call/store';
import { wasCallPlacedSelector } from 'features/call/vrs-call/store/vrsCallSelectors';
import { resetReportingState } from 'features/reporting/store';
import {
  isAcceptingUserSelector,
  isTeamingStartedSelector,
} from 'features/teaming/teaming-base/store';
import { ReportType } from 'features/call/vrs-call-comments/enums';
import type { VrsCallCommentsState } from 'features/call/vrs-call-comments/interfaces';
import { handleError } from 'features/notification/store';

export const initialVrsCallCommentsState: VrsCallCommentsState = {
  isReportNeeded: false,
  previousCallId: 0,
  reportType: ReportType.DEFAULT,
};

const vrsCallCommentsSlice = createSlice({
  name: 'vrs-call-comments',
  initialState: initialVrsCallCommentsState,
  reducers: {
    setIsReportNeeded: (state, action: PayloadAction<boolean>) => {
      state.isReportNeeded = action.payload;
    },
    setPreviousCallId: (state, action: PayloadAction<number>) => {
      state.previousCallId = action.payload;
    },
    setReportType: (state, action: PayloadAction<ReportType>) => {
      state.reportType = action.payload;
    },
    resetCallComments: () => initialVrsCallCommentsState,
  },
});

export const {
  setIsReportNeeded,
  setPreviousCallId,
  resetCallComments,
  setReportType,
} = vrsCallCommentsSlice.actions;

export const vrsCallCommentsReducer = vrsCallCommentsSlice.reducer;

export const closeCallAfterReport = createAsyncThunk(
  'vrsCallComments/closeCallAfterReport',
  async (__, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const isTeamingStarted = isTeamingStartedSelector(state);
    const isAcceptingUser = isAcceptingUserSelector(state);
    const wasCallPlaced = wasCallPlacedSelector(state);
    const terminationType = terminationTypeSelector(state);

    if (!callId || (isTeamingStarted && isAcceptingUser)) {
      return;
    }

    try {
      const interpretTime =
        await CallTimerDaoService.getTotalInterpretTime(callId);
      const termType = terminationDeciderVrs({
        interpretTime,
        wasCallPlaced,
        terminationType,
      });
      await dispatch(closeCall({ terminationType: termType }));
    } catch (error) {
      console.error('Error in closeCallAfterReport:', error);
      dispatch(
        handleError({
          error: error as Error,
          message: 'Error in closeCallAfterReport',
        })
      );
      dispatch(handleResetCall());
    } finally {
      dispatch(resetCallWasPlaced());
      dispatch(resetReportingState());
      dispatch(setIsReportNeeded(false));
      dispatch(setPreviousCallId(callId));
      dispatch(resetCallComments());
    }
  }
);
