import { Snackbar } from '@mui/material';
import type { FC } from 'react';

import type { CustomAlertProps } from 'features/ui/elements';
import { Alert } from 'features/ui/elements';

interface NotificationProps extends CustomAlertProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const Notification: FC<NotificationProps> = ({
  isOpen,
  onClose,
  ...args
}) => {
  return (
    <Snackbar
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        title={args.title}
        message={args.message}
        severity={args.severity}
        onClose={onClose}
      />
    </Snackbar>
  );
};
