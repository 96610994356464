import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Action } from 'redux';
import type { ThunkDispatch } from '@reduxjs/toolkit';

import { updateCalleeConnectionString } from 'features/call/call-base/store/callSlice';
import type { RootState } from 'features/app/store/store';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { primaryHearingPhoneNumberSelector } from 'features/call/call-hearing/store';
import { deafPhoneNumberSelector } from 'features/call/call-deaf/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { handleError } from 'features/notification/store';
import { CallDaoService } from 'features/call/call-base/services';
import {
  addPlusToPhoneNumber,
  parsePhoneNumber,
  normalizePhoneNumber,
} from 'features/call/call-base/helpers';

/**
 * Check and update callee as needed
 */
export const checkAndUpdateCallee = createAsyncThunk(
  'call/checkAndUpdateCallee',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isDeafToHearing = state.call.isDeafToHearing;
    const calleeConnectionString = state.call.calleeConnectionString;
    const callId = callIdSelector(state);

    // Case 1: Missing calleeConnectionString - needs update
    if (!calleeConnectionString) {
      if (isDeafToHearing) {
        await updateMissingDeafToHearingCallee(dispatch, state, callId);
      } else {
        await updateMissingHearingToDeafCallee(dispatch, state, callId);
      }
      return;
    }

    // Case 2: Check if the existing calleeConnectionString matches the expected value
    if (isDeafToHearing) {
      await updateDeafToHearingCallee(
        dispatch,
        state,
        callId,
        calleeConnectionString
      );
    } else {
      await updateHearingToDeafCallee(
        dispatch,
        state,
        callId,
        calleeConnectionString
      );
    }
  }
);

/**
 * Helper function for missing deaf-to-hearing callee
 */
export const updateMissingDeafToHearingCallee = async (
  dispatch: ThunkDispatch<any, unknown, Action>,
  state: RootState,
  callId: number
): Promise<void> => {
  const primaryHearingPhoneNumber = primaryHearingPhoneNumberSelector(state);
  if (!primaryHearingPhoneNumber) {
    return;
  }

  dispatch(
    sendAnalyticsInfo({
      Method: 'checkAndUpdateCallee',
      Message: `Missing calleeConnectionString, updating to ${primaryHearingPhoneNumber}`,
    })
  );
  await updateCalleePhoneNumberTo(dispatch, callId, primaryHearingPhoneNumber);
};

/**
 * Updates the callee phone number
 */
export const updateCalleePhoneNumberTo = async (
  dispatch: ThunkDispatch<any, unknown, Action>,
  callId: number,
  phoneNumber: string
): Promise<void> => {
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber);
    const phoneNumberWithPlus = addPlusToPhoneNumber(parsedNumber);

    const call = await CallDaoService.updateCalleePhoneNumber({
      callId,
      phoneNumber: phoneNumberWithPlus,
    });

    dispatch(updateCalleeConnectionString(call.CalleeConnectionString));
  } catch (error) {
    dispatch(
      handleError({
        error,
        methodName: 'checkAndUpdateCallee',
        message: 'Failed to update callee phone number',
      })
    );
  }
};

/**
 * Helper function for missing hearing-to-deaf callee
 */
export const updateMissingHearingToDeafCallee = async (
  dispatch: ThunkDispatch<any, unknown, Action>,
  state: RootState,
  callId: number
): Promise<void> => {
  const deafPhoneNumber = deafPhoneNumberSelector(state);
  if (!deafPhoneNumber) {
    return;
  }

  dispatch(
    sendAnalyticsInfo({
      Method: 'checkAndUpdateCallee',
      Message: `Missing calleeConnectionString, updating to ${deafPhoneNumber}`,
    })
  );
  await updateCalleePhoneNumberTo(dispatch, callId, deafPhoneNumber);
};

/**
 * Helper function to update deaf-to-hearing callee
 */
export const updateDeafToHearingCallee = async (
  dispatch: ThunkDispatch<any, unknown, Action>,
  state: RootState,
  callId: number,
  calleeConnectionString: string
): Promise<void> => {
  const primaryHearingPhoneNumber = primaryHearingPhoneNumberSelector(state);
  if (!primaryHearingPhoneNumber) {
    return;
  }

  if (!doPhoneNumbersMatch(primaryHearingPhoneNumber, calleeConnectionString)) {
    logPhoneNumberMismatch(
      dispatch,
      primaryHearingPhoneNumber,
      calleeConnectionString,
      'Primary hearing'
    );

    await updateCalleePhoneNumberTo(
      dispatch,
      callId,
      primaryHearingPhoneNumber
    );
  }
};

/**
 * Helper function to update hearing-to-deaf callee
 */
export const updateHearingToDeafCallee = async (
  dispatch: ThunkDispatch<any, unknown, Action>,
  state: RootState,
  callId: number,
  calleeConnectionString: string
): Promise<void> => {
  const deafPhoneNumber = deafPhoneNumberSelector(state);
  if (!deafPhoneNumber) {
    return;
  }

  if (!doPhoneNumbersMatch(deafPhoneNumber, calleeConnectionString)) {
    logPhoneNumberMismatch(
      dispatch,
      deafPhoneNumber,
      calleeConnectionString,
      'Deaf'
    );
    await updateCalleePhoneNumberTo(dispatch, callId, deafPhoneNumber);
  }
};

/**
 * Update callee phone number
 */
export const updateCalleePhoneNumber = createAsyncThunk(
  'call/updateCalleePhoneNumber',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const primaryHearingPhoneNumber = primaryHearingPhoneNumberSelector(state);

    dispatch(
      sendAnalyticsInfo({
        Method: 'updateCalleePhoneNumber',
        Message: `Updating callee phone number to ${primaryHearingPhoneNumber}`,
      })
    );

    try {
      const call = await CallDaoService.updateCalleePhoneNumber({
        callId,
        phoneNumber: addPlusToPhoneNumber(
          parsePhoneNumber(primaryHearingPhoneNumber)
        ),
      });

      dispatch(updateCalleeConnectionString(call.CalleeConnectionString));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'updateCalleePhoneNumber',
        })
      );
    }
  }
);

/**
 * Checks if two phone numbers match after normalization
 */
export const doPhoneNumbersMatch = (
  phoneNumber1: string,
  phoneNumber2: string
): boolean => {
  const normalized1 = normalizePhoneNumber(phoneNumber1);
  const normalized2 = normalizePhoneNumber(phoneNumber2);
  return normalized1 === normalized2;
};

/**
 * Logs analytics for phone number mismatch
 */
export const logPhoneNumberMismatch = (
  dispatch: ThunkDispatch<any, unknown, Action>,
  phoneNumber: string,
  calleeConnectionString: string,
  callDirection: string
): void => {
  dispatch(
    sendAnalyticsInfo({
      Method: 'checkAndUpdateCallee',
      Message: `${callDirection} phone number ${phoneNumber} doesn't match callee connection string ${calleeConnectionString}, updating`,
    })
  );
};
