import { createSelector } from '@reduxjs/toolkit';

import { isConnectionStateHistoryInProgressSelector } from 'features/call/call-base/store/selectors';
import { isConferenceEmptySelector } from 'features/call/call-conference/store';
import { isDeafDisconnectedSelector } from 'features/call/call-deaf/store';
import { isAllHearingsDisconnectedSelector } from 'features/call/call-hearing/store';
import { selectIsFullyDisconnected } from 'features/caller-session/store';
import {
  selectIsEmergencyDeafReconnectionNeeded,
  selectIsSpawningEmergencyCall,
} from 'features/emergency/store';
import {
  isRemoteTeamingSelector,
  isTeamingStartedSelector,
} from 'features/teaming/teaming-base/store';

export const selectIsCallCanBeClosed = createSelector(
  isConferenceEmptySelector,
  isDeafDisconnectedSelector,
  isAllHearingsDisconnectedSelector,
  isConnectionStateHistoryInProgressSelector,
  isRemoteTeamingSelector,
  isTeamingStartedSelector,
  selectIsEmergencyDeafReconnectionNeeded,
  selectIsSpawningEmergencyCall,
  selectIsFullyDisconnected,
  (
    isConferenceEmpty,
    isDeafDisconnected,
    isAllHearingsDisconnected,
    isConnectionStateHistoryInProgress,
    isRemoteTeaming,
    isTeamingStarted,
    isEmergencyDeafReconnectionNeeded,
    isSpawningEmergencyCall,
    isCallerSessionCanEnd
  ) => {
    if (!isCallerSessionCanEnd) {
      return false;
    }
    if (!isConferenceEmpty) {
      return false;
    }

    if (isConnectionStateHistoryInProgress) {
      return false;
    }

    // Skip in-center teaming types since the team members are physically together,
    // and teaming can be ended only manually (no TeamingEnd RNS event)
    if (isRemoteTeaming && isTeamingStarted) {
      return false;
    }

    if (isEmergencyDeafReconnectionNeeded) {
      return false;
    }

    if (isSpawningEmergencyCall) {
      return false;
    }

    return isDeafDisconnected && isAllHearingsDisconnected;
  }
);
