import { alpha } from '@mui/material';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import styled, { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';

interface MainTemplateProps {
  header: ReactNode;
  isHideBackground?: boolean;
}

export const MainTemplate: FC<PropsWithChildren<MainTemplateProps>> = ({
  children,
  header,
  isHideBackground,
}) => {
  return (
    <Container>
      {header}
      <Main>
        <ChildContainer $isHideBackground={isHideBackground}>
          {children}
        </ChildContainer>
      </Main>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.palette.grey[700]};
`;

const Main = styled('div')`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ChildContainer = styledProps('div')<{ $isHideBackground: boolean }>`
  ${({ $isHideBackground }) =>
    !$isHideBackground &&
    `
  background-color: ${alpha(theme.palette.grey[900], 0.5)};
  `}
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
`;
