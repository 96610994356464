import { useEffect, useRef } from 'react';

export const useReceivedEventTimeout = () => {
  const timeoutId = useRef<number | null>(null);

  const setTimeout = (handler: () => void, duration: number) => {
    timeoutId.current = window.setTimeout(handler, duration);
  };

  const removeTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  };

  useEffect(() => {
    return () => {
      removeTimeout();
    };
  }, []);

  return { removeTimeout, setTimeout };
};
