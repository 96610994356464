import { useState, useEffect } from 'react';

import {
  BlueParagraph,
  CallerStatusContainer,
  CallLogSection,
  GreenParagraph,
  LargeColumn,
  RedSpan,
  SmallColumn,
} from './styles';
import { RecentCallsTracker } from './RecentCallsTracker';

import { useAppSelector } from 'features/app/hooks';
import {
  selectCallerSessionId,
  selectCallerSessionStatus,
  selectSwitchboardConnections,
  selectWebRtcRemoteIdentities,
} from 'features/caller-session/store';
import { isDevOrQaEnvironment } from 'common/utils';
import { sipCallIdSelector } from 'features/call/call-base/store/selectors';
import { TerminationType } from 'features/call/call-base/enums';
import { selectCallPageStatus } from 'features/call/call-ui-state/store';

const MENU_KEY = '*614';
const debugMenuVisible = Boolean(import.meta.env.VITE_APP_DEBUG_MENU_VISIBLE);

export const HiddenDebugMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [typedKeys, setTypedKeys] = useState('');
  const callerSessionStatus = useAppSelector(selectCallerSessionStatus);
  const callerSessionId = useAppSelector(selectCallerSessionId);
  const sipCallId = useAppSelector(sipCallIdSelector);
  const isDevOrQa = isDevOrQaEnvironment;
  const webRtcConnections = useAppSelector(selectWebRtcRemoteIdentities);
  const switchboardConnections = useAppSelector(selectSwitchboardConnections);
  const callLog = RecentCallsTracker.getInstance().getCallLog();
  const callPageStatus = useAppSelector(selectCallPageStatus);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setTypedKeys((prev) => (prev + event.key).slice(-MENU_KEY.length));
    };
    if (debugMenuVisible) {
      setIsVisible(true);
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (typedKeys === MENU_KEY) {
      setIsVisible((prev) => !prev);
      setTypedKeys('');
    }
  }, [typedKeys]);

  if (!isDevOrQa || !isVisible) {
    return null;
  }

  return (
    <CallerStatusContainer>
      <LargeColumn>
        <RedSpan>Session Status: {callerSessionStatus}</RedSpan>
        <RedSpan>Call Page Status: {callPageStatus}</RedSpan>
        <BlueParagraph>Session ID: {callerSessionId}</BlueParagraph>
        <GreenParagraph>SIP Call ID: {sipCallId}</GreenParagraph>
      </LargeColumn>
      <SmallColumn>
        <RedSpan>WebRTC Connections: {webRtcConnections?.length}</RedSpan>
        <BlueParagraph>
          Switchboard Connections: {switchboardConnections?.length}
        </BlueParagraph>
      </SmallColumn>
      <LargeColumn>
        <CallLogSection>
          <RedSpan style={{ marginRight: '8px' }}>Last 5 Calls:</RedSpan>
          <div>
            {callLog
              ?.slice()
              .reverse()
              .map((call, idx: number) => {
                return (
                  <BlueParagraph key={call.callId}>
                    #{idx + 1}: {call.callId} -{' '}
                    <RedSpan>{TerminationType[call.terminationCode]}</RedSpan>
                  </BlueParagraph>
                );
              })}
          </div>
        </CallLogSection>
      </LargeColumn>
    </CallerStatusContainer>
  );
};
