import { useState } from 'react';
import type { MouseEvent, ReactNode } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  styled,
  alpha,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

import {
  CallDetailsAccordianLanguageMenu,
  CallDetailsAccordianHeaderMenu,
} from 'features/call/call-details/components';
import { EndSessionButton } from 'features/call/call-finish/components';
import { Separator } from 'features/call/call-details/styles';
import theme from 'features/theme/theme';
import type { Call } from 'features/call/call-base/interfaces';
import { updateCallRecord } from 'features/call/call-base/store/callSlice';
import { toggleAutoComplete } from 'features/call/call-base/store';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  callSelector,
  selectIsAutoCompleteEnabled,
} from 'features/call/call-base/store/selectors';
import { selectIsFullyDisconnected } from 'features/caller-session/store';
import { isAcceptingUserSelector } from 'features/teaming/teaming-base/store';

interface CallDetailsAccordianMenuProps {
  children: ReactNode;
}

export const CallDetailsAccordianMenu = ({
  children,
}: CallDetailsAccordianMenuProps) => {
  const dispatch = useAppDispatch();
  const call = useAppSelector(callSelector);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation('translation');

  const isFullyDisconnected = useAppSelector(selectIsFullyDisconnected);
  const autoCompleteEnabled = useAppSelector(selectIsAutoCompleteEnabled);
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setLanguageMenuAnchorEl(null);
  };

  const handleLanguageMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };

  const handleAccordionToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const updateLanguage = (lang: string) => {
    const partialCall: Partial<Call> = {
      LanguageCode: lang,
    };
    dispatch(updateCallRecord(partialCall));
    handleMenuClose();
  };

  const handleAutoCompleteToggle = () => {
    dispatch(toggleAutoComplete());
  };

  return (
    <RootContainer>
      <ContainerInnerWrapper>
        <Container>
          <Title>{t('callDetails.title')}</Title>
          <ButtonContainer>
            {!isAcceptingUser && (
              <StyledIconButton
                onClick={handleMenuOpen}
                data-testid='call-details-menu-bar-button'
              >
                <MoreHorizIcon />
              </StyledIconButton>
            )}
            <StyledIconButton
              onClick={handleAccordionToggle}
              data-testid='accordion-toggle-button'
            >
              <StyledExpandIcon isExpanded={isExpanded} />
            </StyledIconButton>
          </ButtonContainer>
        </Container>

        <Collapse
          in={isExpanded}
          data-expanded={isExpanded}
          data-testid='call-details-collapse'
        >
          <Separator />
          {children}
        </Collapse>
      </ContainerInnerWrapper>
      {isFullyDisconnected && !autoCompleteEnabled && <EndSessionButton />}

      <CallDetailsAccordianHeaderMenu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onLanguageMenuOpen={handleLanguageMenuOpen}
        onAutoCompleteToggle={handleAutoCompleteToggle}
      />

      <CallDetailsAccordianLanguageMenu
        anchorEl={languageMenuAnchorEl}
        open={Boolean(languageMenuAnchorEl)}
        onClose={handleMenuClose}
        selectedLanguage={call.languageCode}
        onLanguageSelect={updateLanguage}
      />
    </RootContainer>
  );
};

const RootContainer = styled('div')`
  max-width: 340px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${alpha(theme.palette.grey[900], 1)};
`;

const ContainerInnerWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(7, 9, 9)};
`;

const StyledIconButton = styled(IconButton)`
  color: ${theme.palette.common.white};
`;

const ButtonContainer = styled(Box)`
  display: flex;
  gap: ${theme.spacing(4)};
  align-items: center;
`;

const StyledExpandIcon = styled(ExpandMoreIcon)<{ isExpanded: boolean }>`
  transform: ${(props) =>
    props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease-in-out;
`;

const Title = styled(Typography)`
  color: ${theme.palette.common.white};
  font-weight: 600;
`;

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
