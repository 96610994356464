import { styled, Typography } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Logo } from 'assets/svg';
import theme from 'features/theme/theme';

export const AppLogoBase: FC<PropsWithChildren> = ({ children }) => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <Logo />
      <Typography
        color={theme.palette.common.white}
        variant={i18n?.language === 'ja' ? 'h2' : 'h1'}
        component='h1'
        m={theme.spacing(0, 4)}
      >
        {t('appName', { defaultValue: 'Mercury' })}
      </Typography>
      {children}
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  margin-right: ${theme.spacing(12)};
`;
