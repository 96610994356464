import { SIP_HEADER_METADATA_KEY } from 'features/sip/constants';
import { useSipHeaderMetadata } from 'features/sip/hooks';

export const useMetadataHeader = () => {
  const { sipHeaderMetadata } = useSipHeaderMetadata();

  const metadataHeader = `${SIP_HEADER_METADATA_KEY}: ${sipHeaderMetadata}`;

  return { metadataHeader };
};
