import { Typography } from '@mui/material';
import type { FC } from 'react';

import { SpeakerIcon } from 'assets/svg';
import { useAppSelector } from 'features/app/hooks';
import { twoLineVcoPhoneNumberSelector } from 'features/call/call-hearing/store';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const CallDetailsParticipantMetadataVco2Line: FC = () => {
  const vco2LinePhoneNumber = useAppSelector(twoLineVcoPhoneNumberSelector);

  return (
    <ParticipantWrapper data-testid='call-details-participant-metadata-vco-2-line'>
      <SpeakerIcon data-testid='speaker' />
      <Container>
        <Typography variant='body1' color={theme.palette.grey[50]}>
          {maskPhoneNumber(vco2LinePhoneNumber)}
        </Typography>
      </Container>
    </ParticipantWrapper>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ParticipantWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(8)};
  margin-bottom: ${theme.spacing(8)};
`;
