import { N11DaoService } from 'features/n11/services';

export const isN11Number = (phoneNumber: string): boolean => {
  const regex = /^(211|311|511|711|811)$/;
  return regex.test(phoneNumber);
};

export const adaptN11ToNumber = async (phoneNumber: string, callId: number) => {
  const { PhoneNumber, N11Status } = await N11DaoService.getN11Number({
    phoneNumber,
    callId,
  });

  return { phoneNumber: PhoneNumber, n11Status: N11Status };
};
