export class ConferenceWindowService {
  static conferenceWindow: Window | null = null;
  static readonly windowFeatures = `status=0,menubar=0,width=${window.screen.availWidth},height=${window.screen.availHeight}`;

  static open(url: string, name: string) {
    ConferenceWindowService.conferenceWindow = window.open(
      url,
      name,
      ConferenceWindowService.windowFeatures
    );
  }

  static close() {
    if (!ConferenceWindowService.conferenceWindow) {
      console.warn('DEBUG: Conference window has already closed');
      return;
    }

    ConferenceWindowService.conferenceWindow.close();
  }

  static get isClosed() {
    return (
      !ConferenceWindowService.conferenceWindow ||
      ConferenceWindowService.conferenceWindow.closed
    );
  }

  static closeWithStateCheck = () => {
    if (!ConferenceWindowService.isClosed) {
      ConferenceWindowService.close();
    }
  };
}
