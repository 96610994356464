import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  setJoinedToZoom,
  conferenceConnectionsReported,
} from './conferenceSlice';

import { ConferenceWindowService } from 'features/call/call-conference/services';
import type { RootState } from 'features/app/store/store';
import {
  ConnectionChangeType,
  InterpretingSessionType,
} from 'features/call/call-base/enums';
import { CallDaoService } from 'features/call/call-base/services';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { sessionIdSelector } from 'features/session/store';
import {
  leaveRemoteTeaming,
  isTeamingInProgressSelector,
  completeTeamingCall,
} from 'features/teaming/teaming-base/store';
import { userIdSelector } from 'features/user/store';
import { isPrimaryVcoSenderSelector } from 'features/vco/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';

export const leaveConferenceTeaming = createAsyncThunk(
  'conference-teaming/leave',
  async (payload, { dispatch }) => {
    await dispatch(leaveRemoteTeaming());
    ConferenceWindowService.close();
  }
);

export const handleZoomConnection = createAsyncThunk(
  'conference/handleZoomConnection',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const conferenceState = state.conference;

    // Update UI state
    dispatch(setJoinedToZoom());

    // Only send connection state if we haven't connected before
    if (!conferenceState.hasConnected) {
      await dispatch(sendConferenceConnectionState(true));
      sendAnalyticsInfo({
        event: 'ConferenceConnectionState',
        Method: 'sendConferenceConnectionState',
        Message:
          'Conference Connects recorded for hearing and deaf participants',
      });

      // Update connection tracking state
      dispatch(conferenceConnectionsReported());
    }
  }
);

export const handleConferenceDisconnect = createAsyncThunk(
  'conference/handleDisconnect',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isTeamingInProgress = isTeamingInProgressSelector(state);
    const userId = userIdSelector(state);
    const conferenceState = state.conference;

    // Guard against multiple disconnects
    if (!conferenceState.hasConnected || !conferenceState.isJoinedToZoom) {
      console.debug('Ignoring disconnect - already disconnected');
      return;
    }

    // Handle teaming state if needed
    if (isTeamingInProgress) {
      await dispatch(completeTeamingCall({ userId }));
    }

    // Send disconnect state
    await dispatch(sendConferenceConnectionState(false));
    sendAnalyticsInfo({
      event: 'ConferenceConnectionState',
      Method: 'sendConferenceConnectionState',
      Message:
        'Conference Disconnects recorded for hearing and deaf participants',
    });

    // Close the conference window
    ConferenceWindowService.closeWithStateCheck();
  }
);

export const handleConferenceConnect = createAsyncThunk(
  'conference/handleConnect',
  async (_, { dispatch }) => {
    dispatch(setJoinedToZoom());
    await dispatch(sendConferenceConnectionState(true));
    sendAnalyticsInfo({
      event: 'ConferenceConnectionState',
      Method: 'sendConferenceConnectionState',
      Message: 'Conference Connects recorded for hearing and deaf participants',
    });
    dispatch(conferenceConnectionsReported());
  }
);

export const handleConferenceStateDisconnect = createAsyncThunk(
  'conference/handleStateDisconnect',
  async (_, { dispatch }) => {
    await dispatch(sendConferenceConnectionState(false));
    sendAnalyticsInfo({
      event: 'ConferenceConnectionState',
      Method: 'sendConferenceConnectionState',
      Message:
        'Conference Disconnects recorded for hearing and deaf participants',
    });
  }
);

const sendConferenceConnectionState = createAsyncThunk(
  'conference/sendConnectionState',
  async (isConnected: boolean, { getState }) => {
    const state = getState() as RootState;
    const conferenceState = state.conference;
    const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);
    const callId = callIdSelector(state);
    const sessionLoginHistoryId = sessionIdSelector(state);
    const didAgentLeaveConference = conferenceState.didAgentLeaveConference;

    if (!isPrimaryVcoSender || !callId) {
      return;
    }

    const deafConnectionChangeType = determineDeafConnectionChangeType(
      isConnected,
      didAgentLeaveConference
    );

    const connectionStateHistoryParams = {
      interpretingSessionType: InterpretingSessionType.NORMAL,
      isDeafConnected: isConnected,
      isHearingConnected: isConnected,
      callId,
      sessionLoginHistoryId,
    };

    // send the deaf connection with isHearingConnected set to false so it doesn't create two interpreting sessions.
    await CallDaoService.connectionStateHistory({
      ...connectionStateHistoryParams,
      connectionChangeType: deafConnectionChangeType,
      isHearingConnected: false,
    });
    await CallDaoService.connectionStateHistory({
      ...connectionStateHistoryParams,
      connectionChangeType: getHearingConnectionChangeType(isConnected),
    });
  }
);

const determineDeafConnectionChangeType = (
  isConnected: boolean,
  didAgentLeave: boolean
) => {
  if (isConnected) {
    return ConnectionChangeType.DEAF_CONNECT;
  }
  return didAgentLeave
    ? ConnectionChangeType.AGENT_DISCONNECT_DEAF
    : ConnectionChangeType.DEAF_DISCONNECT;
};

const getHearingConnectionChangeType = (isConnected: boolean) =>
  isConnected
    ? ConnectionChangeType.HEARING_CONNECT
    : ConnectionChangeType.HEARING_DISCONNECT;
