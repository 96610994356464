import { IconButton, Typography } from '@mui/material';
import type { FC, MouseEvent, ReactNode } from 'react';

import { MarkIcon } from 'assets/svg';
import styled, { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';

export interface MenuIconButtonHorizontalProps {
  title: string;
  icon?: ReactNode;
  inactiveIcon?: ReactNode;
  isDisabled: boolean;
  isCheckIconVisible: boolean;
  isChecked: boolean;
  textColor?: string;
  testId?: string;
  onClickHandler?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const Container = styled('div')`
  display: inline-block;
  text-align: center;
`;

const StyledIcons = styled('div')`
  display: inline;
`;

const StyledCheckIcon = styledProps(MarkIcon)<{ $isVisible: boolean }>`
  ${({ $isVisible }) =>
    $isVisible &&
    `
  margin-left: ${theme.spacing(3)};
  margin-right: ${theme.spacing(4)};
`}
  width: ${({ $isVisible }) => ($isVisible ? '12px' : '0px')};
  vertical-align: top;
  margin-top: ${theme.spacing(3)};
  transition: width 0.2s ease-in-out;
`;

const StyledIconButton = styledProps(IconButton)<{ $isVisible: boolean }>`
  width: 100%;
  justify-content: left;
  padding: ${theme.spacing(4)};
  border-radius: ${theme.spacing(2)};
  background-color: ${({ $isVisible }) =>
    $isVisible ? theme.palette.grey[900] : ''};
  :not(:first-of-type) {
    margin-top: ${theme.spacing(2)};
  }
`;

const StyledText = styled(Typography)`
  display: inline-block;
  vertical-align: bottom;
  margin-left: ${theme.spacing(4)};
`;

export const MenuIconButtonHorizontal: FC<MenuIconButtonHorizontalProps> = ({
  title,
  icon,
  inactiveIcon,
  isDisabled,
  isCheckIconVisible,
  isChecked,
  testId,
  textColor = theme.palette.common.white,
  onClickHandler,
}) => {
  const isShowCheckIcon = isCheckIconVisible && isChecked;
  const isShowInactiveIcon = inactiveIcon && !isChecked;

  return (
    <StyledIconButton
      disableRipple
      size='small'
      disabled={isDisabled}
      data-testid={testId}
      $isVisible={isShowCheckIcon}
      onClick={onClickHandler}
    >
      <Container>
        <StyledIcons>
          <StyledCheckIcon $isVisible={isShowCheckIcon} />
          {isShowInactiveIcon ? inactiveIcon : icon}
        </StyledIcons>
        <StyledText color={textColor} variant='textMediumRegular'>
          {title}
        </StyledText>
      </Container>
    </StyledIconButton>
  );
};
