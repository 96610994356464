import type { FC } from 'react';
import { useState } from 'react';

import { Hotkey } from 'config/enums';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';
import { CallDetailsButtonConnect } from 'features/call/call-details/components';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { deafIsLoadingSelector } from 'features/call/call-deaf/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';

interface CallDetailsButtonDeafConnectProps {
  phoneNumber: string;
}

export const CallDetailsButtonDeafConnect: FC<
  CallDetailsButtonDeafConnectProps
> = ({ phoneNumber }) => {
  const { handleDeafConnect } = useCallDeafParticipantHandlers();
  const [isLoading, setIsLoading] = useState(false);
  const isDeafLoading = useAppSelector(deafIsLoadingSelector);
  const dispatch = useAppDispatch();

  const handleDeafConnectClick = async () => {
    setIsLoading(true);
    await handleDeafConnect(phoneNumber);
    setIsLoading(false);
    dispatch(
      sendAnalyticsInfo({
        Method: 'handleDeafConnectClick',
        message: `Deaf connect button clicked with phone number ${phoneNumber}`,
      })
    );
  };

  return (
    <CallDetailsButtonConnect
      loading={isLoading || isDeafLoading}
      onClick={handleDeafConnectClick}
      hotkey={Hotkey.CallerConnectionStateChange}
    />
  );
};
