import { useCallback } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { CallSipHeaderTag } from 'features/call/call-base/enums';
import { callIdSelector } from 'features/call/call-base/store/selectors';

export const useCallSipHeader = () => {
  const callId = useAppSelector(callIdSelector);

  const getCallSipHeader = useCallback((): Record<CallSipHeaderTag, string> => {
    return {
      [CallSipHeaderTag.ID]: callId.toString(),
    };
  }, [callId]);

  return {
    getCallSipHeader,
  };
};
