import type { MouseEvent } from 'react';
import { Typography, Menu, MenuItem, styled } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { CallDetailsAccordianAutoCompleteMenu } from 'features/call/call-details/components';
import theme from 'features/theme/theme';

interface CallDetailsAccordianHeaderMenuProps {
  anchorEl?: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  onLanguageMenuOpen?: (event: MouseEvent<HTMLElement>) => void;
  onAutoCompleteToggle?: (event: MouseEvent<HTMLElement>) => void;
}

export const CallDetailsAccordianHeaderMenu = ({
  anchorEl,
  open,
  onClose,
  onLanguageMenuOpen,
  onAutoCompleteToggle,
}: CallDetailsAccordianHeaderMenuProps) => {
  const { t } = useTranslation('translation');
  const isAutoCompleteTogglePossible: boolean = useFlags().autoCompleteToggle;

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <StyledMenuItemWithSpaceBetween onClick={onLanguageMenuOpen}>
        <Text>{t('callDetails.callLanguage')}</Text>
        <KeyboardArrowRightIcon sx={{ ml: 2 }} />
      </StyledMenuItemWithSpaceBetween>
      {isAutoCompleteTogglePossible && (
        <CallDetailsAccordianAutoCompleteMenu onClick={onAutoCompleteToggle}>
          <Text>{t('callDetails.disableAutoComplete')}</Text>
        </CallDetailsAccordianAutoCompleteMenu>
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    color: theme.palette.common.white,
    minWidth: '200px',
    boxShadow: `0px 5px 15px ${theme.palette.grey[900]}`,
    borderRadius: theme.spacing(4),
  },
  '& .MuiMenu-list': {
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.grey[800]}`,
    borderRadius: theme.spacing(4),
  },
  '& .MuiMenuItem-root': {
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      borderRadius: theme.spacing(4),
    },
  },
}));

const StyledMenuItemWithSpaceBetween = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  px: ${theme.spacing(8)};
  width: 230px;
`;

const Text = styled(Typography)<{ selected?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) =>
    props.selected ? theme.palette.primary[500] : theme.palette.common.white};
`;
