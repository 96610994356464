import flow from 'lodash.flow';

import { FeatureFlagService } from 'features/feature-toggle/services';

const featureFlags = FeatureFlagService.getInstance();

const isStartsWithPlus = (phoneNumber: string) => phoneNumber.startsWith('+');
const isEmergencyNumber = (phoneNumber: string) => phoneNumber.length === 3;

export const trimToTenDigits = (phoneNumber: string) => {
  let number = phoneNumber;
  let prefix = '';

  // Retain the '+' sign if it exists
  if (phoneNumber.startsWith('+')) {
    prefix = '+';
    number = phoneNumber.slice(1);
  }

  // Check if the phone number starts with the USA country code
  if (number.startsWith('1')) {
    const localNumber = number.slice(1);

    // Ensure the local number is exactly 10 digits
    if (localNumber.length > 10) {
      return prefix + number.slice(0, 11); // Include country code and 10 digits of the number
    }
    return prefix + number; // If already 10 digits or less, include the country code
  }

  // If it's not starting with '1', it's not a North American number, return unchanged
  return phoneNumber;
};

export function normalizePhoneNumber(phoneNumber: string) {
  // Remove non-digit characters
  return phoneNumber.replace(/\D/g, '');
}

export const removePlusFromPhoneNumber = (phoneNumber: string) =>
  phoneNumber.startsWith('+') ? phoneNumber.substring(1) : phoneNumber;

const replaceLettersInPhoneNumber = (phoneNumber: string) =>
  phoneNumber
    .replace(/[A-Ca-c]/g, '2')
    .replace(/[D-Fd-f]/g, '3')
    .replace(/[G-Ig-i]/g, '4')
    .replace(/[J-Lj-l]/g, '5')
    .replace(/[M-Om-o]/g, '6')
    .replace(/[P-Sp-s]/g, '7')
    .replace(/[T-Vt-v]/g, '8')
    .replace(/[W-Zw-z]/g, '9');

const addUsaCodeIfNeeded = (phoneNumber: string) => {
  const num = phoneNumber.trim();
  const isUSEnvironment = featureFlags.flags?.require011DialInternational;
  if (
    num.startsWith('+') ||
    num.length !== 10 ||
    !isUSEnvironment ||
    num.startsWith('011')
  ) {
    return num;
  }
  return `1${num}`;
};

const remove011Prefix = (phoneNumber: string): string => {
  if (phoneNumber.startsWith('011')) {
    return phoneNumber.substring(3);
  }
  if (phoneNumber.startsWith('+011')) {
    return `+${phoneNumber.substring(4)}`;
  }
  return phoneNumber;
};

export const removeExtNumbers = (phoneNumber: string): string => {
  const [phoneNumberWithoutExt] = phoneNumber.split(/(\s?x\s?\d+)|ext\./gi);
  return phoneNumberWithoutExt.trim();
};

export const addPlusToPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    throw Error('No phone number given');
  }

  return isStartsWithPlus(phoneNumber) || isEmergencyNumber(phoneNumber)
    ? phoneNumber
    : `+${phoneNumber}`;
};

export const parsePhoneNumber = flow([
  removeExtNumbers,
  replaceLettersInPhoneNumber,
  addUsaCodeIfNeeded,
  remove011Prefix,
  trimToTenDigits,
]);

export const parsePhoneNumberWithRemovePlus = flow([
  parsePhoneNumber,
  removePlusFromPhoneNumber,
]);

export const removeAnyLeadingNonNumbers = (s: string) => {
  return s.replace(/^\D+/, '');
};

export function validateNanpPhoneNumber(phoneNumber: string): string {
  // Regular expression to check if the phone number contains only digits, hyphens, periods, or spaces
  const numericOnlyRegex = /^[+0-9-\s.]+$/;

  // If the number contains only numeric characters (and valid separators), return it as-is
  if (numericOnlyRegex.test(phoneNumber)) {
    return phoneNumber;
  }

  // Regular expression to match only the first 3 digits (valid toll-free and premium prefixes)
  const areaCodeRegex = /^1?[-.\s]?(800|833|844|855|866|877|888|900)/;

  // Extract the first 3 digits (area code)
  const areaCodeMatch = phoneNumber.match(areaCodeRegex);
  if (!areaCodeMatch) {
    return '';
  }

  // Get the part of the phone number after the area code
  const restOfNumber = phoneNumber.slice(areaCodeMatch[0].length);

  // Remove any spaces, periods, or hyphens for consistency
  const cleanedNumber = restOfNumber.replace(/[-.\s]/g, '');

  // Check if the remaining part has any invalid characters (anything other than letters, digits, or '+')
  if (/[^a-zA-Z0-9+]/.test(cleanedNumber)) {
    return ''; // Invalid characters, return empty string
  }
  // Validate that the cleaned number has exactly 7 characters (central office + subscriber)
  const nanpRemainingRegex = /^[a-zA-Z0-9]{7}$/; // Matches any 7 valid alphanumeric characters

  // If the rest of the number is valid, return the original phone number
  return nanpRemainingRegex.test(cleanedNumber) ? phoneNumber : '';
}

export const parsePhoneNumberIncomingCall = flow([
  validateNanpPhoneNumber,
  parsePhoneNumber,
]);
