import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  isRequestingUserSelector,
  isSendTeamingUserNotificationSelector,
  teamingAnotherUserSessionIdSelector,
} from 'features/teaming/teaming-base/store/';
import type { RootState } from 'features/app/store/store';
import { CallNotificationType } from 'features/call/call-events/enums';
import type { ClientNotificationEvent } from 'features/rns/interfaces';
import { RnsConnectionService } from 'features/rns/services';

export const sendRnsNotification = createAsyncThunk(
  'rns/sendRnsNotification',
  async (
    { eventName, data }: ClientNotificationEvent,
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const isSendTeamingUserNotification =
      isSendTeamingUserNotificationSelector(state);
    const teamingAnotherUserSessionId =
      teamingAnotherUserSessionIdSelector(state);
    const isRequestingUser = isRequestingUserSelector(state);

    // TODO: Condition should be moved to the parent component (it is an abstract thunk that shouldn't handle specific cases)
    if (
      eventName === CallNotificationType.HEARING_DECLINED_CALL &&
      !isRequestingUser
    ) {
      return;
    }

    if (!isSendTeamingUserNotification || !teamingAnotherUserSessionId) {
      return;
    }

    try {
      const rnsInstance = RnsConnectionService.getInstance();
      if (!rnsInstance) {
        return;
      }

      await rnsInstance.sendClientNotification({
        receiverId: teamingAnotherUserSessionId,
        eventName,
        data,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
