import { SignMailStatus } from 'features/signmail/enums';
import { exhaustiveCheck } from 'common/helpers/exhaustiveCheck';

export function canTransitionToSignMailStatus(
  currentStatus: SignMailStatus,
  nextStatus: SignMailStatus
): boolean {
  switch (currentStatus) {
    case SignMailStatus.INACTIVE:
      return (
        nextStatus === SignMailStatus.STARTED ||
        nextStatus === SignMailStatus.COUNTDOWN
      );
    case SignMailStatus.STARTED:
      return (
        nextStatus === SignMailStatus.GREETING ||
        nextStatus === SignMailStatus.COUNTDOWN
      );
    case SignMailStatus.GREETING:
      return nextStatus === SignMailStatus.COUNTDOWN;
    case SignMailStatus.COUNTDOWN:
      return nextStatus === SignMailStatus.RECORDING;
    case SignMailStatus.RECORDING:
      return nextStatus === SignMailStatus.INACTIVE;
    default:
      exhaustiveCheck(currentStatus);
      return false;
  }
}
