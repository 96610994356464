import { useCallback } from 'react';

import {
  selectIsSpawningEmergencyCall,
  spawnEmergencyCall,
  turnOffSpawningEmergencyCall,
  turnOnSpawningEmergencyCall,
} from 'features/emergency/store';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { terminationTypeSelector } from 'features/call/call-base/store/selectors';
import { CallNotificationType } from 'features/call/call-events/enums';
import { selectPrimaryAndSecondaryConnectedHearings } from 'features/call/call-hearing/store';
import { sendRnsNotification } from 'features/rns/store';
import { useSpawnNewCall } from 'features/spawn-call/hooks';
import {
  isTeamingStartedSelector,
  teamingAcceptingUserIdSelector,
  removeTeam,
  forceAcceptTeaming,
} from 'features/teaming/teaming-base/store';
import { useHearingDisconnect } from 'features/voice-meeting/hooks';

export const useEmergencySpawnNewCall = () => {
  const dispatch = useAppDispatch();
  const { disconnectMultipleHearings } = useHearingDisconnect();
  const { spawnCallTerminationType: handleTermSpawnCall } = useSpawnNewCall();
  const primaryAndSecondaryConnectedHearings = useAppSelector(
    selectPrimaryAndSecondaryConnectedHearings
  );
  const terminationType = useAppSelector(terminationTypeSelector);
  const isTeamingStarted = useAppSelector(isTeamingStartedSelector);
  const acceptingUserId = useAppSelector(teamingAcceptingUserIdSelector);
  const isSpawningEmergencyCall = useAppSelector(selectIsSpawningEmergencyCall);

  const spawnNewCallEmergency = useCallback(async () => {
    if (isSpawningEmergencyCall) {
      return;
    }

    try {
      dispatch(turnOnSpawningEmergencyCall());

      if (isTeamingStarted) {
        await dispatch(
          removeTeam({
            suppressTeamEndNotify: true,
            userId: acceptingUserId,
          })
        ).unwrap();
      }
      const terminationReason =
        (await handleTermSpawnCall()) ?? terminationType;

      if (primaryAndSecondaryConnectedHearings) {
        await disconnectMultipleHearings(
          primaryAndSecondaryConnectedHearings.map((hearing) => hearing.id)
        );
      }

      const newCallId = await dispatch(
        spawnEmergencyCall(terminationReason)
      ).unwrap();

      if (isTeamingStarted) {
        await dispatch(forceAcceptTeaming()).unwrap();
      }

      await dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.SPAWN_CALL,
          data: {
            callId: newCallId,
          },
        })
      ).unwrap();
    } catch (error) {
      dispatch(turnOffSpawningEmergencyCall());
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSpawningEmergencyCall,
    acceptingUserId,
    dispatch,
    handleTermSpawnCall,
    primaryAndSecondaryConnectedHearings,
    isTeamingStarted,
    terminationType,
  ]);

  return {
    spawnNewCallEmergency,
  };
};
