import { useState } from 'react';
import type { MouseEvent, ReactNode } from 'react';
import { Box, Typography, IconButton, Collapse, styled } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

import {
  CallDetailsAccordianLanguageMenu,
  CallDetailsAccordianHeaderMenu,
} from 'features/call/call-details/components';
import { Separator } from 'features/call/call-details/styles';
import theme from 'features/theme/theme';
import type { Call } from 'features/call/call-base/interfaces';
import { updateCallRecord } from 'features/call/call-base/store/callSlice';
import { useAppDispatch } from 'features/app/hooks';

interface CallDetailsAccordianMenuProps {
  children: ReactNode;
}

export const CallDetailsAccordianMenu = ({
  children,
}: CallDetailsAccordianMenuProps) => {
  const dispatch = useAppDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState('English');
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation('translation');

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setLanguageMenuAnchorEl(null);
  };

  const handleLanguageMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };

  const handleAccordionToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const updateLanguage = (lang: string) => {
    setLanguage(lang);
    const partialCall: Partial<Call> = {
      LanguageCode: lang,
    };
    dispatch(updateCallRecord(partialCall));
    handleMenuClose();
  };

  return (
    <RootContainer>
      <Container>
        <Title>{t('callDetails.title')}</Title>
        <ButtonContainer>
          <StyledIconButton
            onClick={handleMenuOpen}
            data-testid='call-details-menu-bar-button'
          >
            <MoreHorizIcon />
          </StyledIconButton>
          <StyledIconButton
            onClick={handleAccordionToggle}
            data-testid='accordion-toggle-button'
          >
            <StyledExpandIcon isExpanded={isExpanded} />
          </StyledIconButton>
        </ButtonContainer>
      </Container>

      <Collapse
        in={isExpanded}
        data-expanded={isExpanded}
        data-testid='call-details-collapse'
      >
        <Separator />
        {children}
      </Collapse>

      <CallDetailsAccordianHeaderMenu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onLanguageMenuOpen={handleLanguageMenuOpen}
      />

      <CallDetailsAccordianLanguageMenu
        anchorEl={languageMenuAnchorEl}
        open={Boolean(languageMenuAnchorEl)}
        onClose={handleMenuClose}
        selectedLanguage={language}
        onLanguageSelect={updateLanguage}
      />
    </RootContainer>
  );
};

const RootContainer = styled(Box)`
  background-color: ${theme.palette.common.black};
  padding: ${theme.spacing(8)};
  border-radius: ${theme.spacing(8)};
  max-width: 340px;
`;

const StyledIconButton = styled(IconButton)`
  color: ${theme.palette.common.white};
`;

const ButtonContainer = styled(Box)`
  display: flex;
  gap: ${theme.spacing(4)};
  align-items: center;
`;

const StyledExpandIcon = styled(ExpandMoreIcon)<{ isExpanded: boolean }>`
  transform: ${(props) =>
    props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease-in-out;
`;

const Title = styled(Typography)`
  color: ${theme.palette.common.white};
  font-weight: 600;
`;

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
