import packageJson from '../../../../package.json';

import { endpointName } from 'features/sip/constants';

export class ProductService {
  static readonly buildNumber = process.env.VITE_BUILD_NUMBER ?? '';
  static get productName() {
    return endpointName;
  }

  static get version() {
    const buildNumber = ProductService.buildNumber;
    if (
      buildNumber &&
      buildNumber !== 'undefined' &&
      buildNumber.trim() !== ''
    ) {
      return `${packageJson.version}.${buildNumber}`;
    } else {
      return packageJson.version;
    }
  }
}
