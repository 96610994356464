import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'features/app/hooks';
import { CallDetailsParticipant } from 'features/call/call-details/components';
import { useCallDetailsCaller } from 'features/call/call-details/hooks';
import { Separator } from 'features/call/call-details/styles';
import { useEmergencyCallDetailsCallee } from 'features/emergency/hooks';
import { isAddressVerificationNeededSelector } from 'features/emergency/store';
import { CallDetailsParticipantChangePhoneNumberVco2Line } from 'features/vco-2-line/components';

export const EmergencyCallDetailsChangeNumberVco2Line: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'callDetails' });
  const { caller } = useCallDetailsCaller();
  const { callee } = useEmergencyCallDetailsCallee();
  const isAddressVerificationNeeded = useAppSelector(
    isAddressVerificationNeededSelector
  );

  const showCallDetailsParticipant = callee && !isAddressVerificationNeeded;

  return (
    <>
      <CallDetailsParticipant {...caller} title={t('callFrom')} />
      <CallDetailsParticipantChangePhoneNumberVco2Line />
      {showCallDetailsParticipant && (
        <>
          <Separator />
          <CallDetailsParticipant {...callee} title={t('callTo')} />
        </>
      )}
    </>
  );
};
