/**
 * Extracts the base SIP URI from a string
 */
export const extractSipUri = (str?: string | null): string | null => {
  if (!str) {
    return str === '' ? '' : null;
  }

  const match = str.match(/(?:<)?(sip:[^>;\s]+)(?:>)?/i);
  return match ? match[1].toLowerCase() : str;
};

/**
 * Normalizes a SIP URI for comparison
 */
export const normalizeSipUri = (uri?: string | null): string | null => {
  if (!uri) {
    return null;
  }

  // Extract the base URI
  const baseUri = extractSipUri(uri);
  if (baseUri === null) {
    return null;
  }

  // Extract user part and domain from SIP URI
  const uriMatch = baseUri.match(/^sip:([^@]+)@(.+)$/i);
  if (!uriMatch) {
    return baseUri;
  }

  const userPart = uriMatch[1];
  const domain = uriMatch[2];

  // Strip any + prefix for normalization
  const rawNumber = userPart.replace(/^\+/, '');

  // For US/Canada numbers: if it starts with 1 and has 11 digits, remove the leading 1
  // This standardizes +12025551234 and +2025551234 to the same value
  if (rawNumber.startsWith('1') && rawNumber.length === 11) {
    return `sip:${rawNumber.substring(1)}@${domain}`;
  }
  return `sip:${rawNumber}@${domain}`;
};

/**
 * Checks if two SIP URIs refer to the same endpoint
 */
export const areSameEndpoint = (
  uri1?: string | null,
  uri2?: string | null
): boolean => {
  // If they are the same, return true early
  if (uri1 === uri2) {
    return true;
  }

  // If either is null/undefined, return false
  if (!uri1 || !uri2) {
    return false;
  }

  // Normalize both URIs for comparison
  const normalizedUri1 = normalizeSipUri(uri1);
  const normalizedUri2 = normalizeSipUri(uri2);

  // Both URIs must be valid after normalization
  if (normalizedUri1 === null || normalizedUri2 === null) {
    return false;
  }

  // Parse the normalized URIs
  const parsedUri1 = parseNormalizedSipUri(normalizedUri1);
  const parsedUri2 = parseNormalizedSipUri(normalizedUri2);

  // Both URIs must be parsable into userPart and domain
  if (parsedUri1 === null || parsedUri2 === null) {
    return false;
  }

  // Compare user parts
  if (parsedUri1.userPart !== parsedUri2.userPart) {
    return false;
  }

  // If user parts match and domains are identical, they are the same
  if (parsedUri1.domain === parsedUri2.domain) {
    return true;
  }

  // If user parts match and both domains are internal, consider them the same
  if (
    isInternalDomain(parsedUri1.domain) &&
    isInternalDomain(parsedUri2.domain)
  ) {
    return true;
  }

  // Otherwise, they are different endpoints
  return false;
};

const INTERNAL_DOMAINS_SUFFIXES = ['.sorenson.com', '.svrs.net'];

/**
 * Checks if a domain is considered internal based on its suffix.
 */
export const isInternalDomain = (domain?: string | null): boolean => {
  if (!domain) {
    return false;
  }
  return INTERNAL_DOMAINS_SUFFIXES.some((suffix) => domain.endsWith(suffix));
};

/**
 * Extracts the user part and domain from a normalized SIP URI.
 * Returns null if the URI format is invalid.
 */
export const parseNormalizedSipUri = (
  normalizedUri: string
): { userPart: string; domain: string } | null => {
  const sipUriRegex = /^sip:([^@]+)@(.+)$/i;
  const matchResult = sipUriRegex.exec(normalizedUri);

  if (!matchResult) {
    return null;
  }
  // matchResult[0] is the full match, [1] is the first capture group (userPart), [2] is the second (domain)
  return { userPart: matchResult[1], domain: matchResult[2] };
};
