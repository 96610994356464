import { env } from 'features/environment/constants';

export function consoleLogDev(message: string, ...args: any[]) {
  const { apiUrl, isTestMode } = env;
  if (!apiUrl) {
    return;
  }

  const dev = apiUrl.includes('dev');
  const qa = apiUrl.includes('qa');
  const local = apiUrl.includes('localhost');
  if (isTestMode || dev || qa || local) {
    console.log(message, ...args);
  }
}
