import type { PeerConnectionEvent } from 'jssip/lib/RTCSession';
import { useCallback } from 'react';

import type { DeafPartyResolutionResult } from 'features/call/call-accepted/interfaces';
import { isStringAllLettersOrEmpty } from 'common/helpers/';
import { LogLevel } from 'features/analytics/enums';
import {
  formatTraceStack,
  sendAnalyticsError,
  sendAnalyticsInfo,
} from 'features/analytics/helpers';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { getCorrectUriFromList } from 'features/call/call-accepted/helpers';
import { ConnectionChangeType } from 'features/call/call-base/enums';
import { removePlusFromPhoneNumber } from 'features/call/call-base/helpers';
import { CallDaoService } from 'features/call/call-base/services';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import {
  deafPartyResolved,
  setDeafIsLoading,
  setDeafLocation,
  setDeafPhoneNumber,
} from 'features/call/call-deaf/store';
import { primaryHearingPhoneNumberSelector } from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import {
  setCallWasPlaced,
  setShouldCreateDataChannel,
} from 'features/call/vrs-call/store/';
import {
  dialedNumbersListSelector,
  setDialedNumberHistory,
} from 'features/dialed-numbers-history/store';
import { envConfig } from 'features/environment/services';
import { useMonitorCustomHeader } from 'features/monitoring/hooks/useMonitorCustomHeader';
import { isMonitorHostIpExistsSelector } from 'features/monitoring/store/selector';
import { isVrsModeSelector } from 'features/multi-mode/store';
import { useConnectionRefs } from 'features/router/hooks';
import { sipSettings } from 'features/sip/config';
import { userAgentHeader } from 'features/sip/constants';
import { useMetadataHeader } from 'features/sip/hooks';
import { SipSessionEventBus } from 'features/sip/services';
import { resetTeaming } from 'features/teaming/teaming-base/store';
import { isConvo, isSorenson } from 'features/vrs-third-parties/helpers';
import { ANONYMOUS } from 'features/voice-meeting/constants';

export const useCallDeafParticipantHandlers = () => {
  const dispatch = useAppDispatch();
  const { uaRef } = useConnectionRefs();
  const { metadataHeader } = useMetadataHeader();
  const { monitorCustomHeader } = useMonitorCustomHeader();
  const dialedNumbersList = useAppSelector(dialedNumbersListSelector);
  const callId = useAppSelector(callIdSelector);
  const isCallMonitoring = useAppSelector(isMonitorHostIpExistsSelector);
  const isVrsMode = useAppSelector(isVrsModeSelector);
  const hearingPhoneNumber = useAppSelector(primaryHearingPhoneNumberSelector);

  const resolvePhoneNumberWithCore = useCallback(
    async (
      callerNumber: string,
      calleeNumber: string
    ): Promise<DeafPartyResolutionResult> => {
      try {
        const res = await CallDaoService.resolvePhoneNumberWithCore(
          callerNumber,
          calleeNumber
        );
        const uri = getCorrectUriFromList(res?.URIList);

        return {
          uri,
          isConvo: isConvo(res?.URIList),
          isSorenson: isSorenson(res?.URIList),
          sipRemoteDomain: uri.split('@')[1] ?? 'unresolved.sorenson.com',
          name: res?.Name ?? '',
        };
      } catch (e: any) {
        sendAnalyticsError({
          Method: 'resolvePhoneNumberWithCore',
          Level: LogLevel.ERROR,
          Message: e?.message || 'error resolving phone number with core',
        });
        return {
          uri: '',
          isConvo: false,
          isSorenson: false,
          sipRemoteDomain: 'unresolved.sorenson.com',
          name: '',
        };
      }
    },
    []
  );

  const handleDeafCancel = useCallback(() => {
    if (!uaRef?.current) {
      console.warn('DEBUG: jssip.UA not found');
      return;
    }

    dispatch(setDeafIsLoading(true));

    uaRef.current.terminateSessions({
      status_code: 200,
    });
    ParticipantsStatusEventBus.deaf.$disconnected.next({
      connectionChangeType: ConnectionChangeType.AGENT_DISCONNECT_DEAF,
    });
  }, [uaRef, dispatch]);

  const handleDeafConnect = useCallback(
    async (phoneNumber: string, calleeConnectionString?: string) => {
      let sipRemoteDomain: string;
      const phoneNumberWithoutPlus = removePlusFromPhoneNumber(phoneNumber);
      if (dialedNumbersList.includes(phoneNumberWithoutPlus)) {
        dispatch(
          sendAnalyticsInfo({
            Method: 'multipleDeafConnect',
            Message:
              'Attempted to dial the same deaf participant number multiple times',
            PhoneNumber: phoneNumber,
          })
        );
      }
      dispatch(
        sendAnalyticsInfo({
          Method: 'handleDeafConnect',
          Message: 'Initiating connection to deaf participant',
          PhoneNumber: phoneNumber,
          Trace: formatTraceStack(new Error().stack ?? 'no stack trace'),
        })
      );
      if (!uaRef?.current) {
        console.warn('DEBUG: jssip.UA not found');
        return;
      }

      dispatch(
        setDialedNumberHistory([phoneNumberWithoutPlus, ...dialedNumbersList])
      );
      dispatch(setCallWasPlaced());
      dispatch(setDeafIsLoading(true));
      dispatch(resetTeaming());

      const settings = sipSettings.get();

      const fromUserName = hearingPhoneNumber || calleeConnectionString;

      let hearingNumberToResolveWithCore = hearingPhoneNumber;
      const isStringAllLettersResult =
        isStringAllLettersOrEmpty(hearingPhoneNumber);
      if (isStringAllLettersResult) {
        hearingNumberToResolveWithCore = ANONYMOUS;
      }

      const options: any = {
        extraHeaders: [userAgentHeader, metadataHeader],
        pcConfig: settings.pcConfig,
        mediaConstraints: { audio: true, video: true },
        eventHandlers: {
          peerconnection: (event: PeerConnectionEvent) => {
            SipSessionEventBus.peerConnection$.next(event);
          },
        },
        ...(fromUserName ? { fromUserName } : {}),
      };
      if (isVrsMode) {
        const resolveInfo = await resolvePhoneNumberWithCore(
          removePlusFromPhoneNumber(hearingNumberToResolveWithCore),
          removePlusFromPhoneNumber(phoneNumber)
        );
        if (resolveInfo) {
          dispatch(
            deafPartyResolved({
              name: resolveInfo.name,
              isSorenson: resolveInfo.isSorenson,
            })
          );
        }
        sipRemoteDomain = resolveInfo.sipRemoteDomain;
        if (resolveInfo.isConvo) {
          console.log('DEBUG: Changing behavior for Convo. No datachannel.', {
            resolveInfo,
          });
          delete options.eventHandlers;
          dispatch(setShouldCreateDataChannel(false));
        } else {
          dispatch(setShouldCreateDataChannel(true));
        }
      } else {
        sipRemoteDomain = envConfig.vri.sipCustomerEndpoint;
      }

      // Adding monitoring host IP address for the monitored agent
      if (isCallMonitoring) {
        options.extraHeaders.push(monitorCustomHeader);
      }

      const callee = `sip:+${phoneNumber}@${sipRemoteDomain}`.trim();

      try {
        uaRef.current.call(callee, options);
      } catch (e: any) {
        sendAnalyticsError({
          Method: 'handleDeafConnect',
          Level: LogLevel.ERROR,
          Message: e?.message || 'error connecting to deaf party',
        });
        handleDeafCancel();
      }
    },

    [
      dialedNumbersList,
      uaRef,
      dispatch,
      metadataHeader,
      hearingPhoneNumber,
      isVrsMode,
      isCallMonitoring,
      resolvePhoneNumberWithCore,
      monitorCustomHeader,
      handleDeafCancel,
    ]
  );

  const handleDeafDisconnect = () => {
    dispatch(setDeafIsLoading(true));
    dispatch(
      sendAnalyticsInfo({
        Method: 'handleDeafDisconnectByButton',
        Message: 'deaf leg disconnected by disconnect button click',
      })
    );

    SipSessionEventBus.terminate$.next();
  };

  const handleDeafConnectNewCall = useCallback(
    async (phoneNumber: string) => {
      dispatch(setDeafPhoneNumber(phoneNumber));

      const { CalleeConnectionLocation } =
        await CallDaoService.updateCalleePhoneNumber({
          callId,
          phoneNumber,
        });
      dispatch(setDeafLocation(CalleeConnectionLocation));
      await handleDeafConnect(phoneNumber);
    },
    [handleDeafConnect, callId, dispatch]
  );

  return {
    handleDeafConnect,
    handleDeafCancel,
    handleDeafDisconnect,
    handleDeafConnectNewCall,
  };
};
