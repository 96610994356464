import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStopwatch } from 'react-timer-hook';

import { CheckIcon } from 'assets/svg';
import { useAppSelector } from 'features/app/hooks';
import { SignMailRecordingStatus } from 'features/signmail/enums';
import { selectSignMailRecordingStatus } from 'features/signmail/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const SignMailRecordingStatusWidget = () => {
  const state = useAppSelector(selectSignMailRecordingStatus);
  const { start, reset, minutes, seconds } = useStopwatch();
  const { t } = useTranslation('translation', { keyPrefix: 'signMail' });

  const stateToMessage = useMemo(
    () => ({
      [SignMailRecordingStatus.UPLOAD_COMPLETE]: t('successfullySent'),
      [SignMailRecordingStatus.NOT_STARTED]: t('preparingRecording'),
      [SignMailRecordingStatus.UPLOADING]: t('uploading'),
      [SignMailRecordingStatus.RECORDING]: t('recording'),
      [SignMailRecordingStatus.MAIL_BOX_FULL]: t('mailBoxFull'),
      [SignMailRecordingStatus.ERROR_OCCURRED]: t('errorOccurred'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (state === SignMailRecordingStatus.RECORDING) {
      start();
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <PositionContainer>
      <Container>
        {state === SignMailRecordingStatus.RECORDING && (
          <IconContainer>
            <RecordingCircle />
          </IconContainer>
        )}
        {state === SignMailRecordingStatus.UPLOAD_COMPLETE && (
          <IconContainer>
            <CheckIcon />
          </IconContainer>
        )}
        {stateToMessage[state]}
        {state === SignMailRecordingStatus.RECORDING && (
          <Timer>
            {minutes}:{seconds > 9 ? seconds : `0${seconds}`}/2:00
          </Timer>
        )}
      </Container>
    </PositionContainer>
  );
};

const PositionContainer = styled('div')`
  position: absolute;
  top: ${theme.spacing(6)};
  bottom: 0;
  margin: auto 0;
  z-index: 9999999;
`;

const Container = styled('div')`
  background-color: rgba(255, 255, 255, 0.4);
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  border-radius: ${theme.spacing(3)};
  display: flex;
  align-items: center;
  font-size: 16px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
`;

const IconContainer = styled('div')`
  margin-right: ${theme.spacing(2)};
`;

const RecordingCircle = styled('div')`
  background-color: red;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: ease pulse 2s infinite;

  @keyframes pulse {
    0% {
      background-color: red;
    }
    50% {
      background-color: #f06c6c;
    }
    100% {
      background-color: red;
    }
  }
`;

const Timer = styled('div')`
  margin-left: ${theme.spacing(2)};
`;
