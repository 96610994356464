import { useEffect } from 'react';

import { Hotkey } from 'config/enums';

export const useKeyBoardEventToConnectCall = (
  eventListenerFunction:
    | (() => void)
    | undefined
    | ((...args: string[]) => void),
  args: string[] = [],
  isDisabled = false
) => {
  useEffect(() => {
    const handleKeyPress = (event: { key: string }) => {
      if (event.key.toLowerCase() === Hotkey.AddCallee && !isDisabled) {
        if (eventListenerFunction) {
          if (args?.length) {
            eventListenerFunction(...args);
          } else {
            eventListenerFunction();
          }
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [args, eventListenerFunction, isDisabled]);
};
