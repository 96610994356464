import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';
import { useKeyBoardEventToConnectCall } from 'features/call/call-details/hooks';
import { useAppSelector } from 'features/app/hooks';
import { selectIsCallCanBeClosed } from 'features/call/call-finish/store';

export const CallDetailsButtonReconnect: FC<
  Pick<ButtonProps, 'onClick' | 'loading' | 'hotkey' | 'disabled'>
> = ({ loading, onClick, hotkey, disabled }) => {
  const { t } = useTranslation();

  const isCallCanBeClosed = useAppSelector(selectIsCallCanBeClosed);

  useKeyBoardEventToConnectCall(onClick, [], isCallCanBeClosed);

  return (
    <Button
      data-testid='call-details-reconnect-button'
      fullWidth
      size='small'
      color='primary'
      loading={loading}
      onClick={onClick}
      hotkey={hotkey}
      disabled={isCallCanBeClosed || disabled}
    >
      {t('reconnect')}
    </Button>
  );
};
