import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { EmergencyLogMessages } from 'features/emergency/enums';
import type { EmergencyAnalytics } from 'features/emergency/interfaces';

export const regularEmergencyCallAnalytics = ({
  methodName,
  isProvisioned,
  isGeoCoordinateAvailable,
  verify911Address,
  isEmergencyDialback,
}: EmergencyAnalytics) => {
  return sendAnalyticsInfo({
    Method: methodName,
    IsProvisioned: isProvisioned,
    IsGeoCoordinateAvailable: isGeoCoordinateAvailable,
    Verify911Address: verify911Address,
    Message: isEmergencyDialback
      ? EmergencyLogMessages.DIALBACK_EMERGENCY_CALL
      : EmergencyLogMessages.REGULAR_EMERGENCY_CALL,
  });
};

export const spawnEmergencyCallAnalytics = ({
  methodName,
  isProvisioned,
  isGeoCoordinateAvailable,
  verify911Address,
  newCallId,
}: EmergencyAnalytics) => {
  return sendAnalyticsInfo({
    Method: methodName,
    IsProvisioned: isProvisioned,
    IsGeoCoordinateAvailable: isGeoCoordinateAvailable,
    Verify911Address: verify911Address,
    Message: EmergencyLogMessages.SPAWN_EMERGENCY_CALL,
    newCallId: newCallId,
  });
};

export const dialbackEmergencyCallAnalytics = ({
  methodName,
  isProvisioned,
  isGeoCoordinateAvailable,
  verify911Address,
}: EmergencyAnalytics) => {
  return sendAnalyticsInfo({
    Method: methodName,
    IsProvisioned: isProvisioned,
    IsGeoCoordinateAvailable: isGeoCoordinateAvailable,
    Verify911Address: verify911Address,
    Message: EmergencyLogMessages.DIALBACK_EMERGENCY_CALL,
  });
};

export const emergencyMenuButtonClickAnalytics = (methodName: string) => {
  return sendAnalyticsInfo({
    Method: methodName,
    Message: 'Emergency Menu Button Clicked',
  });
};

export const psapFallbackOnDeafDisconnectAnalytics = ({
  isLoggedOutEmergencyCall,
  isAddressVerificationNeeded,
  isAutoConnectAttempted,
}: {
  isLoggedOutEmergencyCall: boolean;
  isAddressVerificationNeeded: boolean;
  isAutoConnectAttempted: boolean;
}) => {
  return sendAnalyticsInfo({
    event: 'emergency_auto_connect_psap_fallback',
    Method: 'psapFallbackOnDeafDisconnectAnalytics',
    Message:
      'PSAP fallback triggered on deaf disconnect on logged out call with address verification needed will attempt to connect to PSAP',
    data: {
      isLoggedOutEmergencyCall,
      isAddressVerificationNeeded,
      isAutoConnectAttempted,
    },
  });
};
