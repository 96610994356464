import { ProductSipHeaderTag } from 'features/product/enums';
import { ProductService } from 'features/product/services';
import { sorensonSipVersion } from 'features/sip/constants';

export class ProductSipHeader {
  static get sipHeader(): Record<ProductSipHeaderTag, string> {
    return {
      [ProductSipHeaderTag.NAME]: ProductService.productName,
      [ProductSipHeaderTag.VERSION]: ProductService.version,
      [ProductSipHeaderTag.SORENSON_SIP_VERSION]: sorensonSipVersion,
    };
  }
}
