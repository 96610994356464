import type { RootState } from 'features/app/store/store';
import {
  CallStatus,
  CallType,
  RelayCallState,
} from 'features/call/call-base/enums';

export const callIdSelector = ({ call }: RootState) => call.id;

export const callSelector = ({ call }: RootState) => call;

export const callLanguageCodeSelector = ({ call }: RootState) =>
  call.languageCode;

export const callTakenTimeSelector = ({ call }: RootState) =>
  call.callTakenTime;

export const callStatusSelector = ({ call }: RootState) => call.status;

export const callTypeSelector = ({ call }: RootState) => call.callType;

export const isDeafToHearingSelector = ({ call }: RootState) =>
  call.isDeafToHearing;

export const isHearingToDeafSelector = ({ call }: RootState) =>
  !call.isDeafToHearing;

export const isRelayCallStateInProgressSelector = ({ call }: RootState) =>
  call.relayCallState === RelayCallState.InProg;

export const isRelayCallStateInvalidSelector = ({ call }: RootState) =>
  call.relayCallState === RelayCallState.Invalid;

export const isCallFinishedSelector = ({ call }: RootState) =>
  [CallStatus.WAITING_CALL, CallStatus.POST_CALL_SURVEY].includes(call.status);

export const isTeamingCanceledCallStatusSelector = ({ call }: RootState) =>
  call.status === CallStatus.TEAMING_CANCELED;

export const isCallInProgressStatusSelector = ({ call }: RootState) =>
  call.status === CallStatus.CALL_IN_PROGRESS;

export const isCallTakenTimeExistSelector = ({ call }: RootState) =>
  Boolean(call.callTakenTime);

export const selectCanRelayTransferCallToMercury = ({ call }: RootState) =>
  call.isCanBeTransferred;

export const isHearingCallerIdBlockedSelector = ({ call }: RootState) =>
  call.isHearingCallerIdBlocked;

export const isConferenceCall = ({ call }: RootState) =>
  call.callType === CallType.CONFERENCE_CALL;

export const callerConnectionTypeSelector = ({ call }: RootState) =>
  call.callerConnectionType;

export const terminationTypeSelector = ({ call }: RootState) =>
  call.terminationType;

export const sipCallIdSelector = ({ call }: RootState) => call.sipCallId;

export const sipHeaderFromAddressSelector = ({ call }: RootState) =>
  call.sipHeaderFrom;

export const lastCallIdSelector = ({ call }: RootState) => call.lastCallId;

export const endpointMacAddressSelector = ({ call }: RootState) =>
  call.endpointMacAddress;

export const peerUserAgentSelector = ({ call }: RootState) =>
  call.peerUserAgent;

export const isConnectionStateHistoryInProgressSelector = ({
  call,
}: RootState) => call.connectionStateHistoryCount > 0;

export const isColdHandoffCallSelector = ({ call }: RootState) =>
  call.callType === CallType.COLD_HANDOFF_CALL;
