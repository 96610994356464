import type { URI } from 'features/call/call-base/interfaces';

const convoDomainSubstrings = [
  '@convo-example.example.com',
  '@sip.convorelay.net',
];

/**
 * Returns true if the given connection information is for
 * a device on the third-party Convo VRS system.
 */
export function isConvo(list: URI[]): boolean {
  if (!list || list.length < 1) {
    return false;
  }

  for (const convoDomain of convoDomainSubstrings) {
    for (const item of list) {
      if (item.Value.includes(convoDomain)) {
        return true;
      }
    }
  }

  // No Convo domains found
  return false;
}
