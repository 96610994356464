import { Typography } from '@mui/material';
import { typography } from '@mui/system';
import type { FC, ReactNode } from 'react';

import { useNormalizeCallLocationName } from 'features/call/call-details/hooks';
import { ExtensionNumber } from 'features/call/call-details/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import { ANONYMOUS } from 'features/voice-meeting/constants';

export interface VrsCallReceivedParticipantProps {
  title: string;
  name: string;
  phoneNumber?: string;
  phoneExtension?: string;
  location?: string;
  icon: ReactNode;
  languageLabel?: string;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(4)};
`;

const ParticipantWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(8)};
  position: relative;
`;

const UserName = styled(Typography)`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 192px;
  white-space: nowrap;
  color: ${theme.palette.common.white};
`;

const ParticipantMetadata = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LanguageLabel = styled('div')`
  ${typography(theme.typography.label)};
  background: ${theme.palette.deepChampagne[500]};
  padding: ${theme.spacing(2, 6)};
  border-radius: 100px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const VrsCallReceivedParticipant: FC<
  VrsCallReceivedParticipantProps
> = ({
  name,
  phoneNumber,
  phoneExtension,
  location,
  title,
  icon,
  languageLabel,
}) => {
  const { normalizeLocationName } = useNormalizeCallLocationName();

  return (
    <Container>
      <Typography variant='label' color={theme.palette.grey[300]} mb={4}>
        {title}
      </Typography>
      <ParticipantWrapper>
        {icon}
        <ParticipantMetadata>
          <UserName variant='textLargeMedium'>{name}</UserName>
          {phoneNumber && phoneNumber !== ANONYMOUS && (
            <Typography variant='body1' color={theme.palette.grey[50]}>
              {maskPhoneNumber(phoneNumber)}
            </Typography>
          )}
          {phoneExtension && (
            <ExtensionNumber phoneExtension={phoneExtension} />
          )}
          {location && (
            <Typography variant='label' color={theme.palette.grey[100]}>
              {normalizeLocationName(location)}
            </Typography>
          )}
        </ParticipantMetadata>
        {languageLabel && <LanguageLabel>{languageLabel}</LanguageLabel>}
      </ParticipantWrapper>
    </Container>
  );
};
