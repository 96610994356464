import type { URI } from 'features/call/call-base/interfaces';

export const getCorrectUriFromList = (uriList: URI[]) => {
  if (!uriList || uriList.length === 0) {
    return '';
  }
  // return by lowest priority and the Value doesn't start with h323 with the h being case insensitive
  const regex = new RegExp('^h323:', 'i');
  const uri = uriList
    .filter((x) => !regex.test(x.Value))
    .sort((a, b) => a.Priority - b.Priority)
    .shift();
  return uri ? uri.Value : '';
};
