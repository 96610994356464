import { alpha } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import type { Hotkey } from 'config/enums';
import styled, { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';

interface ButtonProps {
  localizationKey: string;
  onClick: () => Promise<void>;
  hotkey?: Hotkey;
}

interface CallReceivedBaseProps {
  primaryButton: ButtonProps;
  secondaryButton: ButtonProps;
  isLoading: boolean;
  isEmergency: boolean;
}

const Container = styledProps('div')<{ $isEmergency: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 432px;
  padding: ${theme.spacing(23)};
  border-radius: 8px;
  background-color: ${({ $isEmergency }) =>
    $isEmergency
      ? alpha(theme.palette.emergency.main, 0.85)
      : theme.palette.grey[900]}
`;

const PrimaryBtn = styled(Button)`
  margin-top: ${theme.spacing(16)};
`;

const SecondaryBtn = styled(Button)`
  margin-top: ${theme.spacing(8)};
`;

export const CallReceivedBase: FC<PropsWithChildren<CallReceivedBaseProps>> = ({
  children,
  primaryButton,
  secondaryButton,
  isLoading,
  isEmergency,
}) => {
  const { t } = useTranslation();

  return (
    <Container $isEmergency={isEmergency}>
      {children}
      <PrimaryBtn
        autoFocus
        fullWidth
        color='primary'
        loading={isLoading}
        disabled={isLoading}
        title={t(primaryButton.localizationKey)}
        onClick={() => primaryButton.onClick()}
        hotkey={primaryButton.hotkey}
      >
        {t(primaryButton.localizationKey)}
      </PrimaryBtn>
      <SecondaryBtn
        fullWidth
        size='small'
        color='secondary'
        disabled={isLoading}
        title={secondaryButton.localizationKey}
        onClick={() => secondaryButton.onClick()}
        hotkey={secondaryButton.hotkey}
      >
        {t(secondaryButton.localizationKey)}
      </SecondaryBtn>
    </Container>
  );
};
