import type { FC } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { ContainerWrapper } from 'features/call/call-details/styles';
import { vco2LineStatusSelector } from 'features/call/call-hearing/store';
import {
  CallDetailsParticipantConnectedVco2Line,
  CallDetailsParticipantConnectingVco2Line,
  CallDetailsParticipantDisconnectedVco2Line,
} from 'features/vco-2-line/components';

export const CallDetailsParticipantVco2Line: FC = () => {
  const vco2LineStatus = useAppSelector(vco2LineStatusSelector);

  return (
    <ContainerWrapper>
      {vco2LineStatus === 'disconnected' && (
        <CallDetailsParticipantDisconnectedVco2Line />
      )}
      {vco2LineStatus === 'connecting' && (
        <CallDetailsParticipantConnectingVco2Line />
      )}
      {vco2LineStatus === 'connected' && (
        <CallDetailsParticipantConnectedVco2Line />
      )}
    </ContainerWrapper>
  );
};
