import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { exhaustiveCheck } from 'common/helpers/exhaustiveCheck';
import {
  GreetingPreference,
  SignMailRecordingStatus,
  SignMailStatus,
} from 'features/signmail/enums';
import type { Greeting, SignMailState } from 'features/signmail/interfaces';

export const initialSignMailState: SignMailState = {
  currentStatus: SignMailStatus.NONE,
  greeting: {
    videoUrl: '',
    text: '',
    greetingPreference: GreetingPreference.SORENSON_GREETING,
  },
  recording: {
    status: SignMailRecordingStatus.NOT_STARTED,
    uploadUrl: '',
    textStartSeconds: 0,
  },
};

export const signMailSlice = createSlice({
  name: 'signMail',
  initialState: initialSignMailState,
  reducers: {
    transitionSignMailStatus: (
      state,
      action: PayloadAction<SignMailStatus>
    ) => {
      const nextState = action.payload;
      switch (state.currentStatus) {
        case SignMailStatus.NONE:
          if (
            nextState === SignMailStatus.STARTED ||
            nextState === SignMailStatus.COUNTDOWN
          ) {
            state.currentStatus = nextState;
          }
          break;
        case SignMailStatus.STARTED:
          if (
            nextState === SignMailStatus.GREETING ||
            nextState === SignMailStatus.COUNTDOWN
          ) {
            state.currentStatus = nextState;
          }
          break;
        case SignMailStatus.GREETING:
          if (nextState === SignMailStatus.COUNTDOWN) {
            state.currentStatus = nextState;
          }
          break;
        case SignMailStatus.COUNTDOWN:
          if (nextState === SignMailStatus.RECORDING) {
            state.currentStatus = nextState;
          }
          break;
        case SignMailStatus.RECORDING:
          if (nextState === SignMailStatus.NONE) {
            state.currentStatus = nextState;
          }
          break;
        default:
          exhaustiveCheck(state.currentStatus);
      }
    },
    setGreeting: (state, action: PayloadAction<Greeting>) => {
      state.greeting = action.payload;
    },
    setRecordingStatus: (
      state,
      action: PayloadAction<SignMailRecordingStatus>
    ) => {
      state.recording.status = action.payload;
    },
    setRecordingUploadUrl: (state, action: PayloadAction<string>) => {
      state.recording.uploadUrl = action.payload;
    },
    setTextStartSeconds: (state, action: PayloadAction<number>) => {
      state.recording.textStartSeconds = action.payload;
    },
    resetSignMail: () => initialSignMailState,
  },
});

export const {
  transitionSignMailStatus,
  setGreeting,
  resetSignMail,
  setRecordingStatus,
  setRecordingUploadUrl,
  setTextStartSeconds,
} = signMailSlice.actions;

export const signMailReducer = signMailSlice.reducer;
