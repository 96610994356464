import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import {
  signMailRecordingUploadUrlSelector,
  textStartSecondsSelector,
} from './signMailSelectors';
import {
  resetSignMail,
  setGreeting,
  setRecordingStatus,
  transitionSignMailStatus,
} from './signMailSlice';

import { sendAnalyticsInfo } from 'features/analytics/helpers';
import type { RootState } from 'features/app/store/store';
import {
  ConnectionChangeType,
  InterpretingSessionType,
  TerminationType,
} from 'features/call/call-base/enums';
import { removeAnyLeadingNonNumbers } from 'features/call/call-base/helpers';
import { CallDaoService } from 'features/call/call-base/services';
import { setTerminationType } from 'features/call/call-base/store';
import {
  callIdSelector,
  isHearingCallerIdBlockedSelector,
} from 'features/call/call-base/store/selectors';
import { deafPhoneNumberSelector } from 'features/call/call-deaf/store';
import {
  isPrimaryHearingConnectedSelector,
  primaryHearingPhoneNumberSelector,
} from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { hideLocalVideo, showLocalVideo } from 'features/local-video/store';
import { handleError, showNotification } from 'features/notification/store';
import { sessionIdSelector } from 'features/session/store';
import { videoRecordingBitrate } from 'features/signmail/constants';
import {
  SignMailRecordingStatus,
  SignMailStatus,
} from 'features/signmail/enums';
import { getGreetingVideoUrl } from 'features/signmail/helpers';
import {
  SignMailDaoService,
  SignMailRecorderService,
} from 'features/signmail/services';

export const sendSignMailConnectionCallState = createAsyncThunk(
  'call/sendSignMailConnectionCallState',
  async (
    connectionChangeType: ConnectionChangeType,
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const isHearingConnected = isPrimaryHearingConnectedSelector(state);
    const sessionLoginHistoryId = sessionIdSelector(state);

    try {
      await CallDaoService.connectionStateHistory({
        callId,
        connectionChangeType,
        isHearingConnected,
        sessionLoginHistoryId,
        isDeafConnected:
          connectionChangeType ===
          ConnectionChangeType.AGENT_CONNECT_HEARING_TO_DEAF_MESSAGE
            ? true
            : false,
        interpretingSessionType:
          InterpretingSessionType.HEARING_TO_DEAF_MESSAGE,
      });
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'sendSignMailConnectionCallState',
        })
      );
    }
  }
);

export const prepareCustomerGreeting = createAsyncThunk(
  'signMail/prepareGreeting',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const hearingPhoneNumber = removeAnyLeadingNonNumbers(
      primaryHearingPhoneNumberSelector(state)
    );
    const deafPhoneNumber = deafPhoneNumberSelector(state);
    const isHearingCallerIdBlocked = isHearingCallerIdBlockedSelector(state);
    dispatch(setTerminationType(TerminationType.SignMail));

    try {
      const { VideoMsgData } = await SignMailDaoService.getDeafUserInformation({
        calleeNumber: deafPhoneNumber,
        callerNumber: hearingPhoneNumber,
        blockCallerId: isHearingCallerIdBlocked,
      });

      const videoUrl = getGreetingVideoUrl(VideoMsgData);

      dispatch(
        setGreeting({
          videoUrl,
          text: VideoMsgData.GreetingText,
          greetingPreference: VideoMsgData.GreetingPreference,
        })
      );
      ParticipantsStatusEventBus.deaf.$unavailable.next();
      ParticipantsStatusEventBus.deaf.$connectSignMail.next();

      if (VideoMsgData.GreetingURL || VideoMsgData.GreetingText) {
        dispatch(changeSignMailStatus(SignMailStatus.GREETING));
      } else {
        dispatch(changeSignMailStatus(SignMailStatus.COUNTDOWN));
      }
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'prepareCustomerGreeting',
        })
      );
      ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
      dispatch(resetSignMailState());
    }
  }
);

export const finishRecording = createAsyncThunk(
  'signMail/finishRecording',
  async ({ text }: { text: string }, { dispatch, getState }) => {
    const rootState = getState() as RootState;
    const uploadUrl = signMailRecordingUploadUrlSelector(rootState);
    const callId = callIdSelector(rootState);
    const deafPhoneNumber = deafPhoneNumberSelector(rootState);
    const hearingPhoneNumber = primaryHearingPhoneNumberSelector(rootState);
    const textStartSeconds = textStartSecondsSelector(rootState);
    const service = SignMailRecorderService.getInstance();
    try {
      const { file, duration } = await service.stop(callId);
      dispatch(setRecordingStatus(SignMailRecordingStatus.UPLOADING));
      dispatch(sendAnalyticsInfo({ message: 'Starting upload of sign mail' }));
      const messageId = await SignMailDaoService.uploadFile(uploadUrl, file);
      const signMailRecordingSettings = {
        Bitrate: videoRecordingBitrate,
        Codec: 'H264',
        Level: '2.2',
        Profile: 'Baseline',
        TextStartSeconds: textStartSeconds.toString(),
        MessageSeconds: duration,
        Text: text
          ? `Phone: \r\n${hearingPhoneNumber}\r\nNote: \r\n${text}`
          : '',
        TextStopSeconds: duration.toString(),
        ToPhoneNumber: deafPhoneNumber,
        MessageId: messageId,
      };

      await SignMailDaoService.uploadUrlReady(
        callId,
        signMailRecordingSettings
      );

      sendAnalyticsInfo({
        message: 'SignMail recording uploaded successfully',
        Method: 'finishRecording',
      });

      dispatch(setRecordingStatus(SignMailRecordingStatus.DONE));
      dispatch(
        showNotification({
          title: t('signMail.successfullySent'),
          severity: 'success',
        })
      );
      dispatch(resetSignMailState());
      ParticipantsStatusEventBus.deaf.$disconnected.next({
        connectionChangeType: ConnectionChangeType.AGENT_DISCONNECT_DEAF,
      });
      dispatch(
        sendAnalyticsInfo({
          message: 'SignMail message sent successfully',
          Method: 'finishRecording',
        })
      );
    } catch (error) {
      dispatch(setRecordingStatus(SignMailRecordingStatus.ERROR_OCCURRED));
      dispatch(
        handleError({
          error,
          methodName: 'finishRecording',
        })
      );
      dispatch(resetSignMailState());
      ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
    } finally {
      service.destroy();
    }
  }
);

export const changeSignMailStatus = createAsyncThunk(
  'signMail/changeSignMailStatus',
  async (status: SignMailStatus, { dispatch }) => {
    dispatch(hideLocalVideo());
    dispatch(transitionSignMailStatus(status));
  }
);

export const resetSignMailState = createAsyncThunk(
  'signMail/resetSignMailState',
  async (_, { dispatch }) => {
    dispatch(showLocalVideo());
    dispatch(resetSignMail());
  }
);
