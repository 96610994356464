import { Subject, takeUntil } from 'rxjs';
import { useEffect, useRef } from 'react';

/**
 * Returns an RxJS operator
 * that takes values until the component unmounts.
 *
 * Result is stable (created on initial render and not changed).
 */
export const useWhileComponentMounted = () => {
  // Notifier emits a single value when the hook is unmounted.
  const notifier = useRef(new Subject());
  useEffect(() => {
    const subject = notifier.current;
    return () => {
      subject.next('unmounted');
      subject.complete();
    };
  }, []);

  const operatorRef = useRef(<T>() => takeUntil<T>(notifier.current));

  return operatorRef.current;
};
