import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallDetailsStatus } from 'features/call/call-details/enums';
import { setCallDetailsStatus } from 'features/call/call-details/store';
import { ContainerWrapper } from 'features/call/call-details/styles';
import {
  selectIsAnyHearingConnecting,
  selectIsLoadingVco2LineHearing,
} from 'features/call/call-hearing/store';
import styled from 'features/theme/styled';
import { Button } from 'features/ui/elements';
import { CallDetailsParticipantMetadataVco2Line } from 'features/vco-2-line/components';
import { useVoiceMeetingVco2LineHandlers } from 'features/vco-2-line/hooks';
import {
  isPrimaryVcoSenderSelector,
  isTwoLineVcoSelector,
} from 'features/vco/store';
import { selectIsCallCanBeClosed } from 'features/call/call-finish/store';
import { EmergencyCallDetailsStatus } from 'features/emergency/enums';
import { setEmergencyCallDetailsStatus } from 'features/emergency/store';

export const CallDetailsParticipantDisconnectedVco2Line: FC = () => {
  const dispatch = useAppDispatch();
  const isLoadingVco2LineHearing = useAppSelector(
    selectIsLoadingVco2LineHearing
  );
  const { t } = useTranslation('translation', { keyPrefix: 'vco2Line' });

  const { handleVco2LineCall } = useVoiceMeetingVco2LineHandlers();
  const isRequestingUser = useAppSelector(isPrimaryVcoSenderSelector);
  const isTwoLineVco = useAppSelector(isTwoLineVcoSelector);
  const isCallCanBeClosed = useAppSelector(selectIsCallCanBeClosed);
  const isAnyHearingConnecting = useAppSelector(selectIsAnyHearingConnecting);

  const isConnectLoading = isLoadingVco2LineHearing || isAnyHearingConnecting;

  const handleChangeNumber = () => {
    dispatch(setCallDetailsStatus(CallDetailsStatus.CHANGE_NUMBER_VCO_2_LINE));
    dispatch(
      setEmergencyCallDetailsStatus(
        EmergencyCallDetailsStatus.CHANGE_NUMBER_VCO_2_LINE
      )
    );
  };

  return (
    <Container>
      {isTwoLineVco && <CallDetailsParticipantMetadataVco2Line />}
      {isRequestingUser && isTwoLineVco && (
        <>
          <Button
            fullWidth
            size='small'
            color='secondary'
            data-testid='change-number-vco-2-line-button'
            onClick={handleChangeNumber}
          >
            {t('changeNumber')}
          </Button>
          <ContainerWrapper>
            <Button
              fullWidth
              size='small'
              color='primary'
              data-testid='connect-vco-2-line-button'
              loading={isConnectLoading}
              disabled={isCallCanBeClosed}
              onClick={handleVco2LineCall}
            >
              {t('connect')}
            </Button>
          </ContainerWrapper>
        </>
      )}
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
