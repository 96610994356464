import { useAppDispatch } from 'features/app/hooks';
import {
  pendingDisconnectById,
  setHearingAsDisconnected,
  setHearingLoadingStateById,
} from 'features/call/call-hearing/store';
import { handleError } from 'features/notification/store';
import { useVoiceMeetingSession } from 'features/voice-meeting/hooks';
import { setVoiceSessionLoading } from 'features/voice-session/store';

export const useHearingDisconnect = () => {
  const dispatch = useAppDispatch();
  const { disconnectParticipants } = useVoiceMeetingSession();

  const disconnectMultipleHearings = async (participantIds: string[]) => {
    try {
      dispatch(setVoiceSessionLoading(true));

      participantIds.forEach((participantId) => {
        dispatch(pendingDisconnectById({ id: participantId }));
        dispatch(
          setHearingLoadingStateById({ isLoading: true, participantId })
        );
      });

      await disconnectParticipants(participantIds);
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'disconnectMultipleHearings',
        })
      );
    } finally {
      dispatch(setVoiceSessionLoading(false));

      participantIds.forEach((participantId) => {
        dispatch(setHearingAsDisconnected(participantId));
        dispatch(
          setHearingLoadingStateById({ isLoading: false, participantId })
        );
      });
    }
  };

  const disconnectHearing = (participantId: string) => {
    return disconnectMultipleHearings(Array(participantId));
  };

  return { disconnectMultipleHearings, disconnectHearing };
};
