import { useEffect, useRef } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { useSignMailHandlers } from 'features/signmail/hooks';
import {
  signMailGreetingTextSelector,
  signMailVideoUrlSelector,
} from 'features/signmail/store';
import styled, { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';
import { useWhileComponentMounted } from 'features/utils/hook';

export const SignMailGreetingWithVideo = () => {
  const videoUrl = useAppSelector(signMailVideoUrlSelector);
  const text = useAppSelector(signMailGreetingTextSelector);
  const { handleGreetingEnded } = useSignMailHandlers();
  const videoRef = useRef<HTMLVideoElement>(null);
  const whileComponentMounted = useWhileComponentMounted();
  const stopAndResetVideo = () => {
    console.log('DEBUG: stopping and resetting video greeting');
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    return () => {
      stopAndResetVideo();
    };
  }, []);

  useEffect(() => {
    const subscription = ParticipantsStatusEventBus.deaf.$disconnectSignMail
      .pipe(whileComponentMounted())
      .subscribe(stopAndResetVideo);

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container data-testid='signmail-greeting-mixed'>
      <Video
        aria-label='SignMail Greeting Video'
        ref={videoRef}
        autoPlay
        onEnded={handleGreetingEnded}
        onError={handleGreetingEnded}
        data-testid='signmail-video'
        crossOrigin='anonymous'
      >
        <source src={videoUrl} type='video/mp4' />
      </Video>
      {text && (
        <GreetingTextContainer data-testid='signmail-text-container'>
          <GreetingText>{text}</GreetingText>
        </GreetingTextContainer>
      )}
    </Container>
  );
};

const Container = styled('div')`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.grey[900]};
`;

const Video = styledProps('video')`
  height: 100%;
  width: 100%;
`;

const GreetingTextContainer = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const GreetingText = styled('div')`
  padding: ${theme.spacing(8, 16)};
  color: ${theme.palette.common.white};
  text-align: center;
`;
