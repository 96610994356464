import type { FC, PropsWithChildren } from 'react';
import { lazy } from 'react';

import { supportedBrowsers } from 'features/supported-browsers/constants';

const UnsupportedBrowser = lazy(() =>
  import(
    // eslint-disable-next-line import/no-internal-modules
    'features/supported-browsers/components/UnsupportedBrowser/UnsupportedBrowser'
  ).then((module) => ({ default: module.UnsupportedBrowser }))
);

export const BrowserSupportingValidator: FC<PropsWithChildren> = ({
  children,
}) => {
  const isBrowserSupported = supportedBrowsers.test(navigator.userAgent);

  return <>{isBrowserSupported ? children : <UnsupportedBrowser />}</>;
};
