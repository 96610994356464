import { Subject } from 'rxjs';

import type { CallDeafMuteStatusRnsEventsData } from 'features/call/call-deaf/interfaces';
import type { HearingDeclinedCallRnsEventData } from 'features/call/call-hearing/interfaces';
import type { SyncCallTimerRnsEventData } from 'features/call/call-timer/interfaces';
import type { MonitorStart } from 'features/monitoring/interfaces/rnsEvents';
import type {
  AdminTeamedUserLogout,
  AdminTeamingCanceled,
  CallDisconnected,
  ColdHandoffAccepted,
  ColdHandoffCanceled,
  ColdHandoffRequest,
  ColdHandoffSent,
  InfoForVi,
  RegularCall,
  TeamingAccepted,
  TeamingBegin,
  TeamingCanceled,
  TeamingEnd,
  TeamingHandoff,
  TeamingRequest,
} from 'features/rns/interfaces';
import type { SpawnCallRnsEventData } from 'features/spawn-call/interfaces';
import type {
  VoiceSessionCreatingCompletedRnsEventData,
  VoiceSessionCreatingFailedRnsEventData,
  VoiceSessionCreatingStartedRnsEventData,
} from 'features/voice-session/interfaces';

/** NOTE: There are two RnsEventBus classes in Mercury.
 * Be careful which you use.
 * This is a problem and we should fix it someday.
 */
export class RnsEventBus {
  static readonly $newCall = new Subject<RegularCall>();
  static readonly $autoLogout = new Subject<void>();
  static readonly $callDisconnected = new Subject<CallDisconnected | void>();
  static readonly $callStarvationTimeout = new Subject<RegularCall>();

  static readonly $teamingRequest = new Subject<TeamingRequest>();
  static readonly $teamingAccepted = new Subject<TeamingAccepted>();
  static readonly $teamingCanceled = new Subject<TeamingCanceled>();
  static readonly $teamingBegin = new Subject<TeamingBegin>();
  static readonly $teamingEnd = new Subject<TeamingEnd>();
  static readonly $teamingHandoff = new Subject<TeamingHandoff>();
  static readonly $teamingUserConnectedDeprecated = new Subject<void>();
  static readonly $teamingUserConnectionSuccess = new Subject<void>();
  static readonly $teamingUserConnectionFailed = new Subject<void>();

  static readonly $coldHandoffRequest = new Subject<ColdHandoffRequest>();
  static readonly $coldHandoffAccepted = new Subject<ColdHandoffAccepted>();
  static readonly $coldHandoffSent = new Subject<ColdHandoffSent>();
  static readonly $coldHandoffCanceled = new Subject<ColdHandoffCanceled>();

  static readonly $adminAutoLogout = new Subject<void>();
  static readonly $adminCanceledCall = new Subject<void>();
  static readonly $adminTeamingCanceled = new Subject<AdminTeamingCanceled>();
  static readonly $adminTeamedUserLogout = new Subject<AdminTeamedUserLogout>();
  static readonly $remoteTeamedUserLogout = new Subject<void>();

  static readonly $monitorStart = new Subject<MonitorStart>();
  static readonly $monitorEnd = new Subject<void>();

  static readonly $voiceSessionCreatingStarted =
    new Subject<VoiceSessionCreatingStartedRnsEventData>();
  static readonly $voiceSessionCreatingFailed =
    new Subject<VoiceSessionCreatingFailedRnsEventData>();
  static readonly $voiceSessionCreatingCompleted =
    new Subject<VoiceSessionCreatingCompletedRnsEventData>();

  static readonly $hearingDeclinedCall =
    new Subject<HearingDeclinedCallRnsEventData>();
  static readonly $spawnCall = new Subject<SpawnCallRnsEventData>();
  static readonly $infoForVI = new Subject<InfoForVi>();
  static readonly $syncCallTimer = new Subject<SyncCallTimerRnsEventData>();

  static readonly $deafMuteStatusChange =
    new Subject<CallDeafMuteStatusRnsEventsData>();
}
