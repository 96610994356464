import { useState } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { setCallDetailsStatus } from 'features/call/call-details/store';
import { CallDetailsStatus } from 'features/call/call-details/enums';
import {
  isTwoLineVcoSelector,
  isVcoRequestedSelector,
} from 'features/vco/store';
import { EmergencyCallDetailsStatus } from 'features/emergency/enums';
import { setEmergencyCallDetailsStatus } from 'features/emergency/store';
import theme from 'features/theme/theme';
import { CallOutIncomingPhoneIcon } from 'assets/svg';

export const CallDetailsButtonDeafThreeDotMenu = () => {
  const dispatch = useAppDispatch();
  const isTwoLineVcoEnabled = useAppSelector(isTwoLineVcoSelector);
  const isVcoRequested = useAppSelector(isVcoRequestedSelector);
  const showAddVco2Line = !isTwoLineVcoEnabled && !isVcoRequested;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'vco2Line' });

  const handleAddVCOLine = () => {
    dispatch(setCallDetailsStatus(CallDetailsStatus.NEW_VCO_2_LINE));
    dispatch(
      setEmergencyCallDetailsStatus(EmergencyCallDetailsStatus.NEW_VCO_2_LINE)
    );
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      {showAddVco2Line && (
        <>
          <Container>
            <StyledIconButton
              aria-label='call-details-deaf-three-menu-button'
              onClick={handleMenuOpen}
            >
              <MoreHorizIcon />
            </StyledIconButton>
          </Container>
          <StyledMenu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <StyledMenuItem onClick={handleAddVCOLine}>
              <CallOutIncomingPhoneIconStyled />
              <Text aria-label='call-details-deaf-three-menu-add-vco'>
                {t('addNewLine')}
              </Text>
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}
    </>
  );
};

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
`;

const CallOutIncomingPhoneIconStyled = styled(CallOutIncomingPhoneIcon)`
  width: 18px;
  height: 18px;
`;

const StyledIconButton = styled(IconButton)`
 color: ${theme.palette.common.white};
 border-radius: 8px;
 background-color: ${theme.palette.grey[800]};
 width: 2em;
 margin-left: 0.35em;
 &:hover {
  background-color: ${theme.palette.grey[700]};
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  column-gap: 1rem;
  width: 200px;
`;

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    color: theme.palette.common.white,
    minWidth: '200px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
  },
  '& .MuiMenu-list': {
    backgroundColor: 'black',
    border: `1px solid ${theme.palette.grey[800]}`,
    marginTop: '0.5em',
    borderRadius: '8px',
  },
  '& .MuiMenuItem-root': {
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
}));
