import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useCallHearingParticipantHandlers } from 'features/call/call-accepted/hooks';
import {
  AddCallee,
  AddHearingConnecting,
  CallDetailsButtonAddHearing,
} from 'features/call/call-details/components';
import { AddHearingStatus } from 'features/call/call-details/enums';
import {
  addHearingStatusSelector,
  setAddHearingStatus,
} from 'features/call/call-details/store';
import { useHearings } from 'features/call/call-hearing/hooks';
import { HearingParticipantType } from 'features/call/call-hearing/interfaces';
import {
  lastConnectHearingSelector,
  primaryHearingStatusSelector,
} from 'features/call/call-hearing/store';
import { shouldCallControlsBeVisibleSelector } from 'features/spawn-call/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const CallDetailsAddHearing = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'addCallee',
  });
  const addHearingStatus = useAppSelector(addHearingStatusSelector);
  const lastConnectHearing = useAppSelector(lastConnectHearingSelector);
  const dispatch = useAppDispatch();
  const { handleRegularHearingCall, handleHearingDisconnect } =
    useCallHearingParticipantHandlers();
  const primaryHearingStatus = useAppSelector(primaryHearingStatusSelector);
  const { addHearingIfNotExists } = useHearings();
  const shouldCallControlsBeVisible = useAppSelector(
    shouldCallControlsBeVisibleSelector
  );

  const lastConnectedHearingAlreadyConnected = useMemo(
    () =>
      lastConnectHearing &&
      lastConnectHearing.type === HearingParticipantType.SECONDARY &&
      lastConnectHearing.status === 'connected',
    [lastConnectHearing]
  );

  const handleAddHearingButton = () => {
    dispatch(setAddHearingStatus(AddHearingStatus.ENTER_PHONE_NUMBER));
  };

  const handleEnterPhoneNumber = async (phoneNumber: string) => {
    try {
      dispatch(setAddHearingStatus(AddHearingStatus.CONNECTING));
      await addHearingIfNotExists(phoneNumber);
      await handleRegularHearingCall(phoneNumber);
    } catch {
      dispatch(setAddHearingStatus(AddHearingStatus.ENTER_PHONE_NUMBER));
    }
  };

  const handleCancel = () => {
    dispatch(setAddHearingStatus(AddHearingStatus.BASE));
  };

  const handleCancelConnecting = async (id: string) => {
    await handleHearingDisconnect(id);
    dispatch(setAddHearingStatus(AddHearingStatus.BASE));
  };

  useEffect(() => {
    if (lastConnectedHearingAlreadyConnected) {
      dispatch(setAddHearingStatus(AddHearingStatus.BASE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastConnectedHearingAlreadyConnected]);

  if (primaryHearingStatus !== 'connected') {
    return null;
  }

  if (!shouldCallControlsBeVisible) {
    return;
  }

  return (
    <Container>
      {addHearingStatus === AddHearingStatus.BASE && (
        <CallDetailsButtonAddHearing onClick={handleAddHearingButton} />
      )}
      {addHearingStatus === AddHearingStatus.ENTER_PHONE_NUMBER && (
        <Wrapper>
          <AddCallee
            onClick={handleEnterPhoneNumber}
            onCancel={handleCancel}
            title={t('addHearingCallee')}
          />
        </Wrapper>
      )}
      {addHearingStatus === AddHearingStatus.CONNECTING && (
        <Wrapper>
          <AddHearingConnecting onCancel={handleCancelConnecting} />
        </Wrapper>
      )}
    </Container>
  );
};

const Container = styled('div')`
  margin-top: ${theme.spacing(6)};
`;

const Wrapper = styled('div')`
  margin-top: ${theme.spacing(2)};
`;
