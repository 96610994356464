import { httpClient } from 'common/services';
import type { TakeCallRequest } from 'features/call/call-base/interfaces';
import type {
  EmergencyAddressAndProvisionStatus,
  EmergencyInfo,
  EmergencyTakeCallResponse,
} from 'features/emergency/interfaces';
import type { SpawnCallRequest } from 'features/spawn-call/interfaces';

async function getEmergencyCoordinates(callId: number) {
  const route = `/emergency/${callId}/coordinates`;
  const response = await httpClient.get<EmergencyInfo>(route);
  return response.data;
}

async function spawnEmergencyCall({
  callId,
  sessionId,
  terminationReason,
}: SpawnCallRequest) {
  const route = `/emergency/${callId}/spawn-call`;
  const response = await httpClient.post<EmergencyTakeCallResponse>(route, {
    sessionId: sessionId,
    terminationType: terminationReason,
  });
  return response.data;
}

async function takeEmergencyCall({ sessionId, ...rest }: TakeCallRequest) {
  const route = `/emergency/take/${sessionId}`;
  const response = await httpClient.put<EmergencyTakeCallResponse>(route, rest);
  return response.data;
}

async function getProvision(macAddress: string) {
  const route = `/emergency/provision`;
  const response = await httpClient.post<EmergencyAddressAndProvisionStatus>(
    route,
    {
      macAddress,
    }
  );
  return response.data;
}

export const EmergencyDaoService = {
  getEmergencyCoordinates,
  spawnEmergencyCall,
  takeEmergencyCall,
  getProvision,
};
