import { InputAdornment, TextField as MaterialTextField } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';

export const TextField = ({ InputProps, ...restProps }: TextFieldProps) => {
  if (InputProps?.startAdornment || InputProps?.endAdornment) {
    return (
      <MaterialTextField
        {...restProps}
        InputProps={{
          ...InputProps,
          ...(InputProps.startAdornment
            ? {
                startAdornment: (
                  <InputAdornment position='end'>
                    {InputProps.startAdornment}
                  </InputAdornment>
                ),
              }
            : {}),
          ...(InputProps.endAdornment
            ? {
                endAdornment: (
                  <InputAdornment position='start'>
                    {InputProps.endAdornment}
                  </InputAdornment>
                ),
              }
            : {}),
        }}
      />
    );
  }

  return <MaterialTextField InputProps={InputProps} {...restProps} />;
};
