import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { VrsChatStatus } from 'features/vrs-chat/enums';
import type { VrsChatState } from 'features/vrs-chat/interfaces';

export const initialVrsChatState: VrsChatState = {
  isShowPopup: false,
  status: VrsChatStatus.Disconnected,
};

const vrsChatSlice = createSlice({
  initialState: initialVrsChatState,
  name: 'vrs-chat',
  reducers: {
    toggleVrsChatPopup: (state) => {
      state.isShowPopup = !state.isShowPopup;
    },
    openVrsChatPopup(state) {
      state.isShowPopup = true;
    },
    setVrsChatStatus(state, action: PayloadAction<VrsChatStatus>) {
      state.status = action.payload;
    },
    resetVrsChatState: () => initialVrsChatState,
  },
});

export const {
  toggleVrsChatPopup,
  resetVrsChatState,
  openVrsChatPopup,
  setVrsChatStatus,
} = vrsChatSlice.actions;

export const isShowPopupSelector = ({ vrsChat }: RootState) =>
  vrsChat.isShowPopup;

export const selectVrsChatStatus = ({ vrsChat }: RootState) => vrsChat.status;

export const vrsChatReducer = vrsChatSlice.reducer;
