import { styled, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'features/theme/theme';
import { Textarea } from 'features/ui/elements';

export interface EmergencyLocationRecordProps {
  className?: string;
  location: string;
  onLocationChange: (location: string) => void;
}

export const EmergencyLocationRecord: FC<EmergencyLocationRecordProps> = ({
  className,
  location,
  onLocationChange,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'emergencyLocationRecord',
  });

  return (
    <div className={className}>
      <Typography
        component='p'
        variant='textMediumRegular'
        color={theme.palette.common.white}
      >
        {t('recordEmergencyLocation')}
      </Typography>
      <Typography component='p' variant='label' color='#e8a8a6' mt={2}>
        ({t('yourInfoIsAutosaved')})
      </Typography>
      <LocationTextArea
        value={location}
        inputProps={{ maxLength: 300 }}
        placeholder={t('enterLocationDetails')}
        onChange={(e) => onLocationChange(e.target.value)}
      />
    </div>
  );
};

const LocationTextArea = styled(Textarea)`
  margin-top: ${theme.spacing(8)};
`;
