import { t } from 'i18next';
import type { FC } from 'react';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';

const StyledButton = styled(Button)`
  margin-top: ${theme.spacing(8)};
  height: 44px;
`;

export const VrsCallReceivedTakeBreakButton: FC<
  Pick<ButtonProps, 'disabled' | 'onClick'>
> = ({ disabled, onClick }) => {
  return (
    <StyledButton
      fullWidth
      disabled={disabled}
      onClick={onClick}
      color='secondary'
      variant='outlined'
    >
      {t('takeBreak')}
    </StyledButton>
  );
};
