import { Typography } from '@mui/material';
import { typography } from '@mui/system';
import type { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'features/app/hooks';
import { CallDetailsButtonConnect } from 'features/call/call-details/components';
import { callErrorSelector } from 'features/call/call-hearing/store';
import { PhoneNumberInput } from 'features/phone-number/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Label } from 'features/ui/elements';

export interface ChangePhoneNumberProps {
  refPhoneNumber: RefObject<HTMLInputElement>;
  isPhoneNumberValid?: boolean;
  onClick: () => void;
  className?: string;
}

const Container = styled('div')`
  margin-top: ${theme.spacing(8)};
  border-radius: ${theme.spacing(4)};
  padding: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
`;

const Head = styled('div')`
  color: ${theme.palette.common.white};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(4)};
`;

const PhoneNumberInputStyle = styled(PhoneNumberInput)`
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(6)};
  padding: ${theme.spacing(4, 6)};
`;

export const ChangePhoneNumber: FC<ChangePhoneNumberProps> = ({
  refPhoneNumber,
  isPhoneNumberValid,
  onClick,
  className,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'newCall',
  });

  const callError = useAppSelector(callErrorSelector);

  return (
    <Container className={className}>
      <Head>
        <Typography
          variant='textLargeMedium'
          color={theme.palette.common.white}
        >
          {t('title')}
        </Typography>
      </Head>
      <Label text={t('label')} />
      <PhoneNumberInputStyle title='phoneNumber' refInput={refPhoneNumber} />
      <CallDetailsButtonConnect
        onClick={onClick}
        disabled={!isPhoneNumberValid}
      />
      {callError && <CallError>{callError}</CallError>}
    </Container>
  );
};

const CallError = styled('p')`
  margin-top: ${theme.spacing(4)};
  color: ${theme.palette.error.light};
  ${typography(theme.typography.label)};
`;
