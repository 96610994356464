import { getExponentialBackoffDelay } from 'features/utils/helpers';

export const SIGNALR_RECONNECT = {
  MAX_RETRIES: 4,
  BASE_DELAY: 500,
  MAX_DELAY: 10000,
};

/**
 * Generates an array of delay times (in milliseconds) for RNS SignalR reconnection attempts.
 * Implements an exponential backoff strategy with random jitter using the retryHelpers utility.
 *
 */
export const generateSignalRReconnectDelays = (): number[] => {
  // Create an array with a length equal to the maximum number of retries.
  return Array.from({ length: SIGNALR_RECONNECT.MAX_RETRIES }, (_, index) => {
    const delay = getExponentialBackoffDelay(index, {
      baseDelay: SIGNALR_RECONNECT.BASE_DELAY,
      maxDelay: SIGNALR_RECONNECT.MAX_DELAY,
    });
    return delay;
  });
};
