import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import {
  isDeafConnectedSelector,
  isDeafConnectingSelector,
} from 'features/call/call-deaf/store';
import {
  isPrimaryHearingConnectedSelector,
  isPrimaryHearingConnectingSelector,
} from 'features/call/call-hearing/store';
import {
  TeamingReason,
  TeamingState,
  TeamingStatus,
  TeamingType,
} from 'features/teaming/teaming-base/enums';

export const teamingStatusSelector = ({ teaming }: RootState) => teaming.status;

export const isTeamingInProgressSelector = ({ teaming }: RootState) =>
  teaming.status !== TeamingStatus.NONE;

export const isHandoffSelector = ({ teaming }: RootState) =>
  teaming.reason === TeamingReason.Handoff;

export const teamingReasonSelector = ({ teaming }: RootState) => teaming.reason;

export const teamingRoomUriSelector = ({ teaming }: RootState) =>
  teaming.roomUri;

export const isCurrentlyOnTeamingConferenceSelector = ({
  teaming,
}: RootState) => teaming.isCurrentlyOnTeamingConference;

export const acceptingUserIdSelector = ({ teaming }: RootState) =>
  teaming.acceptingUser.id;

export const isRequestingUserSelector = createSelector(
  acceptingUserIdSelector,
  Boolean
);

export const teamingAcceptingUserSelector = ({ teaming }: RootState) =>
  teaming.acceptingUser;

export const teamingAcceptingUserIdSelector = createSelector(
  teamingAcceptingUserSelector,
  (acceptingUser) => acceptingUser.id
);

export const teamingRequestingUserSelector = ({ teaming }: RootState) =>
  teaming.requestingUser;

export const teamingAnotherUserSelector = createSelector(
  isRequestingUserSelector,
  teamingRequestingUserSelector,
  teamingAcceptingUserSelector,
  (isRequestingUser, requestingUser, acceptingUser) =>
    isRequestingUser ? acceptingUser : requestingUser
);

export const teamingAnotherUserSessionIdSelector = createSelector(
  teamingAnotherUserSelector,
  (anotherUser) => anotherUser.sessionId
);

export const teamingStationSelector = ({ teaming }: RootState) =>
  teaming.station;

export const isRemoteTeamingSelector = ({ teaming }: RootState) =>
  teaming.teamingType === TeamingType.RemoteRq ||
  teaming.teamingType === TeamingType.RemoteIc;

export const isInPersonTeamingSelector = ({ teaming }: RootState) =>
  teaming.teamingType === TeamingType.Request;

export const isAdhocTeamingSelector = ({ teaming }: RootState) =>
  teaming.teamingType === TeamingType.AdhocReq;

export const isCollaborationInProgressSelector = ({ teaming }: RootState) =>
  teaming.status === TeamingStatus.REQUESTED ||
  teaming.status === TeamingStatus.ACCEPTED;

export const isTeamingStartedSelector = ({ teaming }: RootState) =>
  teaming.status === TeamingStatus.STARTED;

export const isRemoteTeamingStartedSelector = createSelector(
  isTeamingStartedSelector,
  isRemoteTeamingSelector,
  (isTeamingStarted, isRemoteTeaming) => isTeamingStarted && isRemoteTeaming
);

export const isSendTeamingUserNotificationSelector = createSelector(
  isRemoteTeamingStartedSelector,
  teamingAnotherUserSessionIdSelector,
  (isRemoteTeamingStarted, anotherUserSessionId) =>
    isRemoteTeamingStarted && anotherUserSessionId
);

export const isHandoffStartedSelector = createSelector(
  isTeamingStartedSelector,
  isHandoffSelector,
  (isTeamingStarted, isHandoff) => isTeamingStarted && isHandoff
);

export const isTeamingStatedSelector = createSelector(
  isTeamingStartedSelector,
  isHandoffSelector,
  (isTeamingStarted, isHandoff) => isTeamingStarted && !isHandoff
);

export const actualSessionTeamingStatedSelector = createSelector(
  isTeamingStartedSelector,
  (isTeamingStarted) =>
    isTeamingStarted ? TeamingState.Teaming : TeamingState.None
);

export const isTeamingReasonEmergencySelector = ({ teaming }: RootState) =>
  teaming.reason === TeamingReason.Emergency;

export const isAcceptingUserSelector = createSelector(
  isRemoteTeamingSelector,
  teamingRequestingUserSelector,
  (isRemoteTeaming, teamingRequestingUser) =>
    isRemoteTeaming && Boolean(teamingRequestingUser.id)
);

export const selectIsAcceptingUserAndCurrentlyTeaming = createSelector(
  isTeamingInProgressSelector,
  isAcceptingUserSelector,
  (isTeamingInProgress, isAcceptingUser) =>
    isTeamingInProgress && isAcceptingUser
);

export const selectIsRequestingUserAndCurrentlyTeaming = createSelector(
  isTeamingInProgressSelector,
  isRequestingUserSelector,
  (isTeamingInProgress, isRequestingUser) =>
    isTeamingInProgress && isRequestingUser
);

export const selectIsHandoffDisabled = createSelector(
  isDeafToHearingSelector,
  isDeafConnectedSelector,
  isPrimaryHearingConnectedSelector,
  isTeamingInProgressSelector,
  (
    isDeafToHearing,
    isDeafConnected,
    isPrimaryHearingConnected,
    isTeamingInProgress
  ) => {
    if (isTeamingInProgress) {
      return true;
    }

    if (isDeafToHearing) {
      return !isDeafConnected;
    }

    return !isPrimaryHearingConnected;
  }
);

const selectIsParticipantsConnecting = createSelector(
  isDeafConnectingSelector,
  isPrimaryHearingConnectingSelector,
  (isDeafConnecting, isPrimaryHearingConnecting) =>
    isDeafConnecting || isPrimaryHearingConnecting
);

const selectIsAnyTeamingStatusChangeDisabled = createSelector(
  selectIsParticipantsConnecting,
  isTeamingInProgressSelector,
  isTeamingStartedSelector,
  (arePartiesConnecting, isTeamingInProgress, isTeamingStarted) => {
    if (arePartiesConnecting) {
      return true;
    }

    return isTeamingInProgress && !isTeamingStarted;
  }
);

export const selectIsTeamingStatusChangeDisabled = createSelector(
  selectIsAnyTeamingStatusChangeDisabled,
  isTeamingInProgressSelector,
  isHandoffSelector,
  (isAnyTeamingDisabled, isTeamingInProgress, isHandoff) => {
    if (isAnyTeamingDisabled) {
      return true;
    }

    return isTeamingInProgress && isHandoff;
  }
);

export const selectIsHandoffStatusChangeDisabled = createSelector(
  selectIsAnyTeamingStatusChangeDisabled,
  isTeamingInProgressSelector,
  isHandoffSelector,
  (isAnyTeamingDisabled, isTeamingInProgress, isHandoff) => {
    if (isAnyTeamingDisabled) {
      return true;
    }

    return isTeamingInProgress && !isHandoff;
  }
);

export const selectIsInFreeSwitchRoomSelector = ({ teaming }: RootState) => {
  return teaming.isCurrentlyOnTeamingConference;
};
