import type { RootState } from 'features/app/store/store';
import { Mode } from 'features/multi-mode/enums';

export const deafSelector = ({ callDeaf }: RootState) => callDeaf;

export const isDeafConnectedSelector = ({ callDeaf }: RootState) =>
  callDeaf.status === 'connected';

export const isDeafConnectingSelector = ({ callDeaf }: RootState) =>
  callDeaf.status === 'connecting';

export const isDeafUnavailableSelector = ({ callDeaf }: RootState) =>
  callDeaf.status === 'unavailable';

export const isDeafDisconnectedSelector = ({ callDeaf }: RootState) =>
  callDeaf.status === 'disconnected';

export const deafStatusSelector = ({ callDeaf }: RootState) => callDeaf.status;

export const deafIsLoadingSelector = ({ callDeaf }: RootState) =>
  callDeaf.isLoading;

export const deafNameSelector = ({ callDeaf }: RootState) => callDeaf.name;

export const isDeafMutedSelector = ({ callDeaf }: RootState) =>
  callDeaf.isMuted;

export const deafPhoneNumberSelector = ({ callDeaf }: RootState) =>
  callDeaf.phoneNumber;

// VRI sets the IP address as the phone number and it breaks so for VRS only we will use the actual phone number
export const deafPhoneNumberByModeSelector = ({
  callDeaf,
  multiMode,
}: RootState) =>
  multiMode.mode === Mode.VRS ? callDeaf.phoneNumber : '+13854466691';

export const selectDeafConnectionWasNotAttempted = ({ callDeaf }: RootState) =>
  callDeaf.connectionAttempts === 0;

export const selectIsDeafUserFromSorenson = ({ callDeaf }: RootState) =>
  callDeaf.isSorenson;
