import { createRoot } from 'react-dom/client';

import { initI18n, register } from './config';
import { reportWebVitals } from './config/reportWebVitals';

import { httpClient } from 'common/services';
import { initPendo } from 'features/analytics-scripts/helpers';
import { env } from 'features/environment/constants';
import { envConfig } from 'features/environment/services';
import { initSentry } from 'features/sentry';
import RootComponent from 'features/app/Root';

if (env.isProductionBuild) {
  initSentry();
  initPendo();
}

initI18n();
httpClient.setup(envConfig.apiUrl);

const root = createRoot(document.getElementById('root')!);
root.render(<RootComponent />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
