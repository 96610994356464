import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CallDetailsParticipant } from 'features/call/call-details/components';
import { useCallDetailsCaller } from 'features/call/call-details/hooks';
import { Separator } from 'features/call/call-details/styles';
import { useEmergencyCallDetailsCallee } from 'features/emergency/hooks';

export const EmergencyCallDetailsCalleeReconnect: FC = () => {
  const { caller } = useCallDetailsCaller();
  const { callee } = useEmergencyCallDetailsCallee();

  const { t } = useTranslation('translation', { keyPrefix: 'callDetails' });

  return (
    <>
      <CallDetailsParticipant {...caller} title={t('callFrom')} />
      {callee && (
        <>
          <Separator />
          <CallDetailsParticipant {...callee} title={t('lastCaller')} />
        </>
      )}
    </>
  );
};
