import type { RTCSession } from 'jssip/lib/RTCSession';

import { isUnregisteredCaller } from './isUnregisteredCaller';

import {
  sendAnalyticsError,
  sendAnalyticsInfo,
} from 'features/analytics/helpers';
import type { RootState } from 'features/app/store/store';
import { isEmergencyCallTypeSelector } from 'features/emergency/store';
import { isVriModeSelector } from 'features/multi-mode/store';
import { getBrowserInfo } from 'common/utils';

export const callerSessionAnalyticsInfo = (message: string) => {
  const { browser, version } = getBrowserInfo();
  return sendAnalyticsInfo({
    Method: 'CallerSession',
    Message: message,
    Browser: browser,
    BrowserVersion: version,
  });
};

export const callerSessionAnalyticsError = (message: string) => {
  return sendAnalyticsError({
    Method: 'CallerSession',
    Message: message,
  });
};

export const getDefaultAnalyticsInfo = (
  session: RTCSession,
  rtcCallInfo: {
    callInfo: string | undefined;
    endpointMacAddress: string | undefined;
  },
  state: RootState
) => {
  const isVrsMode = !isVriModeSelector(state);

  return {
    remoteIdentifier: session.remote_identity.uri.toString(),
    isAnyWebRtcConnected: state.callerSession.webRtcRemoteIdentities.length > 0,
    isAlreadyConnected: state.callerSession.webRtcRemoteIdentities.includes(
      session.remote_identity.uri.toString()
    ),
    isReadyForTeaming: session.remote_identity.uri
      .toString()
      .includes(state.teaming.roomUri),
    validUnregisteredCaller: isUnregisteredCaller(
      session.remote_identity.uri.toString()
    ),
    caller: state.callerSession.caller,
    isVriMode: !isVrsMode,
    isEmergencyCall: isEmergencyCallTypeSelector(state),
    doesCallInfoIncludeCaller: rtcCallInfo.callInfo?.includes(
      state.callerSession.caller
    ),
  };
};
