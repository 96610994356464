import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'features/theme/styled';
import { Button } from 'features/ui/elements';
import { toggleAutoComplete } from 'features/call/call-base/store';
import { useAppDispatch } from 'features/app/hooks';

export const EndSessionButton: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'callDetails',
  });
  const dispatch = useAppDispatch();
  const onClickHandle = () => {
    dispatch(toggleAutoComplete());
  };
  return (
    <EndSessionButtonStyle
      fullWidth
      size='small'
      onClick={onClickHandle}
      color='error'
    >
      {t('endSession')}
    </EndSessionButtonStyle>
  );
};

const EndSessionButtonStyle = styled(Button)`
  width: 100%;
  border-radius: 0 0 8px 8px;
`;
