import type { FC, PropsWithChildren } from 'react';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

const Container = styled('div')`
  > button {
    margin-right: ${theme.spacing(7)};

    &:last-child {
      margin-right: 0;
    }
  }
`;
export const MenuButtons: FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};
