import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import {
  callIdSelector,
  isDeafToHearingSelector,
} from 'features/call/call-base/store/selectors';
import {
  isDeafConnectingSelector,
  isDeafUnavailableSelector,
} from 'features/call/call-deaf/store';
import {
  GreetingPreference,
  SignMailRecordingStatus,
  SignMailStatus,
} from 'features/signmail/enums';

export const selectSignMailStatus = ({ signMail }: RootState) =>
  signMail.currentStatus;

export const signMailVideoUrlSelector = ({ signMail }: RootState) =>
  signMail.greeting.videoUrl;

export const signMailGreetingPreferenceSelector = ({ signMail }: RootState) =>
  signMail.greeting.greetingPreference;

export const signMailCallIdSelector = ({ signMail }: RootState) =>
  signMail.signMailSessionCallId;

export const isTextOnlyGreetingSelector = createSelector(
  signMailGreetingPreferenceSelector,
  (greetingPreference) => greetingPreference === GreetingPreference.TEXT_ONLY
);

export const signMailGreetingTextSelector = ({ signMail }: RootState) =>
  signMail.greeting.text;

export const selectSignMailRecordingStatus = ({ signMail }: RootState) =>
  signMail.recording.status;

export const signMailRecordingUploadUrlSelector = ({ signMail }: RootState) =>
  signMail.recording.uploadUrl;

export const textStartSecondsSelector = ({ signMail }: RootState) =>
  signMail.recording.textStartSeconds;

export const isShowSignMailTextFormSelector = createSelector(
  isDeafToHearingSelector,
  isDeafConnectingSelector,
  isDeafUnavailableSelector,
  (isDeafToHearing, isDeafConnecting, isDeafUnavailable) => {
    return !isDeafToHearing && (isDeafConnecting || isDeafUnavailable);
  }
);

export const selectIsSignMailSessionInProgress = createSelector(
  ({ signMail }: RootState) => signMail.recording.status,
  ({ signMail }: RootState) => signMail.currentStatus,
  (recordingStatus, signMailStatus) => {
    return (
      recordingStatus === SignMailRecordingStatus.RECORDING ||
      recordingStatus === SignMailRecordingStatus.UPLOADING ||
      signMailStatus !== SignMailStatus.INACTIVE
    );
  }
);
export const selectShouldContinueSignMailSession = createSelector(
  selectIsSignMailSessionInProgress,
  isDeafUnavailableSelector,
  callIdSelector,
  signMailCallIdSelector,
  (
    isSignMailSessionInProgress,
    isDeafStatusUnavailable,
    relayCallId,
    signMailCallId
  ) => {
    if (relayCallId !== signMailCallId) {
      return false;
    }
    if (isSignMailSessionInProgress && isDeafStatusUnavailable) {
      return true;
    } else {
      return false;
    }
  }
);
