export enum CallDetailsStatus {
  CALLEE_DISCONNECTED_FAST = 'calleeDisconnectedFast',
  CALLEE_DISCONNECTED = 'calleeDisconnected',
  CHANGE_NUMBER = 'changeNumber',
  CHANGE_NUMBER_VCO_2_LINE = 'changeNumberVco2Line',
  CALLEE_CONNECTED = 'calleeConnected',
  CALLEE_RECONNECT = 'calleeReconnect',
  NEW_CALL = 'newCall',
  NEW_VCO_2_LINE = 'newVco2Line',
}
