import { alpha } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

const Container = styled('div')`
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${alpha(theme.palette.grey[900], 0.5)};
  border-radius: 8px;
  padding: ${theme.spacing(31, 23, 23)};
`;

export const VrsCallReceivedStyledContainer: FC<PropsWithChildren> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};
