import type { StandardTextFieldProps } from '@mui/material/TextField';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'features/ui/elements';

export interface TextareaProps extends StandardTextFieldProps {
  value?: string;
}

export const Textarea: FC<TextareaProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'textarea' });

  // eslint-disable-next-line @typescript-eslint/no-deprecated
  const maxLength: number | undefined = props.inputProps?.maxLength;

  const isMaxLengthExceeded = useMemo(() => {
    if (!props.value || !maxLength) {
      return;
    }

    return props.value.length > maxLength;
  }, [props.value, maxLength]);

  const helperText = useMemo(() => {
    if (!maxLength) {
      return;
    }

    const value = props.value;

    if (!value) {
      return t('max', { count: maxLength });
    }

    if (isMaxLengthExceeded) {
      return t('max-exceeded');
    }

    return `${value.length}/${maxLength}`;
  }, [t, maxLength, props.value, isMaxLengthExceeded]);

  const inputProps = useMemo(() => {
    if (!maxLength) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      return props.inputProps;
    }

    return {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      ...props.inputProps,
      maxLength: maxLength + 1,
    };
    // eslint-disable-next-line @typescript-eslint/no-deprecated
  }, [props.inputProps, maxLength]);

  return (
    <TextField
      {...props}
      type='text'
      multiline
      rows={props.rows || 5}
      fullWidth
      error={props.error || isMaxLengthExceeded}
      inputProps={inputProps}
      helperText={helperText}
    />
  );
};
