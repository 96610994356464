import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  removePlusFromPhoneNumber,
  addPlusToPhoneNumber,
  parsePhoneNumber,
} from 'features/call/call-base/helpers';
import { CallDaoService } from 'features/call/call-base/services';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import {
  setHearingLocation,
  setPrimaryHearingPhoneNumber,
} from 'features/call/call-hearing/store';
import {
  dialedNumbersListSelector,
  setDialedNumberHistory,
} from 'features/dialed-numbers-history/store';
import { isVrsModeSelector } from 'features/multi-mode/store';
import { isSpecialPhoneNumber } from 'features/n11/helpers';
import {
  useHearingConnect,
  useHearingDisconnect,
  useVoiceMeetingSessionInit,
} from 'features/voice-meeting/hooks';
import { updateCalleeConnectionString } from 'features/call/call-base/store';

export const useCallHearingParticipantHandlers = () => {
  const dispatch = useAppDispatch();

  const { connectHearing } = useHearingConnect();
  const { disconnectHearing } = useHearingDisconnect();
  const { replaceN11Number } = useVoiceMeetingSessionInit();
  const callId = useAppSelector(callIdSelector);
  const dialedNumbersList = useAppSelector(dialedNumbersListSelector);
  const isVrs = useAppSelector(isVrsModeSelector);

  const handleRegularHearingCall = useCallback(
    async (phoneNumber: string) => {
      dispatch(
        setDialedNumberHistory([
          removePlusFromPhoneNumber(phoneNumber),
          ...dialedNumbersList,
        ])
      );

      await connectHearing(phoneNumber);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectHearing]
  );

  const handleHearingSpawnNewCall = useCallback(
    async (phoneNumber: string) => {
      let processedNumber = phoneNumber;

      if (isVrs && isSpecialPhoneNumber(phoneNumber)) {
        processedNumber = (await replaceN11Number(phoneNumber)) ?? phoneNumber;
      }

      dispatch(setPrimaryHearingPhoneNumber(processedNumber));

      const { CalleeConnectionLocation, CalleeConnectionString } =
        await CallDaoService.updateCalleePhoneNumber({
          callId,
          phoneNumber: addPlusToPhoneNumber(parsePhoneNumber(processedNumber)),
        });

      dispatch(updateCalleeConnectionString(CalleeConnectionString));

      dispatch(
        setHearingLocation({
          location: CalleeConnectionLocation,
          phoneNumber: processedNumber,
        })
      );

      await handleRegularHearingCall(processedNumber);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleRegularHearingCall]
  );

  const handleHearingDisconnect = async (id: string) => {
    await disconnectHearing(id);
  };

  return {
    handleRegularHearingCall,
    handleHearingDisconnect,
    handleHearingSpawnNewCall,
  };
};
