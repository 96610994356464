import type { DefaultMeetingSession } from 'amazon-chime-sdk-js';

import type { Maybe } from 'common/types';
import type { VoiceSessionHearingParticipant } from 'features/voice-meeting/interfaces';
import type {
  DisconnectReason,
  Participant,
  SessionHearingParticipant,
  VoiceSessionResponse,
} from 'features/voice-session/interfaces';

const getHearingPhoneNumber = (
  hearingParticipant: SessionHearingParticipant
) =>
  hearingParticipant.direction === 'outbound'
    ? hearingParticipant.dialString
    : hearingParticipant.fromPhoneNumber;

const adaptHearing = (
  hearingParticipant: SessionHearingParticipant
): VoiceSessionHearingParticipant => ({
  id: hearingParticipant.id,
  state: hearingParticipant.state,
  disconnectReason: (hearingParticipant.disconnectReason ||
    '') as DisconnectReason,
  phoneNumber: getHearingPhoneNumber(hearingParticipant),
  direction: hearingParticipant.direction,
});

/**
 * deprecated Prefer using Redux `callHearing`, `voiceMeeting` slices instead.
 */
export class VoiceMeetingService {
  private static instance: VoiceMeetingService;
  public meetingSession: DefaultMeetingSession | null = null;
  public voiceSession: VoiceSessionResponse | null = null;

  private constructor() {}

  public static getInstance(): VoiceMeetingService {
    if (!VoiceMeetingService.instance) {
      VoiceMeetingService.instance = new VoiceMeetingService();
    }

    return VoiceMeetingService.instance;
  }

  public setMeetingSession(session: DefaultMeetingSession | null) {
    this.meetingSession = session;
  }

  public setVoiceSession(sessionState: VoiceSessionResponse | null) {
    this.voiceSession = sessionState;
  }

  public getHearingById = (
    id: string
  ): VoiceSessionHearingParticipant | null => {
    const hearing = this.voiceSession?.participants.find(
      (participant) => participant.type === 'hearing' && participant.id === id
    ) as Maybe<SessionHearingParticipant>;

    if (!hearing) {
      return null;
    }

    return adaptHearing(hearing);
  };

  public getHearingByPhoneNumber = (
    hearingPhoneNumber: string
  ): VoiceSessionHearingParticipant | null => {
    const hearing = this.voiceSession?.participants.find(
      (participant) =>
        participant.type === 'hearing' &&
        getHearingPhoneNumber(participant) === hearingPhoneNumber
    ) as Maybe<SessionHearingParticipant>;

    if (!hearing) {
      return null;
    }

    return adaptHearing(hearing);
  };

  public getFirstNotDisconnectedHearing = (
    participantId?: string
  ): VoiceSessionHearingParticipant | null => {
    const participants: Participant[] = Object.assign(
      [],
      this.voiceSession?.participants
    ).reverse();

    const hearingParticipant = participants.find((participant) => {
      const baseSelector =
        participant.type === 'hearing' && participant.state !== 'disconnected';

      if (participantId) {
        return baseSelector && participant.id === participantId;
      }

      return baseSelector;
    }) as Maybe<SessionHearingParticipant>;

    return hearingParticipant ? adaptHearing(hearingParticipant) : null;
  };

  public getHearings = (): VoiceSessionHearingParticipant[] => {
    const participants: Participant[] = this.voiceSession?.participants || [];

    const hearingParticipants = participants.filter((participant) => {
      return participant.type === 'hearing';
    });

    return hearingParticipants.map(adaptHearing);
  };

  public getAllHearingsNotDisconnected =
    (): VoiceSessionHearingParticipant[] => {
      const participants: Participant[] = this.voiceSession?.participants || [];

      const hearingParticipants = participants.filter((participant) => {
        return (
          participant.type === 'hearing' && participant.state !== 'disconnected'
        );
      }) as SessionHearingParticipant[];

      return hearingParticipants.map(adaptHearing);
    };

  public getFirstHearing = (): VoiceSessionHearingParticipant | null => {
    const hearing = this.voiceSession?.participants.find(
      (participant) => participant.type === 'hearing'
    ) as Maybe<SessionHearingParticipant>;

    if (!hearing) {
      return null;
    }

    return adaptHearing(hearing);
  };

  public getSessionId = (): string =>
    this.voiceSession?.chimeMeeting.ExternalMeetingId;

  public completeMeetingSession = () => {
    const meetingSession = this.meetingSession;
    const audioVideo = meetingSession?.audioVideo;

    if (!audioVideo || !meetingSession) {
      return;
    }

    // Destroy meeting session after audioVideo stopped. See: https://github.com/aws/amazon-chime-sdk-js/issues/2178
    const destroyObserver = {
      audioVideoDidStop() {
        audioVideo.removeObserver(destroyObserver);
        meetingSession.destroy();
      },
    };

    audioVideo.addObserver(destroyObserver);
    audioVideo.stop();
    this.meetingSession = null;
  };

  public clearParticipants = (): void => {
    if (this.voiceSession) {
      this.voiceSession.participants = [];
    }
  };

  public onAudioVideoDidStart = (callback: () => void) => {
    this.meetingSession?.audioVideo.addObserver({
      audioVideoDidStart: callback,
    });
  };
}
