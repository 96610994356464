import { ifElse } from 'ramda';
import type { FC } from 'react';

import { EmergencyGpsAttribute } from 'features/emergency/components';
import type { EmergencyCoordinates } from 'features/emergency/interfaces';

export interface EmergencyGpsDetailsProps {
  details: EmergencyCoordinates;
}

export const EmergencyGpsDetails: FC<EmergencyGpsDetailsProps> = ({
  details,
}) => {
  const validateValue = (value?: string | number): value is number =>
    typeof value === 'number' && !isNaN(value);

  const getUnknown = () => 'unknown';

  const formatValueWith = (formatFn: (value: number) => string) =>
    ifElse(validateValue, formatFn, getUnknown);

  const formatCoordinate = formatValueWith((value) => value.toFixed(6));

  const formatDistance = formatValueWith((value) => `${value.toFixed(2)} m`);

  return (
    <>
      <EmergencyGpsAttribute
        label='Lat'
        value={formatCoordinate(details?.latitude)}
      />
      <EmergencyGpsAttribute
        label='Long'
        value={formatCoordinate(details?.longitude)}
      />

      <EmergencyGpsAttribute
        label='Altitude'
        value={formatDistance(details?.altitude)}
      />

      <EmergencyGpsAttribute
        label='Uncertainty'
        value={formatDistance(details?.uncertainty)}
      />
    </>
  );
};
