import { createSlice } from '@reduxjs/toolkit';

import { CallPageStatus } from 'features/call/call-ui-state/enums';

export interface CallUiManagerState {
  state: CallPageStatus;
  errors: string[];
}

const initialState: CallUiManagerState = {
  state: CallPageStatus.IDLE,
  errors: [],
};

const callUiManagerSlice = createSlice({
  name: 'callUiManager',
  initialState,
  reducers: {
    // errors are going to be permissive for now
    callReceived(state: CallUiManagerState) {
      if (state.state !== CallPageStatus.IDLE) {
        const error = `Invalid state transition: ${state.state} cannot transition to CALL_RECEIVED`;
        state.errors.push(error);
      }
      state.state = CallPageStatus.CALL_RECEIVED;
    },
    connectCall(state: CallUiManagerState) {
      if (state.state === CallPageStatus.CONNECTED) {
        return;
      }
      if (
        state.state !== CallPageStatus.CALL_RECEIVED &&
        state.state !== CallPageStatus.TEAMING_OFFER_ACCEPTED &&
        state.state !== CallPageStatus.COLD_HANDOFF_OFFER_ACCEPTED
      ) {
        const error = `Invalid state transition: ${state.state} cannot transition to CONNECTED`;
        console.error(error);
        state.errors.push(error);
      }
      state.state = CallPageStatus.CONNECTED;
    },
    acceptTeamingOffer(state: CallUiManagerState) {
      if (state.state !== CallPageStatus.CALL_RECEIVED) {
        const error = `Invalid state transition: ${state.state} cannot transition to TEAMING_AWAIT_CONNECTION`;
        state.errors.push(error);
      }
      state.state = CallPageStatus.TEAMING_OFFER_ACCEPTED;
    },
    acceptColdHandoffOffer(state: CallUiManagerState) {
      if (state.state !== CallPageStatus.CALL_RECEIVED) {
        const error = `Invalid state transition: ${state.state} cannot transition to HANDOFF_AWAIT_CONNECTION`;
        state.errors.push(error);
      }
      state.state = CallPageStatus.COLD_HANDOFF_OFFER_ACCEPTED;
    },
    startSurvey(state: CallUiManagerState) {
      // TODO: Check if can get to survey any other state
      if (state.state !== CallPageStatus.CONNECTED) {
        const error = `Invalid state transition: ${state.state} cannot transition to SURVEY_STAGE`;
        state.errors.push(error);
      }
      state.state = CallPageStatus.SURVEY;
    },
    /** Canceled by VI1 */
    teamingCanceled(state: CallUiManagerState) {
      if (state.state !== CallPageStatus.TEAMING_OFFER_ACCEPTED) {
        const error = `Invalid state transition: ${state.state} cannot transition to TEAMING_CANCELED`;
        state.errors.push(error);
      }
      state.state = CallPageStatus.TEAMING_OFFER_CANCELED;
    },
    resetToIdle(state: CallUiManagerState) {
      state.state = CallPageStatus.IDLE;
      state.errors = [];
    },
    readyForNextCall(state: CallUiManagerState) {
      state.state = CallPageStatus.IDLE;
      state.errors = [];
    },
    clearUiManagerErrors(state: CallUiManagerState) {
      state.errors = [];
    },
  },
});

export const {
  callReceived,
  connectCall,
  acceptTeamingOffer,
  acceptColdHandoffOffer,
  startSurvey,
  teamingCanceled,
  resetToIdle,
  readyForNextCall,
  clearUiManagerErrors,
} = callUiManagerSlice.actions;

export const callUiManagerReducer = callUiManagerSlice.reducer;
