import type { FC, MouseEvent } from 'react';
import { useMemo } from 'react';

import { ArrowDownIcon, ArrowUpIcon } from 'assets/svg';
import { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';
import type { DropDownColor } from 'features/ui/interfaces';
import { Color } from 'features/ui/interfaces';

interface DropdownIconProps {
  isOpen: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  iconColor: DropDownColor;
}

interface DropdownColorGroup {
  backgroundColor: string;
  iconColor: string;
}

export const DropdownIcon: FC<DropdownIconProps> = ({
  isOpen,
  onClick,
  iconColor,
}) => {
  const colorGroup = useMemo(() => getIconColor(iconColor), [iconColor]);

  return (
    <Container
      data-testid='dropdown-icon'
      onClick={onClick}
      $backgroundColor={colorGroup.backgroundColor}
    >
      {isOpen ? (
        <ArrowDownIconStyled $iconColor={colorGroup.iconColor} />
      ) : (
        <ArrowUpIconStyled $iconColor={colorGroup.iconColor} />
      )}
    </Container>
  );
};

function getIconColor(iconColor: DropDownColor): DropdownColorGroup {
  if (iconColor === Color.PRIMARY) {
    return {
      iconColor: theme.palette.common.black,
      backgroundColor: theme.palette.primary[600],
    };
  }

  return {
    iconColor: theme.palette.common.white,
    backgroundColor: theme.palette.grey[500],
  };
}

const Container = styledProps('button')<{ $backgroundColor: string }>`
  width: ${theme.spacing(23)};
  height: ${theme.spacing(22)};
  border-radius: 0 8px 8px 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-left: 1px solid ${theme.palette.grey[400]};
  border-bottom: none;
  border-top: none;
  border-right: none;
  outline: none;
  cursor: pointer;
`;

const ArrowDownIconStyled = styledProps(ArrowDownIcon)<{
  $iconColor: string;
}>`
  & path {
    stroke: ${({ $iconColor }) => $iconColor};
  }
`;

const ArrowUpIconStyled = styledProps(ArrowUpIcon)<{
  $iconColor: string;
}>`
  & path {
    stroke: ${({ $iconColor }) => $iconColor};
  }
`;
