import {
  createListenerMiddleware,
  isAnyOf,
  type Action,
  type ListenerEffectAPI,
  type Middleware,
  type ThunkDispatch,
} from '@reduxjs/toolkit';

import {
  sendAnalyticsError,
  sendAnalyticsInfo,
} from 'features/analytics/helpers';
import type { RootState } from 'features/app/store/store';
import {
  acceptColdHandoffOffer,
  acceptTeamingOffer,
  callReceived,
  clearUiManagerErrors,
  connectCall,
  readyForNextCall,
  resetToIdle,
  startSurvey,
  teamingCanceled,
} from 'features/call/call-ui-state/store/';

interface TransitionInfo {
  Action: string;
  Message: string;
  CurrentState: string;
  Method: string;
}

const listenerMiddleware = createListenerMiddleware();

// List of monitored actions
const monitoredActions = [
  callReceived,
  connectCall,
  acceptTeamingOffer,
  acceptColdHandoffOffer,
  startSurvey,
  teamingCanceled,
  resetToIdle,
  readyForNextCall,
];

// Set to track in-progress actions and prevent infinite loops
const inProgressActions = new Set<string>();

/**
 * Handles state transitions by dispatching analytics events.
 * @param action - The Redux action being handled.
 * @param stateBefore - The state before the action was dispatched.
 * @param stateAfter - The state after the action was dispatched.
 * @param listenerApi - The listener API provided by Redux Toolkit.
 */
const handleStateTransition = (
  action: Action,
  stateBefore: RootState,
  stateAfter: RootState,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, Action>
  >
) => {
  const { state: prevState } = stateBefore.callUiManager;
  const { state: currentState, errors: currentErrors } =
    stateAfter.callUiManager;

  if (prevState !== currentState) {
    const transitionInfo: TransitionInfo = {
      Action: action.type,
      Message: `State transition: ${prevState} → ${currentState}`,
      CurrentState: currentState,
      Method: 'callUiManager',
    };

    if (currentErrors.length > 0) {
      console.error('Transition with errors:', currentErrors);
      listenerApi.dispatch(
        sendAnalyticsError({
          ...transitionInfo,
          Message: `Errors: ${currentErrors.join(', ')}`,
        })
      );
      listenerApi.dispatch(clearUiManagerErrors());
    } else {
      listenerApi.dispatch(sendAnalyticsInfo(transitionInfo));
    }

    console.log('Transition detected:', transitionInfo);
  }
};

listenerMiddleware.startListening({
  matcher: isAnyOf(...monitoredActions),
  effect: async (action, listenerApi) => {
    const actionType = action.type;

    if (inProgressActions.has(actionType)) {
      console.warn(`Skipping in-progress action: ${actionType}`);
      return;
    }

    inProgressActions.add(actionType);

    try {
      const stateBefore = listenerApi.getOriginalState() as RootState;
      const stateAfter = listenerApi.getState() as RootState;

      handleStateTransition(action, stateBefore, stateAfter, listenerApi);
    } catch (error) {
      console.error('Error handling action in middleware:', error);
    } finally {
      inProgressActions.delete(actionType);
    }
  },
});

export const stateTransitionMiddleware: Middleware =
  listenerMiddleware.middleware;
