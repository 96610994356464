import { httpClient } from 'common/services';
import type {
  BlockCustomerRequest,
  Call,
  CloseCallRequest,
  ConnectionStateHistoryRequest,
  DenyCallRequest,
  FinalizeUnexpectedEndRequest,
  PhoneNumberCoreResolution,
  PhoneNumberRegistration,
  TakeCallRequest,
  UpdateCalleePhoneNumberRequest,
} from 'features/call/call-base/interfaces';

async function getCall(callId: number) {
  const route = `/call/${callId}`;
  const response = await httpClient.get<Call>(route);
  return response.data;
}

async function takeCall({ sessionId, ...rest }: TakeCallRequest) {
  const route = `/call/take/${sessionId}`;
  const response = await httpClient.put<Call>(route, rest);
  return response.data;
}

async function closeCall({ callId, keepAlive, ...rest }: CloseCallRequest) {
  const route = `/call/${callId}/close`;
  const response = await httpClient.put<void>(route, rest, { keepAlive });
  return response.data;
}

async function blockCustomer({ callId }: BlockCustomerRequest) {
  const route = `/call/${callId}/block`;
  const response = await httpClient.post<void>(route);
  return response.data;
}

async function denyCall({ callId, userStatus }: DenyCallRequest) {
  const route = `/call/${callId}/deny`;
  const response = await httpClient.put<void>(route, { userStatus });
  return response.data;
}

async function updateCall(callId: number, partialCall: Partial<Call>) {
  const route = `/call/${callId}`;
  const response = await httpClient.patch<Call>(route, partialCall);
  return response.data;
}

async function updateCalleePhoneNumber({
  callId,
  ...rest
}: UpdateCalleePhoneNumberRequest) {
  const route = `/call/${callId}/callee-phone-number`;
  const response = await httpClient.put<Call>(route, rest);
  return response.data;
}

async function connectionStateHistory(options: ConnectionStateHistoryRequest) {
  const route = `/call/${options.callId}/connection-state-history`;
  const response = await httpClient.put<Call>(route, options);
  return response.data;
}

async function starvationReset(callId: number) {
  const route = `/call/${callId}/starvation-reset`;
  const response = await httpClient.patch<Call>(route);
  return response.data;
}

async function interpretUpdate(callId: number) {
  const route = `/call/${callId}/interpret`;
  const response = await httpClient.patch<Call>(route);
  return response.data;
}

async function phoneNumberRegistration(phoneNumber: string) {
  const route = `/call/${phoneNumber}/phone-number-registration`;
  const response = await httpClient.get<PhoneNumberRegistration>(route);
  return response.data;
}

async function resolvePhoneNumberWithCore(
  callerNumber: string,
  calleeNumber: string
) {
  const route = `/call/resolve/${callerNumber}/${calleeNumber}`;
  const response = await httpClient.get<PhoneNumberCoreResolution>(route);
  return response.data;
}

/**
 * Instructs the Proxy to handle the close-call logic.
 *
 * This is needed because closing a call properly
 * requires fetching the interpreting time from Relay,
 * and Mercury (client) cannot do that while it's being
 * unloaded from the browser (refreshed or closed mid-call).
 */
function finalizeUnexpectedEnd(data: FinalizeUnexpectedEndRequest) {
  httpClient.post('/call/finalize-unexpected-end', data, {
    keepAlive: true,
  });
}

export const CallDaoService = {
  getCall,
  takeCall,
  closeCall,
  denyCall,
  updateCall,
  blockCustomer,
  updateCalleePhoneNumber,
  connectionStateHistory,
  starvationReset,
  interpretUpdate,
  phoneNumberRegistration,
  resolvePhoneNumberWithCore,
  finalizeUnexpectedEnd,
};
