import type { FC } from 'react';

import { Hotkey } from 'config/enums';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { useAppDispatch } from 'features/app/hooks';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';
import { CallDetailsButtonReconnect } from 'features/call/call-details/components';

interface CallDetailsButtonDeafReconnectProps {
  phoneNumber: string;
}

export const CallDetailsButtonDeafReconnect: FC<
  CallDetailsButtonDeafReconnectProps
> = ({ phoneNumber }) => {
  const { handleDeafConnect } = useCallDeafParticipantHandlers();

  const dispatch = useAppDispatch();

  const handleDeafConnectClick = async () => {
    await handleDeafConnect(phoneNumber);

    dispatch(
      sendAnalyticsInfo({
        Method: 'handleDeafConnectClick',
        message: `Deaf connect button clicked with phone number ${phoneNumber}`,
      })
    );
  };

  return (
    <CallDetailsButtonReconnect
      onClick={handleDeafConnectClick}
      hotkey={Hotkey.CallerConnectionStateChange}
    />
  );
};
