import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { endpointMacAddressSelector } from 'features/call/call-base/store/selectors';
import { setCallbackAddress } from 'features/emergency/store';

export const EmergencyCallbackAddressHandlers = () => {
  const dispatch = useAppDispatch();

  const endpointMacAddress = useAppSelector(endpointMacAddressSelector);

  useEffect(() => {
    if (endpointMacAddress) {
      dispatch(setCallbackAddress(endpointMacAddress));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointMacAddress]);

  return <></>;
};
