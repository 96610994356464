import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import type { SetCallDetailsReconnectStatusPayload } from 'features/call/call-details/store';
import { setCallDetailsStatus } from 'features/call/call-details/store';
import { selectIsLoadingVco2LineHearing } from 'features/call/call-hearing/store';
import { isAcceptingUserSelector } from 'features/teaming/teaming-base/store';

export const setCallDetailsCalleeStatus = createAsyncThunk(
  'callDetails/setCalleeConnectedStatus',
  async (
    { isDeafParticipant, status }: SetCallDetailsReconnectStatusPayload,
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const isDeafToHearing = isDeafToHearingSelector(state);
    const isAcceptingUser = isAcceptingUserSelector(state);

    const isHearingParticipant = !isDeafParticipant;
    const isHearingToDeaf = !isDeafToHearing;
    const isLoadingVco2LineHearing = selectIsLoadingVco2LineHearing(state);

    if (isLoadingVco2LineHearing) {
      return;
    }

    if (
      (isDeafToHearing && isHearingParticipant) ||
      (isHearingToDeaf && isDeafParticipant) ||
      (isHearingToDeaf && isAcceptingUser && isHearingParticipant)
    ) {
      dispatch(setCallDetailsStatus(status));
    }
  }
);
