import { useAppSelector } from 'features/app/hooks';
import { VcoSipHeaderTag } from 'features/vco/enums';
import { isVcoRequestedSelector } from 'features/vco/store';

export const useVcoSipHeader = () => {
  const isVcoRequested = useAppSelector(isVcoRequestedSelector);

  const getVcoSipHeader = (): Record<VcoSipHeaderTag, number> => {
    return {
      [VcoSipHeaderTag.VCO_TYPE_PREF]: 0,
      [VcoSipHeaderTag.VCO_ACTIVE]: isVcoRequested ? 1 : 0,
    };
  };

  return { getVcoSipHeader };
};
