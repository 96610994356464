import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'features/app/hooks';
import { Mode } from 'features/multi-mode/enums';
import { switchModeTo } from 'features/multi-mode/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';
import { VpnWarning } from 'features/vpn-detector/components';

export const SelectMode: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'multiMode',
  });

  return (
    <Container data-testid='multi-mode-modal'>
      <VpnWarning />

      <MultiModeModal>
        <Typography
          variant='textLargeMedium'
          color={theme.palette.common.white}
          mb={4}
        >
          {t('title')}
        </Typography>
        <Typography variant='body2' color={theme.palette.grey[300]} mb={8}>
          {t('subtitle')}
        </Typography>
        <Buttons>
          {[Mode.VRI, Mode.VRS].map((itemMode) => (
            <Button
              fullWidth
              size='large'
              color='primary'
              isSendAnalytics={false}
              key={itemMode}
              title={itemMode}
              onClick={() => dispatch(switchModeTo(itemMode))}
            >
              {itemMode}
            </Button>
          ))}
        </Buttons>
      </MultiModeModal>
    </Container>
  );
};

const Container = styled('div')`
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${theme.palette.grey[700]};
  z-index: 30;

  .vpn-warning {
    max-width: 600px;
    display: flex;
    border-radius: 8px;
    background-color: ${theme.palette.grey[900]};
    padding: ${theme.spacing(23)};
    margin-bottom: ${theme.spacing(16)};
  }
`;

const MultiModeModal = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.grey[900]};
  border-radius: 8px;
  padding: ${theme.spacing(23)};
`;

const Buttons = styled('div')`
  width: 550px;
  display: flex;
  gap: ${theme.spacing(16)};
`;
