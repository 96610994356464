import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';
import {
  deafPhoneNumberSelector,
  selectDeafConnectionWasNotAttempted,
} from 'features/call/call-deaf/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { selectIsEmergencyDeafReconnectionNeeded } from 'features/emergency/store';
import { useWhileComponentMounted } from 'features/utils/hook';
import { sendAnalyticsInfo } from 'features/analytics/helpers';

export const useEmergencyDeafReconnect = () => {
  const whileComponentMounted = useWhileComponentMounted();
  const isEmergencyDeafReconnectionNeeded = useAppSelector(
    selectIsEmergencyDeafReconnectionNeeded
  );
  const deafConnectionWasNotAttempted = useAppSelector(
    selectDeafConnectionWasNotAttempted
  );
  const dispatch = useAppDispatch();
  const deafPhoneNumber = useAppSelector(deafPhoneNumberSelector);
  const { handleDeafConnect } = useCallDeafParticipantHandlers();

  const handleDeafDisconnect = useCallback(async () => {
    if (isEmergencyDeafReconnectionNeeded && deafConnectionWasNotAttempted) {
      // if we haven't placed a call already to the deaf, then try to connect.
      dispatch(
        sendAnalyticsInfo({
          Method: 'useEmergencyDeafReconnect',
          Message: 'emergency deaf reconnect',
          DeafConnectionWasNotAttempted: deafConnectionWasNotAttempted,
          IsEmergencyDeafReconnectionNeeded: isEmergencyDeafReconnectionNeeded,
        })
      );
      await handleDeafConnect(deafPhoneNumber);
    }
  }, [
    isEmergencyDeafReconnectionNeeded,
    deafConnectionWasNotAttempted,
    dispatch,
    handleDeafConnect,
    deafPhoneNumber,
  ]);

  useEffect(() => {
    const deafDisconnectSubscription =
      ParticipantsStatusEventBus.deaf.$disconnected
        .pipe(whileComponentMounted())
        .subscribe(handleDeafDisconnect);

    return () => {
      deafDisconnectSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDeafDisconnect]);
};
