import { typography } from '@mui/system';
import type { FC } from 'react';

import { useCountdown, useSignMailHandlers } from 'features/signmail/hooks';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const SignMailCountdown: FC = () => {
  const { handleCountdownEnded } = useSignMailHandlers();
  const { count } = useCountdown({
    onFinish: handleCountdownEnded,
  });

  return (
    <Container data-testid='signmail-countdown'>
      <Count>{count}</Count>
    </Container>
  );
};

const Container = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.grey[900]};
`;

const Count = styled('div')`
  ${typography(theme.typography.h1)};
  color: ${theme.palette.common.white};
  font-size: 76px;
  line-height: 68px;
  text-align: center;
`;
