import { useMemo } from 'react';

import { useCallSipHeader } from 'features/call/call-base/hooks';
import { ProductSipHeader } from 'features/product/services';
import { HEADER_METADATA_VALUE_KEY } from 'features/sip/constants';
import { useUserSipHeader } from 'features/user/hooks';
import { useVcoSipHeader } from 'features/vco/hooks';

export const useSipHeaderMetadata = () => {
  const { getCallSipHeader } = useCallSipHeader();
  const { getVcoSipHeader } = useVcoSipHeader();
  const { getUserSipHeader } = useUserSipHeader();

  const getSipHeaderMetadata = useMemo(() => {
    return {
      ...getUserSipHeader(),
      ...getVcoSipHeader(),
      ...getCallSipHeader(),
      ...ProductSipHeader.sipHeader,
    };
  }, [getCallSipHeader, getUserSipHeader, getVcoSipHeader]);

  const sipHeaderMetadata = useMemo((): string => {
    return Object.entries(getSipHeaderMetadata).reduce(
      (acc, [tag, value]) => `${acc}${tag}${value}|`,
      HEADER_METADATA_VALUE_KEY
    );
  }, [getSipHeaderMetadata]);

  return { sipHeaderMetadata };
};
