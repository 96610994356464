import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  GreetingPreference,
  SignMailRecordingStatus,
  SignMailStatus,
} from 'features/signmail/enums';
import type { Greeting, SignMailState } from 'features/signmail/interfaces';

export const initialSignMailState: SignMailState = {
  currentStatus: SignMailStatus.INACTIVE,
  signMailSessionCallId: 0,
  greeting: {
    videoUrl: '',
    text: '',
    greetingPreference: GreetingPreference.SORENSON_GREETING,
  },
  recording: {
    status: SignMailRecordingStatus.NOT_STARTED,
    uploadUrl: '',
    textStartSeconds: 0,
  },
};

export const signMailSlice = createSlice({
  name: 'signMail',
  initialState: initialSignMailState,
  reducers: {
    _resetSignMail: () => initialSignMailState,
    _transitionSignMailStatus: (
      state,
      action: PayloadAction<SignMailStatus>
    ) => {
      state.currentStatus = action.payload;
    },
    mailBoxIsFull: (state) => {
      state.recording.status = SignMailRecordingStatus.MAIL_BOX_FULL;
    },
    recordingErrorOccurred: (state) => {
      state.recording.status = SignMailRecordingStatus.ERROR_OCCURRED;
    },
    recordingStarted: (state, action: PayloadAction<{ uploadUrl: string }>) => {
      state.recording.status = SignMailRecordingStatus.RECORDING;
      state.recording.uploadUrl = action.payload.uploadUrl;
    },
    recordingUploadCompleted: (state) => {
      state.recording.status = SignMailRecordingStatus.UPLOAD_COMPLETE;
    },
    recordingUploadStarted: (state) => {
      state.recording.status = SignMailRecordingStatus.UPLOADING;
    },
    signMailSessionEnded: (state) => {
      return {
        ...initialSignMailState,
        signMailSessionCallId: state.signMailSessionCallId,
        recording: {
          ...state.recording,
          status: state.recording.status,
        },
      };
    },
    startSignmailSession: (
      state,
      action: PayloadAction<{ callId: number; greeting?: Greeting }>
    ) => {
      if (action.payload.greeting) {
        state.greeting = action.payload.greeting;
      }
      state.currentStatus = SignMailStatus.STARTED;
      state.signMailSessionCallId = action.payload.callId;
    },
    textEnteringStarted: (state, action: PayloadAction<number>) => {
      state.recording.textStartSeconds = action.payload;
    },
  },
});
export const {
  _resetSignMail,
  _transitionSignMailStatus,
  mailBoxIsFull,
  recordingErrorOccurred,
  recordingStarted,
  recordingUploadCompleted,
  recordingUploadStarted,
  signMailSessionEnded,
  startSignmailSession,
  textEnteringStarted,
} = signMailSlice.actions;

export const signMailReducer = signMailSlice.reducer;
