import { useAppSelector } from 'features/app/hooks';
import { FREESWITCH_ADDRESS_KEY } from 'features/monitoring/constants/headerKey';
import { monitorHostIpAddressSelector } from 'features/monitoring/store/selector';

export const useMonitorCustomHeader = () => {
  const monitorHostIpAddress = useAppSelector(monitorHostIpAddressSelector);

  const monitorCustomHeader = `${FREESWITCH_ADDRESS_KEY}: ${monitorHostIpAddress}`;

  return { monitorCustomHeader };
};
