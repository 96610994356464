import { AppLogoBase } from 'features/app-logo/components';
import { useAppSelector } from 'features/app/hooks';
import { modeSelector } from 'features/multi-mode/store';
import { Badge } from 'features/ui/elements';

export const AppLogo = () => {
  const mode = useAppSelector(modeSelector);

  return (
    <AppLogoBase>
      <Badge severity='subtle'>{mode}</Badge>
    </AppLogoBase>
  );
};
