import { Divider, styled, Typography } from '@mui/material';
import { typography } from '@mui/system';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckIcon, ClockRefreshIcon, MedicalCrossIcon } from 'assets/svg';
import { useAppSelector } from 'features/app/hooks';
import { EmergencyCallbackAddressHandlers } from 'features/emergency/components';
import {
  emergencyRegisteredAddressNameSelector,
  isRegisteredAddressPendingSelector,
} from 'features/emergency/store';
import theme from 'features/theme/theme';
import type { BadgeProps } from 'features/ui/elements';
import { Badge } from 'features/ui/elements';

interface EmergencyCallbackInfoProps {
  className?: string;
}

export const EmergencyCallbackInfo: FC<EmergencyCallbackInfoProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const isRegisteredAddressPending = useAppSelector(
    isRegisteredAddressPendingSelector
  );
  const emergencyRegisteredAddressName = useAppSelector(
    emergencyRegisteredAddressNameSelector
  );
  const registrationStatusBadgeProps = useMemo<BadgeProps>(() => {
    if (isRegisteredAddressPending) {
      return {
        icon: ClockRefreshIcon,
        severity: 'error',
        children: 'Pending',
      };
    }

    return {
      icon: CheckIcon,
      severity: 'success',
      children: 'OK',
    };
  }, [isRegisteredAddressPending]);

  return (
    <>
      <Container className={className}>
        <TitleContainer>
          <StyledMedicalCrossIcon />
          <Typography
            variant='textLargeMedium'
            color={theme.palette.error.contrastText}
            ml={4}
          >
            {t('emergency')}
          </Typography>
        </TitleContainer>
        <Separator />
        {emergencyRegisteredAddressName && (
          <Section>
            <SectionHeader>
              <StyledCaption>{t('registeredAddress')}</StyledCaption>
              <Badge {...registrationStatusBadgeProps} />
            </SectionHeader>
            <StyledText>{emergencyRegisteredAddressName}</StyledText>
          </Section>
        )}
      </Container>
      <EmergencyCallbackAddressHandlers />
    </>
  );
};

const Container = styled('div')`
  border-radius: 8px;
  padding: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
  border: 1px solid ${theme.palette.common.black};
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(8)};
`;

const StyledMedicalCrossIcon = styled(MedicalCrossIcon)`
  width: 18px;
  height: 18px;
  & path {
    stroke: ${theme.palette.error.contrastText};
  }
`;

const Section = styled('div')`
  ${typography(theme.typography.label)};
  color: ${theme.palette.grey[100]};
`;

const SectionHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(4)};
`;

const Separator = styled(Divider)`
  margin: ${theme.spacing(8, 0)};
  border-color: ${theme.palette.grey[400]};
`;

const StyledCaption = styled(Typography)`
  ${typography(theme.typography.label)};
  color: ${theme.palette.grey[100]};
`;

const StyledText = styled(Typography)`
  ${typography(theme.typography.textLargeMedium)};
  color: ${theme.palette.common.white};
`;
