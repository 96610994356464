import type { MutableRefObject } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallDaoService } from 'features/call/call-base/services';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { deafPhoneNumberSelector } from 'features/call/call-deaf/store';
import { handleError } from 'features/notification/store';
import {
  SignMailDaoService,
  SignMailRecorderService,
} from 'features/signmail/services';
import {
  mailBoxIsFull,
  recordingStarted,
  resetSignMailState,
} from 'features/signmail/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { useMediaStream } from 'features/media-streams/hooks';

export const useSignMailRecording = () => {
  const { getVideoStream } = useMediaStream();
  const service = SignMailRecorderService.getInstance();
  const callId = useAppSelector(callIdSelector);
  const deafPhoneNumber = useAppSelector(deafPhoneNumberSelector);
  const dispatch = useAppDispatch();

  const start = async (videoRef: MutableRefObject<HTMLVideoElement | null>) => {
    const videoStream = await getVideoStream();
    if (!videoStream) {
      return;
    }
    service.setVideoStream(videoStream);
    try {
      await CallDaoService.updateCalleePhoneNumber({
        callId,
        phoneNumber: deafPhoneNumber,
      });
      const { UploadURL: uploadUrl, MailboxFull } =
        await SignMailDaoService.getUploadUrl(callId);

      if (MailboxFull) {
        dispatch(mailBoxIsFull());
        dispatch(
          sendAnalyticsInfo({
            method: 'SignMail Start',
            message: 'SignMail mailbox is full',
          })
        );
        service.destroy();
        return;
      }

      videoRef.current!.srcObject = await service.start();
      dispatch(recordingStarted({ uploadUrl }));
    } catch (error) {
      dispatch(resetSignMailState());
      ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
      dispatch(
        handleError({
          error,
          methodName: 'start',
        })
      );
      service.destroy();
    }
  };

  return {
    start,
  };
};
