import type { FC } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { CallDetailsButtonDisconnect } from 'features/call/call-details/components';
import styled from 'features/theme/styled';
import { CallDetailsParticipantMetadataVco2Line } from 'features/vco-2-line/components';
import { useVoiceMeetingVco2LineHandlers } from 'features/vco-2-line/hooks';
import { isPrimaryVcoSenderSelector } from 'features/vco/store';
import { selectIsAnyHearingConnecting } from 'features/call/call-hearing/store';

export const CallDetailsParticipantConnectedVco2Line: FC = () => {
  const { handleVco2LineDisconnect } = useVoiceMeetingVco2LineHandlers();
  const isRequestingUser = useAppSelector(isPrimaryVcoSenderSelector);
  const isAnyHearingConnecting = useAppSelector(selectIsAnyHearingConnecting);

  return (
    <Container>
      <CallDetailsParticipantMetadataVco2Line />
      {isRequestingUser && (
        <CallDetailsButtonDisconnect
          disabled={isAnyHearingConnecting}
          onClick={handleVco2LineDisconnect}
        />
      )}
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
