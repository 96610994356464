import { ANONYMOUS } from 'features/voice-meeting/constants';
import type { VoiceSessionHearingParticipant } from 'features/voice-meeting/interfaces';
import { isStringAllLettersOrEmpty } from 'common/helpers';

export const anonymizeInvalidPhoneNumbers = (
  hearings: VoiceSessionHearingParticipant[]
) => {
  return hearings.map((hearing) => {
    if (isStringAllLettersOrEmpty(hearing?.phoneNumber)) {
      return {
        ...hearing,
        phoneNumber: ANONYMOUS,
      };
    }
    return hearing;
  });
};
