import { addBreadcrumb, captureException } from '@sentry/react';
import deepmerge from 'deepmerge';

import type { KeepAliveRequest } from 'common/interfaces/keepAliveRequest';
import { replaceLocation } from 'common/utils';

type CustomRequestInit<T = any> = Pick<RequestInit, 'method' | 'body'> & {
  headers?: Record<string, string>;
  body?: T;
};

interface CustomRequestConfig<T = any>
  extends CustomRequestInit<T>,
    KeepAliveRequest {}

export interface HttpResponse<T = any> {
  data: T;
}

class HttpService {
  private static _baseUrl = '';
  private static readonly _config: CustomRequestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  static setup(baseUrl: string) {
    HttpService.baseUrl = baseUrl;
  }

  static set baseUrl(url: string) {
    HttpService._baseUrl = url;
  }

  static setHeader(key: string, value: string) {
    if (!HttpService._config.headers) {
      HttpService._config.headers = {};
    }

    HttpService._config.headers[key] = value;
  }

  static get headers() {
    return HttpService._config.headers;
  }

  static get<D>(url: string) {
    return HttpService.makeRequest<D>(url, {
      method: 'GET',
    });
  }

  static post<D = any, B = any>(
    url: string,
    body?: B,
    config?: CustomRequestConfig
  ) {
    return HttpService.makeRequest<D>(url, {
      ...config,
      body,
      method: 'POST',
    });
  }

  static put<D = any, B = any>(
    url: string,
    body?: B,
    config?: CustomRequestConfig
  ) {
    return HttpService.makeRequest<D>(url, {
      ...config,
      body,
      method: 'PUT',
    });
  }

  static patch<D = any, B = any>(url: string, body?: B) {
    return HttpService.makeRequest<D>(url, {
      body,
      method: 'PATCH',
    });
  }

  static delete<D>(url: string, config?: CustomRequestConfig) {
    return HttpService.makeRequest<D>(url, {
      ...config,
      method: 'DELETE',
    });
  }

  static uploadFile<D = any>(url: string, body: FormData) {
    return HttpService.makeUploadFileRequest<D>(url, body);
  }

  private static async makeRequest<D = any, B = any>(
    url: string,
    { keepAlive, body, ...config }: CustomRequestConfig<B>
  ): Promise<HttpResponse<D>> {
    const requestConfig: RequestInit = deepmerge(HttpService._config, {
      ...config,
      ...(keepAlive ? { keepalive: true } : {}),
    });

    requestConfig.body = JSON.stringify(body);

    const response = await fetch(
      `${HttpService._baseUrl}${url}`,
      requestConfig
    );

    return await HttpService.handleResponse(response, url);
  }

  private static async makeUploadFileRequest<D = any>(
    url: string,
    body: FormData
  ): Promise<HttpResponse<D>> {
    const requestConfig = {
      body,
      headers: { ...HttpService._config.headers },
      method: 'POST',
    };

    delete requestConfig.headers['Content-Type'];

    const response = await fetch(url, requestConfig);

    return await HttpService.handleResponse<D>(response, url);
  }

  private static async handleResponse<D>(
    response: Response,
    url: string
  ): Promise<HttpResponse<D>> {
    const text = await response.text();

    if (response.ok) {
      return { data: HttpService.getResponseData(text) };
    }

    addBreadcrumb({
      message: 'Request data',
      data: {
        body: text,
      },
    });

    const errorMessage = `Request failed to ${url}. Status: ${response.status}. Message: ${text}`;
    const error = new Error(errorMessage);
    captureException(error);

    if (response.status === 401) {
      sessionStorage.clear();
      replaceLocation('/');
    }

    throw error;
  }

  private static getResponseData<D>(text: string): D {
    try {
      return JSON.parse(text) as D;
    } catch {
      return text as unknown as D;
    }
  }
}

export const httpClient = HttpService;
